<ng-container>
  <div class="card h-100 text-center m-2">
    <div class="card-body text-center">
      <h5 class="card-title alcon-text-color mx-2">
        {{ card.title | translate }}
      </h5>
      <p class="card-text">{{ card.text | translate }}</p>
    </div>
    <div class="card-footer">
      <a
        *ngIf="card.link && (card.isHreflink !== true)"
        [routerLink]="card.link"
        type="button"
        class="btn btn-home-edit py-2 mb-3"
      >
        {{ card.buttonkey | translate }}</a
      >
      <a
        *ngIf="card.link && (card.isHreflink === true)"
        [attr.href]="card.link"
        type="button"
        class="btn btn-home-edit py-2 mb-3"
      >
        {{ card.buttonkey | translate }}</a
      >
      <button
        *ngIf="!card.link"
        (click)="openPopup(card.function)"
        type="button"
        class="btn btn-home-edit py-2 mb-3"
      >
        {{ card.buttonkey | translate }}
      </button>
    </div>
  </div>
</ng-container>
