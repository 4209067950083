// This card is build and used for My alcon home and My favorite resoueces Section
// Re uasble responsive component

import { Component ,EventEmitter,Input, OnInit, Output} from '@angular/core';
import { Card } from '../card/card';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-play-card',
  templateUrl: './play-card.component.html',
  styleUrls: ['./play-card.component.scss']
})
export class PlayCardComponent {
  @Output() childEvent = new EventEmitter<string>();
  @Input() card!: Card;
  @Input() cardheight!: Number;

  myfaArrow = faArrowAltCircleRight;
  constructor() {}
  ngOnInit(): void {}
}
