<form #f="ngForm" [formGroup]="secretQuestionForm" (ngSubmit)="onSubmit()">
  <div class="wrapper px-3">
  <select style="width:60%" class="form-select" placeholder="Select Your Question" required formControlName="secretquestion" id="secretquestion">
    <option *ngFor="let option of questions" [value]="option.question">{{ option.questionText }}</option>
  </select>
    <input type="text" style="width:60%" required name="secretquestionasnwer"  class="form-control my-2" id="secretquestionasnwer" formControlName="secretquestionasnwer"
      aria-describedby="secretquestionasnwer" placeholder="Your Response" />
</div>
  <div class="text-end  my-2 px-2">
    <button class="btn text-end btn-reset" (click)="f.reset()" type="reset">
      {{ 'btnreset'  | translate}}
    </button>
    <button class="btn btn-cancel mx-2 my-1" (click)="cancelBtn()">{{ 'btncancel'  | translate}}</button>
    <button class="btn btn-submit mx-2 my-1" [disabled]="!secretQuestionForm.valid" type="submit" (click)="submitBtn()">
      {{ 'btnsubmit'  | translate}}
    </button>
  </div>
</form>

