<div class="px-3 py-5 privacy-card">
  <div class="fw-light fs-1">
    <!-- <div class="back-link">&lt;<a href="https://www.myalcon.com/">Back to [origin/close window]</a></div> -->
    <h4 class="head-title">{{ "mnumyprivacyandsecuritySettings" | translate }}</h4>
  </div>
  <h2 class="fw-bold fs-3 py-2 sub-header">Account verification</h2>
  <!-- <div class="alert alert-success" role="alert" *ngIf="isPhoneFactorSubmitted">
   {{isPhoneFactorSubmitted}}
  </div> 


    <div class="alert alert-success" role="alert" *ngIf="isSecretQusFactorSubmitted">
       {{isSecretQusFactorSubmitted}}
   </div>   -->
    
        
  <div class="px-3 py-3">
    <div *ngIf="isPhoneFactorErrorMsg" class="error-text alert alert-danger">                      
      {{isPhoneFactorErrorMsg }}     </div>

    <div *ngIf="isSecretQuestionFactorErrorMsg" class="error-text alert alert-danger">                      
      {{isSecretQuestionFactorErrorMsg}} </div>

    <div class="row card-group py-2">
      <div class="col- col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card rounded-left rounded-right">
          <div class="card-body text-left">
            <h5 class="card-title alcon-text-color text pt-4 fw-bold">{{'VerificationPhoneNumber' | translate}} <span><i class="bi bi-check-circle"></i></span></h5>
            <div class="card-body">
              
              <div class="">
                <p class="card-text px-3 lead sub-text" >{{ getMaskedPhoneNumber() }}</p>
              </div>
              <div class="px-3">
                <button type="button" class="btn btn-submit py-1 float-end"   (click)="disablePhoneFactorComponent()"  *ngIf="enablePhoneFactorDisableButton" ><i class="bi bi-x-lg"></i>
                  <span> {{'btndisable'| translate}}</span>
                </button>
                <button type="button" class="btn btn-edit py-1 end-float" *ngIf="!isPhoneFactorEditMode && !enablePhoneFactorDisableButton" (click)="loadPhoneFactorComponent()"><i
                    class="bi bi-power"></i>
                  <span> {{'btnenable'| translate}}</span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <router-outlet name="phonefactor" *ngIf="isPhoneFactorEditMode"></router-outlet>
            </div>
            <hr />
            <h5 class="card-title alcon-text-color pt-4 fw-bold text">{{'VerificationSecurityQuestion' | translate}}</h5>
            <div class="card-body text-left">
              <div>               
                  <div *ngIf="securityQuestions.length > 0" class="card-text px-3 lead sub-text">                  
                  <ul>
                    <li *ngFor="let q of securityQuestions">                    
                        {{ q.questionText }}
                     
                    </li>
                  </ul>
                </div>  
                <div *ngIf="selectedSecurityQuestion === '' && !enableSecretQuestionDisablesButton &&!isSecretQuestionFactorEditMode" class="card-text px-3 lead sub-text">
                  <p>Select a Security question</p>
                </div>           
              </div>
              <div class="px-3">
                <button type="button" class="btn btn-submit py-1 float-end"  (click)="disableSecretFactorComponent()" *ngIf="enableSecretQuestionDisablesButton"  ><i class="bi bi-x-lg"></i>
                  <span> {{'btndisable'| translate}}</span>
                </button>
                <button type="button" class="btn btn-edit py-1 end-float security-button" *ngIf="!isSecretQuestionFactorEditMode && !enableSecretQuestionDisablesButton" (click)="loadSecretFactorComponent()"><i
                    class="bi bi-power"></i>
                  <span> {{'btnenable'| translate}}</span>
                </button>
              </div>
            </div>
            <div class="card-body">
              <router-outlet name="seceretfactors" *ngIf="isSecretQuestionFactorEditMode" ></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h2 class="fw-bold fs-3 py-2 sub-header">Alcon notices</h2>
  <div class="px-3 py-3">
    <div class="row card-group py-2">
      <div class="col- col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card rounded-left rounded-right">
          <div class="card-body text-left">
            <h5 class="card-title alcon-text-color text pt-4 fw-bold">Privacy Notices<span></span></h5>
            <div class="card-body">
              <div class="">
                <p class="card-text lead sub-text" > Privacy Notices</p>
              </div>
              <div class="px-3">
               
                <button type="button" class="btn btn-edit py-1 float-end view-btn " >
                  <i class="bi bi-eye"></i>
                  <!-- <span><a href="https://preferences.myalcon.com/privacy-notice/?hidebanner=true" target="_blank">View</a> </span> -->
                  <span><a href="https://preferences.myalcon.com/privacy-notice/?hidebanner=true" target="_blank"> View</a> </span>
                </button>
              </div>
            </div>
            <div class="card-body">
            </div>
            <hr />
            <h5 class="card-title alcon-text-color pt-4 fw-bold text">Terms of Use</h5>
            <div class="card-body text-left">
              <div class="">
                <p class="card-text lead sub-text">Terms of Use
                </p>
              </div>
              <div class="px-3">
                <button type="button" class="btn btn-edit py-1 float-end view-btn" ><i
                  class="bi bi-eye"></i>
                <span><a href="https://preferences.myalcon.com/terms-of-use-us/?hidebanner=true" target="_blank"> View</a> </span>
              </button>
               
              </div>
            </div>
            <div class="card-body">
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>