import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyalconAccountRoutingModule } from './myalcon-account-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedModule } from 'src/app/shared.module';
import { MyalconAccountComponent } from './myalcon-account.component';
import { EditAccountComponent } from './editforms/edit-account/edit-account.component';
import { EditPhoneComponent } from './editforms/edit-phone/edit-phone.component';
import { ResetPasswordComponent } from './editforms/reset-password/reset-password.component';
import { ModalModule } from 'ngx-bootstrap/modal';
//import { EditPhoneFactorComponent } from './editforms/edit-phone-factor/edit-phone-factor.component';
//import { EditSecretQuestionFactorComponent } from './editforms/edit-secret-question-factor/edit-secret-question-factor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { PhoneFactorPopupModalComponent } from './editforms/edit-phone-factor/edit-phone-factor.popup-modal.component';
//import { SecretQuestionPopupModalComponent } from './editforms/edit-secret-question-factor/edit-secret-question-factor.popup-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConfrimModalComponent } from './confirmation.popup-modal.component';
import { SignOutModalComponent } from 'src/app/core/commoncomponent/card/signout.popup-modal.component';
@NgModule({
  declarations: [
    MyalconAccountComponent,ConfrimModalComponent,SignOutModalComponent,
    EditAccountComponent,
    EditPhoneComponent,
    
    ResetPasswordComponent,
   
  ],
  imports: [ TranslateModule.forChild(),
    CommonModule,SharedModule,ReactiveFormsModule,NgxIntlTelInputModule,FormsModule,
    MyalconAccountRoutingModule,ModalModule.forRoot(),NgbModule
  ]
})
export class MyalconAccountModule { }
