

import { environment } from "../environments/environment";


const myMemoryStore: Record<string, string> = {};
const key = "custom-storage-americas"; // Define a unique key
// Define a custom storage provider
const storageProvider = {
  getItem: function(key: string) {
      // Custom get
      return myMemoryStore[key];
  },
  setItem: function(key: string, val: string) {
      // Custom set
      myMemoryStore[key] = val;
  },
  // Optional: Implement removeItem if needed
  // removeItem: function(key: string) {
  //    delete myMemoryStore[key];
  // }
};
export default {
  oidcus: {
    clientId: environment.myalconaccOktaProfileUS.clientId,
    issuer: environment.myalconaccOktaProfileUS.issuer,
    redirectUri: environment.myalconaccOktaProfileUS.redirectUri,
    postLogoutRedirectUri: environment.myalconaccOktaProfileUS.postLogoutRedirectUri,
      tokenManager: {
      storageKey: 'custom-storage-americas'
    },
    storageManager: {
      token: {
     
        storageProvider: storageProvider
      },
      transaction: {
        storageProvider: storageProvider
      },
      cache: {
        storageProvider: storageProvider
      },
    },
    scopes: [
      'openid',
      'profile',
      'email',
      'okta.myAccount.password.manage',
      'okta.myAccount.profile.manage',
      'okta.myAccount.authenticators.manage',
      'okta.myAccount.authenticators.read',
      // 'alcon_user_type',
      // 'alcon_franchise',
      // 'alcon_consent',
    ],
    pkce: true,
  },
  oidceu: {
    clientId: environment.myalconaccOktaProfileEU.clientId,
    issuer: environment.myalconaccOktaProfileEU.issuer,
    redirectUri: environment.myalconaccOktaProfileEU.redirectUri,
    postLogoutRedirectUri: environment.myalconaccOktaProfileEU.postLogoutRedirectUri,
    tokenManager: {
      storageKey: 'custom-storage-emea'
    },
    storageManager: {
      token: {
     
        storageProvider: storageProvider
      },
      transaction: {
        storageProvider: storageProvider
      },
      cache: {
        storageProvider: storageProvider
      },
    },
    scopes: [
      'openid',
      'profile',
      'email',
      'okta.myAccount.password.manage',
      'okta.myAccount.profile.manage',
      'okta.myAccount.authenticators.manage',
      'okta.myAccount.authenticators.read',
      // 'alcon_user_type',
      // 'alcon_franchise',
      // 'alcon_consent',
    ],
    pkce: true,
  },
  oidcap: {
    clientId: environment.myalconaccOktaProfileAP.clientId,
    issuer: environment.myalconaccOktaProfileAP.issuer,
    redirectUri: environment.myalconaccOktaProfileAP.redirectUri,
    postLogoutRedirectUri: environment.myalconaccOktaProfileAP.postLogoutRedirectUri,
    tokenManager: {
      storageKey: 'custom-storage-apac'
    },
    storageManager: {
      token: {
     
        storageProvider: storageProvider
      },
      transaction: {
        storageProvider: storageProvider
      },
      cache: {
        storageProvider: storageProvider
      },
    },
    scopes: [
      'openid',
      'profile',
      'email',
      'okta.myAccount.password.manage',
      'okta.myAccount.profile.manage',
      'okta.myAccount.authenticators.manage',
      'okta.myAccount.authenticators.read',
      // 'alcon_user_type',
      // 'alcon_franchise',
      // 'alcon_consent',
    ],
    pkce: true,
  },
};

