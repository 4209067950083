import { Pipe, PipeTransform } from '@angular/core';

// This pipe is used to get a first letter in the given word
@Pipe({
  name: 'firstChar'
})
export class FirstCharPipe implements PipeTransform {
  transform(value: string): string {
    return value.charAt(0);
  }
}
