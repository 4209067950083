import { Component } from '@angular/core';
import { faBraille, faGear, faHome, faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})

export class MobileNavComponent {
  public myfaHome:any = faHome;
  public myfaUser:any = faUser;
  public myfaGear:any = faGear;
  public myfaDashboard:any = faBraille;
}
