import { Inject, Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import appconfig from 'src/config/appconfig';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';


interface HttpRequest {
  url: string;
  queryParams?: Record<string, string | number>;
  body?: any;
}

@Injectable({
  providedIn: 'root',
})
export class HttpResponseProvider {
  private getSubject = new ReplaySubject<any>();
  private getSubject1 = new ReplaySubject<any>();
  private postSubject = new ReplaySubject<any>();
  private deleteSubject = new ReplaySubject<any>();
  private putSubject = new ReplaySubject<any>();
  private patchSubject = new ReplaySubject<any>();
  token: any;
  // Observables for each HTTP method
  get$: Observable<any> = this.getSubject.asObservable();
  get1$: Observable<any> = this.getSubject1.asObservable();
  post$: Observable<any> = this.postSubject.asObservable();
  delete$: Observable<any> = this.deleteSubject.asObservable();
  put$: Observable<any> = this.putSubject.asObservable();
  patch$: Observable<any> = this.patchSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {}

   private buildUrl(
    url: string,
    queryParams?: Record<string, string | number>
  ): string {
    if (!queryParams) return url;

    const queryString = Object.keys(queryParams)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
      )
      .join('&');

    return `${url}?${queryString}`;
  }

  // This method is used to add headers for Okta
  addHeader() {
    let authConfig;
    if (window.localStorage.getItem("region") == "apac") {
      authConfig = appconfig.oidcap;
    }
    else if (window.localStorage.getItem("region") == "emea") {
      authConfig = appconfig.oidceu;
    }  
    else {
      authConfig = appconfig.oidcus;
    }
    const oktaAuth = new OktaAuth(authConfig);
    const accessToken = oktaAuth.getAccessToken(); // Use await to get the accessToken
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json; okta-version=1.0.0',
    });
    return headers;
  }

  toDataURL(
    url: string | URL,
    callback: (arg0: string | ArrayBuffer | null) => void
  ) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  // This method is used to add headers for MuleSoft
  addMuleSoftHeader() {


    const accessToken = this.oktaAuth.getAccessToken(); // Use await to get the accessToken
    const url = environment.mulesoftOktaProfile.jwksUrl + accessToken + '?clientId='+ environment.mulesoftOktaProfile.multitenenetclientId + '&clientSecret=' + environment.mulesoftOktaProfile.multitenenetclientSecret;
    this.token = btoa(url);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token' :this.token ,
      Authorization: `Bearer ${accessToken}`,
      client_id: `${environment.mulesoftOktaProfile.multitenenetclientId}`,
      client_secret: `${environment.mulesoftOktaProfile.multitenenetclientSecret}`,
    });
    return headers;
  }
}
