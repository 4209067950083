import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faPhone, faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FactorsService } from '../privacyand-security-settings/services/factotoenroll.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-myalcon-home',
  templateUrl: './myalcon-home.component.html',
  styleUrls: ['./myalcon-home.component.scss'],
})
export class MyalconHomeComponent implements OnInit {
  public loading: boolean = true;
  ismyUniversalLogoutEnabled = false;
  SignOut: any;

  cardData: any;
  imgcardSalesRepData: any;
  country_code: any;
  //public title= 'home_title';

  myFunction() {
    alert('Function executed in parent component');
  }
  constructor(
    private route: ActivatedRoute,
    public factorsService: FactorsService,
    public profileService: ProfileService
  ) {}
  ngOnInit(): void {
    this.storeReferrer();
    this.profileService.UserProfile.subscribe(
      (data: any) => {
        console.log('Location Data:', data); 
        // Set default country code to 'us' if countryCode is not available
        this.country_code = data?.country?.toLowerCase() || 'au';  
        console.log('Extracted Country Code:', this.country_code); 
      },
      (error: any) => {
        console.error('Error fetching location data:', error);
        // Set default country code to 'us' in case of error
        this.country_code = 'au';
      }
    );
    this.factorsService.getFeatureFlags().subscribe((features) => {
      if (features) {
        this.ismyUniversalLogoutEnabled = features['universal_logout'];
        // Account over view card data
        // This object is  built keeping in mind we can use an api call instead
        this.cardData = [
          {
            title: 'tile0_title', /// key of from the locale.json
            text: 'tile0_text', /// key of from the locale.json
            link: environment.landingPage.replace('{country_code}', this.country_code),
            isShow: true,
            buttonkey: 'manageapplication',
            faImage: faHome,
            function: '',
            isHreflink: true
          },
          {
            title: 'tile1_title', /// key of from the locale.json
            text: 'tile1_text', /// key of from the locale.json
            link: '/myaccount',
            isShow: true,
            buttonkey: 'updateinformattion',
            faImage: faHome,
            function: '',
            isHreflink: false
          },
          {
            title: 'tile2_title', /// key of from the locale.json
            text: 'tile2_text', /// key of from the locale.json
            link: '#',
            isShow: true,
            buttonkey: 'updatepreferencebtn',
            faImage: faHome,
            function: '',
            isHreflink: false
          },
          {
            title: 'tile5_title', /// key of from the locale.json
            text: 'tile5_text', /// key of from the locale.json
            link: '/privacyandsecuritysettings',
            isShow: this.ismyUniversalLogoutEnabled,
            buttonkey: 'updatesettings',
            faImage: faHome,
            function: '',
            isHreflink: false
          },
          {
            title: 'tile3_title', /// key of from the locale.json
            text: 'tile3_text', /// key of from the locale.json
            link: '/',
            isShow: false,
            buttonkey: 'btnedit',
            faImage: faHome,
            function: '',
            isHreflink: false
          },
          {
            title: 'tile2_title', /// key of from the locale.json
            text: 'tile2_text', /// key of from the locale.json
            link: '/mypreference',
            buttonkey: 'btnedit',            
            isShow: false,
            faImage: faHome,
            function: '',
            isHreflink: false
          },
        ];

        // My Alcon Support   view card data
        // This object is  built keeping in mind we can use an api call instead
        this.imgcardSalesRepData = [
          {
            title: 'myaclconsupporttilestile1_title', /// key of from the locale.json
            text: 'myaclconsupporttilestile1_primarytext', /// key of from the locale.json
            subtext: '',
            linkone: 'myaclconsupporttilestile1_primaryconatact', /// key of from the locale.json
            secondtext: 'myaclconsupporttilestile1_secondrytext', /// key of from the locale.json
            linktwo: 'myaclconsupporttilestile1_secondryconatact', /// key of from the locale.json
            faImage: faPhone, ///  Card Icon
            fragment: '',
            type: 'tel',
          },
          {
            title: 'myaclconsupporttilestile2_title', /// key of from the locale.json
            text: 'myaclconsupporttilestile2_primarytext', /// key of from the locale.json
            subtext: 'myaclconsupporttilestile2_subtext',
            linkone: '', // text is not applicable for the card property
            secondtext: 'myaclconsupporttilestile2_secondrytext', // text is not applicable for the card property
            linktwo: '', // text is not applicable  for the card property
            faImage: faHome, ///  Card Icon for the card property
            fragment: '',
            type: 'tel', // Telephine prefix
          },
          {
            title: 'myaclconsupporttilestile3_title', /// key of from the locale.json
            text: 'myaclconsupporttilestile3_primarytext', /// key of from the locale.json
            subtext: '',
            linkone: 'myaclconsupporttilestile3_primaryconatact', /// key of from the locale.json
            secondtext: 'myaclconsupporttilestile3_secondrytext', /// key of from the locale.json
            linktwo: 'myaclconsupporttilestile3_secondryconatact', /// key of from the locale.json
            faImage: faEnvelope, ///  Card Icon
            fragment: '', ///  text is not applicable  for the card property
            type: 'mailto', /// Email prefix
          },
        ];
      }
    });
    setTimeout(() => {
      this.loading = false;
    }, 700);
    //(window as any).sendHeightToParent();
  }
  goBack(event: Event): void {
    event.preventDefault(); // Prevent the default anchor behavior
    const referrer = document.referrer;
    if (referrer) {
      window.location.href = referrer;
    } else {
      console.warn('No referrer available');
    }
  }

  redirectToFaq(){
    if (this.country_code) {   
          const targetUrl = environment.faqPage.replace('{country_code}', this.country_code);
          console.log('Redirecting to:', targetUrl);
          window.location.href = targetUrl;
        } else {
          console.warn('Country code not available; redirecting to default.');
          window.location.href = 'https://www.myalcon.com/professional/myalconid/faqs/';
        }
  }

  redirectToHome(){
    if (this.country_code) {   
          const targetUrl = environment.helpUrl.replace('{country_code}', this.country_code);
          console.log('Redirecting to:', targetUrl);
          window.location.href = targetUrl;
        } else {
          console.warn('Country code not available; redirecting to default.');
          window.location.href = 'https://www.myalcon.com/professional/';
        }
  }
 
  storeReferrer(): void {
    const referrer = localStorage.getItem('referrer');
 
    if (referrer) {
      // Store the referrer in local storage
      localStorage.setItem('referrer', referrer);
      console.log('Referrer stored:', referrer);
    } else {
      console.log('No referrer available');
    }
  }
  afterViewInit() {}
}
