import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { StepupCallbackComponent } from './stepup-callback.component';
import { StepupRoutingModule } from './stepup-routing.module';
import { SetpupConfig, StepupService } from './stepup.service';

export interface StepupModuleConfig {
  config: SetpupConfig;
}

@NgModule({
  declarations: [StepupCallbackComponent],
  imports: [CommonModule, StepupRoutingModule],
  providers: [StepupService],
})
export class StepupModule {
  static forRoot(
    config: StepupModuleConfig
  ): ModuleWithProviders<StepupModule> {
    const defaultConfig: StepupModuleConfig = {
      config: {
        durationInSeconds: 1800,
        acrValues: 'urn:okta:loa:1fa:any',
        additionalScopes: [ 'okta.myAccount.password.manage',
          'okta.myAccount.profile.manage',
          'okta.myAccount.authenticators.manage',
          'okta.myAccount.authenticators.read',
          'alcon_user_type',
          'alcon_franchise',
          'alcon_consent'],
      },
    };

    return {
      ngModule: StepupModule,
      providers: [
        StepupService,
        {
          provide: 'CONFIG',
          useValue: { config: { ...defaultConfig.config, ...config.config } },
        },
      ],
    };
  }
}
