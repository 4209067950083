import { Component, inject, Inject, ViewChild } from '@angular/core';
import { CustomLink } from './custom-link';
import { FirstCharPipe } from 'src/app/core/pipes/fistChar';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { environment } from 'src/environments/environment';
import { OktaService } from 'src/app/core/services/common/okta.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent {
  navbarOpen = false;
  isNavbarCollapsed = true;
  private sidebarVisible: boolean = false;
  public profile = this.profileService.UserProfile;
  public myIcon: any = null;
  public ovalLogOutButton = "oval-logout-button";
  selectedLang = 'English';
  languageList: any =
    [
      { "locale": 'en_AU', "language": "Austerila" },
      { "locale": 'en_US', "language": "English" },
      { "locale": '', "language": "Brazilian Portuguese" },
      { "locale": '', "language": "Malaysian" },
      { "locale": '', "language": "Canadian French" },
      { "locale": '', "language": "Norwegian" },
      { "locale": '', "language": "Czech" },
      { "locale": '', "language": "Polish" },
      { "locale": '', "language": "Danish" },
      { "locale": '', "language": "Portuguese" },
      { "locale": '', "language": "Dutch" },
      { "locale": '', "language": "Russian" },
      { "locale": '', "language": "Finnish" },
      { "locale": '', "language": "Spanish" },
      { "locale": '', "language": "French" },
      { "locale": '', "language": "Swedish" },
      { "locale": '', "language": "German" },
      { "locale": '', "language": "Thai" },
      { "locale": '', "language": "Greek" },
      { "locale": '', "language": "Turkish" },
      { "locale": '', "language": "Hungarian" },
      { "locale": '', "language": "Ukrainian" },
      { "locale": '', "language": "Indonesian" },
      { "locale": '', "language": "Vietnamese" },
      { "locale": '', "language": "Italian" },
      { "locale": '', "language": "简体中文 (Simplified Chinese)" },
      { "locale": '', "language": "Japanese" },
      { "locale": '', "language": "繁體中文 (Traditional Chinese)" },
      { "locale": '', "language": "Korean" },


    ];
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;
  showDropdown: boolean = false;
  private readonly translateService = inject(TranslateService);
  private readonly availableLanguages = ['en_US', 'en_AU'];
  public lang: any = 'en_US';
  languageOptions: { value: string; label: any; }[];
  textTranslatedByPipe = '';
  private readonly translatePipe = inject(TranslatePipe);
  onLangDropdown() {
    console.log('dropdown staus...', this.dropdown.isOpen())
    this.showDropdown = this.dropdown.isOpen();
    // return this.dropdown.isOpen();

  }
  constructor(public profileService: ProfileService, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth, public oktaService: OktaService) {
    this.myIcon = faUser;
    this.profileService.UserProfile.subscribe((data: any) => {
      this.translateService.addLangs(this.availableLanguages);
      if (navigator.language.replace('-', '_')) {
        this.translateService.setDefaultLang(navigator.language.replace('-', '_'));
        this.lang = navigator.language.replace('-', '_');
      }
      else if (data.gen_locale) {
        this.translateService.setDefaultLang(data.gen_locale);
        this.lang = data.gen_locale;
      }
      else {
        this.lang = 'en_US';
        this.translateService.setDefaultLang('en_US');
      }

      this.buildLanguageOptions();
    });
  }
  ngOnInit(): void {
  }

  private buildLanguageOptions() {
    const EN = this.translateService.get('EN');
    const AU = this.translateService.get('AU');

    forkJoin([EN, AU]).subscribe((_response) => {
      this.languageOptions = [
        {
          value: this.availableLanguages[0],
          label: _response[0].toUpperCase(),
        },
        {
          value: this.availableLanguages[1],
          label: _response[1].toUpperCase(),
        },
      ];
      this.textTranslatedByPipe = this.translatePipe.transform(
        'TRANSLATED_BY_COMPONENT'
      );
    });
    console.log('current language,', this.translateService.currentLang)

  }
  logout() {
    this.oktaService.logoutSessions();
  }

  sidebarToggle() {
    var body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible == false) {
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    }
  }

  // onLangSelect(lang: any) {
  //   console.log('selected lang...', lang)
  // }
  onLangSelect(lang: any) {
    console.log('selected lang...', lang)
    this.selectedLang = lang.language;
    if (lang.locale) {
      const selectedValue = lang.locale;
      this.lang = selectedValue;
      this.changeLanguage(selectedValue);
    }
  }
  changeLanguage(language: string) {
    //    alert(language);

    this.translateService.use(language);
    this.textTranslatedByPipe = this.translatePipe.transform(
      'TRANSLATED_BY_COMPONENT'
    );
  }
}
