// sidebar.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SidenavService } from './sidenav.service';

@Injectable({
  providedIn: 'root',
})
export class SidenavGuard implements CanActivate {
  constructor(private sidebarService: SidenavService) {}

  canActivate(): boolean {
    if (!this.sidebarService.getSidebarLoadedStatus()) {
      this.sidebarService.setSidebarLoadedStatus();
      return true;
    } else {
      // Sidebar has already been loaded, prevent loading again
      return false;
    }
  }
}
