<div class="modal-header" style="overflow: hidden;">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
<!-- <label [(ngModel)]="user.myacc_phone_no" type="text"></label> -->
<!-- <input [(ngModel)]="user.otp" type="number"> -->
<label  type="text"></label>
<input class="form-control" type="number">
  <!-- Use DomSanitizer to mark the URL as safe -->
  <!-- <iframe [src]="safeUrl" frameborder="0" width="100%" height="550px"></iframe> -->
</div>
<div class="modal-footer">
  <button  (click)="verify()" type="submit" class="btn btn-primary w-100">{{'verifyandenable' | translate}}</button>
  <button type="button" class="btn btn-secondary" (click)="onClose()">{{'btnclose' | translate}}</button>
</div>
