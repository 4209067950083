
// This card is build and used for My alcon home and the My Recent app Section
// Re uasble responsive component

import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { Card } from '../card/card';

@Component({
  selector: 'app-img-card',
  templateUrl: './img-card.component.html',
  styleUrls: ['./img-card.component.scss'],
})
export class ImgCardComponent implements OnInit {
  @Input() card!: Card;
  @Input() cardheight!: Number;
  @Input() label!: string;
  @Input() selectedButton!: string;
  @Input() css!: string;
  constructor() {}
  ngOnInit(): void {
    this.label  = this.card.label;
    this.selectedButton= this.card.selectedButton;
    this.css= this.card.selectedButton;
  }
}
