import { Component, EventEmitter, HostListener, inject, Inject, Input, Output, ViewChild } from '@angular/core';
import { CustomLink } from './custom-link';
import { FirstCharPipe } from 'src/app/core/pipes/fistChar';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { environment } from 'src/environments/environment';
import { OktaService } from 'src/app/core/services/common/okta.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { SidenavService } from '../myalcon-sidenav/services/sidenav.service';
import { DeviceDetectionService } from 'src/app/device-detection.service';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';

@Component({
  selector: 'app-topnav',
  templateUrl:'./topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent {
  menuOpen :boolean = false;
  menuStatus: boolean = true;
  navbarOpen = false;
  isNavbarCollapsed = true;
  private sidebarVisible: boolean = false;
  public profile = this.profileService.UserProfile;
  public myIcon: any = null;
  public ovalLogOutButton = "oval-logout-button";
  selectedLang = 'English';
  @Input() openLang: string;
  languageList: any =
    [      
      // { "locale": '', "language": "Brazilian Portuguese" },
      // { "locale": '', "language": "Malaysian" }, 
      // { "locale": '', "language": "Canadian French" },
      // { "locale": '', "language": "Norwegian" }, 
      // { "locale": '', "language": "Czech" },
      // { "locale": '', "language": "Polish" },  
      // { "locale": '', "language": "Danish" },
      // { "locale": '', "language": "Portuguese" },  
      // { "locale": '', "language": "Dutch" },
      // { "locale": '', "language": "Romanian" }, 
      { "locale": 'en_US', "language": "English" },
      // { "locale": '', "language": "Russian" },
      // { "locale": '', "language": "Finnish" },  
      // { "locale": '', "language": "Spanish" },
      // { "locale": '', "language": "French" }, 
      // { "locale": '', "language": "Swedish" },  
      // { "locale": '', "language": "German" }, 
      // { "locale": '', "language": "Thai" }, 
      // { "locale": '', "language": "Greek" }, 
      // { "locale": '', "language": "Turkish" },
      // { "locale": '', "language": "Hungarian" }, 
      // { "locale": '', "language": "Ukrainian" },
      // { "locale": '', "language": "Indonesian" },
      // { "locale": '', "language": "Vietnamese" },
      // { "locale": '', "language": "Italian" },    
      // { "locale": '', "language": "简体中文 (Simplified Chinese)" },
      // { "locale": '', "language": "Japanese" },
      // { "locale": '', "language": "繁體中文 (Traditional Chinese)" },
      // { "locale": '', "language": "Korean" },  

    ];
    
  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;
  showDropdown: boolean = false;
  private readonly translateService = inject(TranslateService);
  private readonly availableLanguages = ['en_US', 'en_AU'];
  public lang: any = 'en_US';
  languageOptions: { value: string; label: any; }[];
  textTranslatedByPipe = '';
  private readonly translatePipe = inject(TranslatePipe);
  showLangSelection = false;
  @Output() valueChange = new EventEmitter();
  elRef: any;
  arrowDirection: string;
  isIpadMiniLandscape: boolean;
  country_code: string;
 
  onLangDropdown() {
    // console.log('dropdown staus...', this.dropdown.isOpen())
    this.showDropdown = this.dropdown.isOpen();
    // return this.dropdown.isOpen();
    this.showDropdown = !this.showDropdown;
    this.showLangSelection = this.showDropdown;
  }  
  constructor(private deviceDetectionService: DeviceDetectionService, public sidenavService: SidenavService, public profileService: ProfileService, @Inject(OKTA_AUTH) public oktaAuth: OktaAuth, public oktaService: OktaService) {
    this.myIcon = faUser;
    this.onLocale();
  }
  ngOnInit(): void {
    this.showLangSelection = false;
    this.getResolution();   
    this.profileService.UserProfile.subscribe(
      (data: any) => {
        console.log('Location Data:', data); 
        // Set default country code to 'us' if countryCode is not available
        this.country_code = data?.country?.toLowerCase() || 'au';  
        console.log('Extracted Country Code:', this.country_code); 
      },
      (error) => {
        console.error('Error fetching location data:', error);
        // Set default country code to 'us' in case of error
        this.country_code = 'au';
      }
    );
  }
  mobileView = false;
  tabView = false;
  desktopView = true;
  isIpadMiniPortrait = false;
  onResize(event: any) {
    this.getResolution();
  }
  getResolution() {
    this.mobileView = false;
    this.tabView = false;
    this.desktopView = false;
    this.isIpadMiniPortrait = false;
    this.deviceDetectionService.isHandset().subscribe(isHandset => {
      this.mobileView = isHandset;
    });
    this.deviceDetectionService.isTablet().subscribe(isTablet => {
      this.tabView = isTablet;
    
    });
    this.isIpadMiniPortrait = this.deviceDetectionService.isIpadMiniPortrait();
    this.isIpadMiniLandscape = this.deviceDetectionService.isIpadMiniLandscape();
    this.deviceDetectionService.isWeb().subscribe(isWeb => {
      this.desktopView = isWeb;
    });;

  }
  ngOnChanges(): void {
    this.showLangSelection = !this.showLangSelection;
  }
  ngAfterViewInit() {
    this.showLangSelection = !this.showLangSelection;    
  }

  onDropdownToggle(isOpen: boolean) {    
    this.showDropdown = isOpen;     
   }  

  onLocale() {
    this.profileService.UserProfile.subscribe((data: any) => {
      this.translateService.addLangs(this.availableLanguages);
      if (navigator.language.replace('-', '_')) {
        this.translateService.setDefaultLang(navigator.language.replace('-', '_'));
        this.lang = navigator.language.replace('-', '_');
      }
      else if (data.gen_locale) {
        this.translateService.setDefaultLang(data.gen_locale);
        this.lang = data.gen_locale;
      }
      else {
        this.lang = 'en_US';
        this.translateService.setDefaultLang('en_US');
      }

      this.buildLanguageOptions();
    });
  }
  private buildLanguageOptions() {
    const EN = this.translateService.get('EN');
    const AU = this.translateService.get('AU');

    forkJoin([EN, AU]).subscribe((_response) => {
      this.languageOptions = [
        {
          value: this.availableLanguages[0],
          label: _response[0].toUpperCase(),
        },
        {
          value: this.availableLanguages[1],
          label: _response[1].toUpperCase(),
        },
      ];
      this.textTranslatedByPipe = this.translatePipe.transform(
        'TRANSLATED_BY_COMPONENT'
      );
    });
    // console.log('current language,', this.translateService.currentLang)

  }
  logout() {
    this.oktaService.logoutSessions();
  }

  redirectToProfessional() {   
    if (this.country_code) {   
      const targetUrl = environment.helpUrl.replace('{country_code}', this.country_code);
      console.log('Redirecting to:', targetUrl);
      window.location.href = targetUrl;
    } else {
      console.warn('Country code not available; redirecting to default.');
      window.location.href = 'https://www.myalcon.com/professional';
    }
  }

  redirectToHelp(){
    if (this.country_code) {   
      const targetUrl = environment.faqPage.replace('{country_code}', this.country_code);
      console.log('Redirecting to:', targetUrl);
      window.location.href = targetUrl;
    } else {
      console.warn('Country code not available; redirecting to default.');
      window.location.href = 'https://www.myalcon.com/international/professional/myalconid/faqs/';
    }
  }

  sidebarToggle() {
    var body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible == false) {
      
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    }
  }

  // onLangSelect(lang: any) {
  //   console.log('selected lang...', lang)
  // }
  onLangSelect(lang: any) {
    console.log('selected lang...', lang)
    this.selectedLang = lang.language;
    if (lang.locale) {
      const selectedValue = lang.locale;
      this.lang = selectedValue;
      this.changeLanguage(selectedValue);
    }
    this.showLangSelection = false;
  }
  changeLanguage(language: string) {
    //    alert(language);

    this.translateService.use(language);
    this.textTranslatedByPipe = this.translatePipe.transform(
      'TRANSLATED_BY_COMPONENT'
    );
  }
  onHamburgerMenu() {
    this.menuOpen = !this.menuOpen;    
    this.menuStatus= this.menuOpen;    
    this.gotoTop();
    this.valueChange.emit('menu clicked');
    // const body = $("body");
    // body.scrollTop(0);
  }

  closebtn(){
    this.menuOpen = !this.menuOpen;  
  }


  getDataFromChild(event: MouseEvent): void  {    
    if(event)
    {
      this.menuOpen= false;
    }      
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
