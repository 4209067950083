import { Component, EventEmitter, Output } from '@angular/core';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  @Output() cancelAcc = new EventEmitter();
  newPasswordVisible = false;
  confirmPasswordVisible = false;
  newPasswd: any;
  newPassword = '';
  mismatch = false;
  passwordValidation = false;
  

  confirmPassword = '';
  email: any;
  oldPasswordVisible = false;

  emailCheck = false;
  length = false;
  lowerCase = false;
  upperCase = false;
  number = false;
  redirectUrl: any;
  url = '#';
  successArray: string;
  passwdMatch = true;
  validForm = true;
  resetResponse = false;
  statusCode: any;
  hasErrorInPasswordReset:boolean;
  isPasswordResetSuccess:boolean;
  errorArray: string ;
  error: Error | null = null;
  firstName: any;
  lastName: any;
  primaryEmail: any;
  firstNameCheck = false;
  lastNameCheck = false;
  commonPassword = false;
  recentPassword = false;
  currentPassword = false;
  usedPassword = false;
  apiError = false;
  resetPasswordForm = new FormGroup({
    oldpassword: new FormControl('',[Validators.required, Validators.minLength(5)]),
    newpassword: new FormControl('',[Validators.required, Validators.minLength(5)]),
  });  
  cdr: any;
  constructor(public profileService: ProfileService,
    public communcationService : CommunicationService
    ) {}

  ngOnInit(): void {
    this.getUserFormData();
  }
  async onResetPasswordSubmit() {
    // Reset error and success-related properties to hide previous messages
    this.hasErrorInPasswordReset = false;
    this.isPasswordResetSuccess = false;
    this.errorArray = ''; // Initialize as an empty string
    this.error = null;
    this.passwordValidation = false;
    this.successArray = ''; // Initialize as an empty array

    this.profileService.resetPassWord(this.newPasswd);
    this.profileService.resetPasswordResponse.subscribe(
      (data: any) => {
        if (data?.error) {
          const response = JSON.parse(data.error);
          const errorSummary = response.errorSummary;
          const errorDetails = response.errorCauses.map((cause: { errorSummary: any }) => cause.errorSummary).join(", ");          
          const updatedErrorDetails = this.handleErrorMessage(errorDetails);
           console.log('Updated Error Details:', updatedErrorDetails); 
           this.errorArray = updatedErrorDetails;
          // this.errorArray = errorDetails;
          this.hasErrorInPasswordReset = true;
          this.passwordValidation = true;
          return;
        }      
          if (data.status === 200) {
          this.successArray = 'Password Changed Successfully';
          this.passwordValidation = false;
          this.errorArray = '';
          this.error = null;
          this.isPasswordResetSuccess = true;
          this.hasErrorInPasswordReset = false;
          setTimeout(() => {
            this.cancelBtn();
          }, 2000);
        } 
        else if (data.status === 400) {
          const response = JSON.parse(data.error);
          const errorSummary = response.errorSummary;
          const errorDetails = response.errorCauses.map((cause: { errorSummary: any }) => cause.errorSummary).join(", ");
          const updatedErrorDetails = this.handleErrorMessage(errorDetails);
           console.log('Updated Error Details:', updatedErrorDetails); 
           this.errorArray = updatedErrorDetails;
          this.passwordValidation = true;
          this.successArray = '';
          this.isPasswordResetSuccess = false;
          this.hasErrorInPasswordReset = true;
        } 
        else if (data.status === 403) {
          this.errorArray = data.message.split('403')[1];
          this.successArray = '';
          this.isPasswordResetSuccess = false;
          this.hasErrorInPasswordReset = true;
        }
      },
      (error) => {
        console.log('error....', error);
      }
    );
  }
  onConfirmPassword(e: any) {
    this.mismatch = false;

    if (this.resetPasswordForm.get('oldpassword')?.value !== e.target.value) {
      this.mismatch = true;
    } else {
      this.mismatch = false;
    }
  }

  handleErrorMessage(errorDetails: string): string {
    if (errorDetails.includes('password:')) {
      errorDetails = errorDetails.replace('password:', 'Error:');
    }
    if (errorDetails.includes('Password cannot be your current password')) {
      return 'Error: New password cannot be your current password';      
    }
    return errorDetails;
  }
  onNewPassword(e: any) {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    // tslint:disable-next-line:prefer-const
    let numbers = /[0-9]/g;

    // this.emailCheck = this.isValidPassword(this.email, e.target.value);

    this.passwordValidation = true;
    this.newPasswd = e.target.value;

    if (this.newPasswd.length < 8) {
      this.length = true;
    } else {
      this.length = false;
    }
    // Validation for lowercase
    if (this.newPasswd.match(lowerCaseLetters)) {
      this.lowerCase = true;
    } else {
      this.lowerCase = false;
    }
    // Validation for uppercase
    if (this.newPasswd.match(upperCaseLetters)) {
      this.upperCase = true;
    } else {
      this.upperCase = false;
    }

    // validation for numbers
    if (this.newPasswd.match(numbers)) {
      this.number = true;
    } else {
      this.number = false;
    }
    
// Check if password matches the confirmed password
    if (e.target.value === this.confirmPassword) {
      this.passwdMatch = true;
    } else {
      this.passwdMatch = false;
    }

 // Additional checks for first name and last name in the password
    if (
      e.target.value
        .toLocaleLowerCase()
        .includes(this.firstName.toLocaleLowerCase())
    ) {
      this.firstNameCheck = true;
    } else {
      this.firstNameCheck = false;
    }

    if (
      e.target.value
        .toLocaleLowerCase()
        .includes(this.lastName.toLocaleLowerCase())
    ) {
      this.lastNameCheck = true;
    } else {
      this.lastNameCheck = false;
    }
  }
  isValidPassword(email: string, password: string) {
    // Split the email address into its parts
    const parts = email.split(/[.@\-=/_]/);

    // Check if the password includes any of the email address parts
    for (const part of parts) {
      if (password.toLocaleLowerCase().includes(part.toLocaleLowerCase())) {
        if (part.length > 3) {
          return true;
        }
      }
    }

    return false;
  }
  reset(event: any) {
    event.preventDefault();
    this.resetPasswordForm.patchValue({
      oldpassword: '',
      newpassword: '',
    });
  }
  getUserFormData() {
    this.profileService.UserProfile.subscribe((data: any) => {
      this.firstName = data?.first_name;
      this.lastName = data?.last_name;
    });
  }
  cancelBtn() {
    this.communcationService.cancelAll(false);
  }
  togglenewPasswordVisibile(): void {
    this.newPasswordVisible = !this.newPasswordVisible;
  }
  toggleconfirmPasswordVisibile(): void {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
  }
}
