
<div class="modal-header" style="overflow: hidden; position: relative;">
  <h4 class="modal-title">{{'confirmation' | translate}}</h4>

  <button type="button" class="close close-button" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label type="text">{{'confinrationmsg' | translate}}</label>
</div>
<div class="modal-footer">
  <button type="submit" style="background-color: #002362;" (click)="confirmme()" class="btn btn-primary w-100" style="background-color: #002362;"> {{'btncontinue' | translate}}</button>
</div>
