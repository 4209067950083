export const array_message_translations = {
    "zh_CN": [
      "您的操作已成功完成。",
      "请关闭此窗口并返回或重新启动 Alcon 应用程序。",
      "谢谢!"
    ],

    "zh": [
        "您的操作已成功完成。",
        "请关闭此窗口并返回或重新启动 Alcon 应用程序。",
        "谢谢!"
      ],
  
    "zh_TW": [
      "您的操作已成功完成。",
      "關閉此視窗並返回或重新啟動 Alcon 應用程式。",
      "謝謝!"
    ],
  
    "cs": [
      "Vaše akce byla úspěšně dokončena.",
      "Zavřete toto okno a vraťte se do systému Alcon nebo jej znovu spusťte.",
      "Děkuji!"
    ],
  
    "da": [
      "Din handling blev udført med succes.",
      "Luk dette vindue, og vend tilbage til eller genstart ALCON.",
      "Tak!"
    ],
  
    "nl": [
      "Uw actie is succesvol voltooid.",
      "Sluit dit venster en keer terug naar de Alcon of start deze opnieuw.",
      "Dank je wel!"
    ],
  
    "en": [
      "Your action completed successfully.",
      "Please close this window and return to the Alcon Application.",
      "Thank you!"
    ],
  
    "fi": [
      "Toimintosi onnistui.",
      "Sulje tämä ikkuna ja palaa takaisin Alcon-sovellukseen tai käynnistä se uudelleen.",
      "Kiitos!"
    ],
  
    "fr": [
      "Votre action a été effectuée avec succès.",
      "Veuillez fermer cette fenêtre et revenir à l’application Alcon ou la relancer.",
      "Merci!"
    ],
  
    "de": [
      "Ihre Aktion wurde erfolgreich abgeschlossen.",
      "Bitte schließen Sie dieses Fenster und kehren Sie zur Alcon-Anwendung zurück oder starten Sie sie neu.",
      "Danke!"
    ],
  
    "el": [
      "Η ενέργειά σας ολοκληρώθηκε με επιτυχία.",
      "Κλείστε αυτό το παράθυρο και επιστρέψτε ή επανεκκινήστε την εφαρμογή Alcon.",
      "Ευχαριστώ (Efcharistó)!"
    ],
  
    "hu": [
      "A művelet sikeresen befejeződött.",
      "Kérjük, zárja be ezt az ablakot, és térjen vissza az Alcon alkalmazáshoz, vagy indítsa újra.",
      "Köszönöm!"
    ],
  
    "id": [
      "Tindakan Anda berhasil diselesaikan.",
      "Silakan tutup jendela ini dan kembali ke atau mulai ulang Aplikasi Alcon.",
      "Terima kasih!"
    ],
  
    "it": [
      "L'azione è stata completata con successo.",
      "Si prega di chiudere questa finestra e tornare o riavviare l’applicazione Alcon.",
      "Grazie!"
    ],
  
    "ja": [
      "アクションが正常に完了しました。",
      "このウィンドウを閉じて、Alcon アプリケーションに戻るか再起動してください.",
      "ありがとう (Arigatou)!"
    ],
  
    "ko": [
      "작업이 성공적으로 완료되었습니다.",
      "이 창을 닫고 Alcon 애플리케이션으로 돌아가거나 다시 시작하세요.",
      "감사합니다 (Gamsahamnida)!"
    ],
  
    "my": [
      "Tindakan anda berjaya diselesaikan.",
      "Sila tutup tetingkap ini dan kembali atau mulakan semula Aplikasi Alcon.",
      "Terima kasih!"
    ],
  
    "no": [
      "Din handling ble fullført.",
      "Lukk dette vinduet og gå tilbake til eller start Alcon-applikasjonen på nytt.",
      "Takk!"
    ],
  
    "pl": [
      "Twoja akcja została pomyślnie zakończona.",
      "Proszę zamknąć to okno i wrócić lub ponownie uruchomić aplikację Alcon.",
      "Dziękuję!"
    ],
  
    "pt": [
      "Sua ação foi concluída com sucesso.",
      "Por favor, feche esta janela e retorne ou reinicie para a página de login Alcon.",
      "Obrigado!"
    ],
  
    "ro": [
      "Acțiunea dvs. a fost finalizată cu succes.",
      "Vă rugăm să închideți această fereastră și să vă întoarceți sau să reluați aplicația Alcon.",
      "Mulțumesc!"
    ],
  
    "ru": [
      "Ваше действие успешно завершено.",
      "Пожалуйста, закройте это окно и вернитесь или перезапустите приложение Alcon.",
      "Спасибо (Spasibo)!"
    ],
  
    "es": [
      "Su acción se ha completado con éxito.",
      "Por favor, cierre esta ventana y regrese o vuelva a iniciar la aplicación de Alcon.",
      "Gracias!"
    ],
  
    "sv": [
      "Din åtgärd slutfördes framgångsrikt.",
      "Stäng detta fönster och återvänd till eller starta om Alcon-applikationen.",
      "Tack!"
    ],
  
    "th": [
      "การกระทำของคุณเสร็จสมบูรณ์แล้ว",
      "โปรดปิดหน้าต่างนี้และกลับไปหรือเริ่มใช้งาน แอปพลิเคชัน Alcon อีกครั้ง",
      "ขอบคุณ (Khop khun)!"
    ],
  
    "tr": [
      "İşleminiz başarıyla tamamlandı.",
      "Bu pencereyi kapatın ve Alcon’a geri dönün veya yeniden başlatın.",
      "Teşekkür ederim!"
    ],
  
    "uk": [
      "Вашу дію успішно завершено.",
      "Закрийте це вікно та поверніться до Alcon або перезапустіть його.",
      "Дякую (Dyakuyu)!"
    ],
  
    "vn": [
      "Hành động của bạn đã hoàn thành thành công.",
      "Vui lòng đóng cửa sổ này và quay lại hoặc khởi động lại ứng dụng Alcon.",
      "Cảm ơn!"
    ]
  };