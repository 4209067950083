<form #f="ngForm"  [formGroup]="phoneForm">
  <div class="wrapper">
    <ngx-intl-tel-input
    #phoneInput
    type="text" [style]="'padding-left:0px;'"
      [cssClass]="'input-tel1 form-group form-control input-tel col-md-4 col-xl-4'"
      [preferredCountries]="preferredCountries"
      (countryChange)="onChangeCode($event)"
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="false"
      [searchCountryFlag]="true"
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
      [selectFirstCountry]="false" 
      [selectedCountryISO]="SearchCountryISO"   
      [maxLength]="15"
      [phoneValidation]="true"
      [separateDialCode]="separateDialCode"
      [numberFormat]="PhoneNumberFormat.National"
      name="phone"
      formControlName="phone"
      (change)="onInput($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (input)="onInput($event)"
      aria-describedby="button-addon2" required
    >
    </ngx-intl-tel-input>
  </div>
  <div>
    <div *ngIf="invalidNo" class="error-text">
      <span class="bi bi-exclamation-circle-fill" aria-label="Error"></span>
      {{'entervalidphone'  | translate}}
    </div>
  </div>
</form>
