import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { MyaccountService } from 'src/app/features/myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
@Component({
  selector: 'app-edit-phone',
  templateUrl: './edit-phone.component.html',
  styleUrls: ['./edit-phone.component.scss'],
})
export class EditPhoneComponent {
  @Output() cancelAcc = new EventEmitter();
  public userData: any;

  invalidNo = false;
  inphone: any;
  isValidPhoneNumber: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneForm: any = new FormGroup({
    phone: new FormControl(undefined, [Validators.required, Validators.minLength(5)]),
  });

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  constructor(
    public myaccountService: MyaccountService,
    public profileService: ProfileService,
    private route: Router,
    public communcationService : CommunicationService
  ) {}
  ngOnInit(): void {
    this.getUserFormData();


  }

  async getUserFormData() {
    this.userData = this.profileService.UserProfile.subscribe((data: any) => {
      this.userData = data;




      this.phoneForm.patchValue({
        phone: data.myacc_phone_no.substring(4).trim(),
      });
    });


  }
  async onChangeCode(event :any){
    // const temp = this.phoneForm.controls.value;
    // // const phoneUser =temp.internationalNumber.toString().substring(4).trim();
    // console.log(this.phoneForm.controls.phone.pendingValue,this.phoneForm.controls.phone)
  }

  onPhoneNumberSubmit() {
    if (this.phoneForm.invalid) {
      this.invalidNo = true;
    } else {
      this.invalidNo = false;
      let phone: any = this.phoneForm.value;



      let bodyParam = {
        profile: {
          primaryPhone: phone?.phone?.internationalNumber,
        },
      };
      this.myaccountService.updateUserProfile(bodyParam);
    }
    this.cancelBtn() ;
  }
  reset(event: any) {
    event.preventDefault();
    this.getUserFormData();
  }
  cancelBtn() {
    this.communcationService.cancelAll(false);
  }
}
