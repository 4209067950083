
// This card is build and used for My alcon home and the My brand Section
// Re uasble responsive component

import { Component ,EventEmitter,Input, OnInit, Output} from '@angular/core';
import { Card } from '../card/card';

import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-flat-card',
  templateUrl: './flat-card.component.html',
  styleUrls: ['./flat-card.component.scss']
})
export class FlatCardComponent {
  @Input() card!: Card;
  @Input() cardheight!: Number;
  myfaArrow = faArrowAltCircleRight; // need to find a  way to used the images based on configuraiton
  constructor() {}
  ngOnInit(): void {}
}
