// popup-modal.component.ts
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-popup-modal',
  templateUrl: './edit-secret-question-factor.popup-modal.component.html',
})
export class SecretQuestionPopupModalComponent {
  @Input() title: string = 'Verify Your Mobile';
  @Input() url: string = '';
  @Input() public user: any;
  @Output() verifyOTP: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  // Use DomSanitizer to mark the URL as safe
  safeUrl: SafeResourceUrl;

  verify() {
    this.verifyOTP.emit(this.user);
    }

  constructor(public bsModalRef: BsModalRef, private sanitizer: DomSanitizer,public activeModal: NgbActiveModal) {}

  ngOnInit() {
    // Mark the URL as safe when it changes
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    window.addEventListener('beforeunload', this.handlePageRefresh);

  }

  onClose(): void {
    this.close.emit();


  }

  handlePageRefresh = (): void => {
    // Close modal or emit close event on page refresh
    this.onClose();
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    if (this.activeModal) {
      this.activeModal.close();
    }
  };

  ngOnDestroy() {
    // Remove the page refresh listener to avoid memory leaks
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  }  

}
