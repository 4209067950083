import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { StepupService } from './stepup.service';

export function stepupGuard(btnname?:string): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const stepupService = inject(StepupService);

    if (stepupService.getCookie('stepup')) {
      //if(stepupService.getCookie(state.url)!.length >1){
        return true;
     // }
    }


    // if (stepupService.getCookie('stepup')) {
    //   return true;
    // }

    stepupService.setpUp(state.url,btnname?btnname:'');
    return false;
  };
}
