<ng-container *ngIf="profile | async as user">
  <ng-container>
    <div class="px-3 py-5 profile-card">
      <!-- <div class="back-link">&lt;<a href="https://www.myalcon.com/">Back to [origin/close window]</a></div> -->
      <div class="fw-light fs-1 pt-4 text-center head-title">
        <p>{{ "mnumpersonnelinformation" | translate }}</p>
      </div>
      <div class="fw-bold fs-3 personal-info">
        <p>{{ "mnumpersonnelinformation_subtitle" | translate }}<span> <i _ngcontent-ng-c767202758="" class="bi bi-chevron-down px-4 float-end"></i></span></p>
      </div>
    
      <div class="px-3">
        <div class="card rounded-left rounded-right h-100 my-3">
        
          <div class="row card-group py-2 px-3">
         
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix">
                  <div class="card-body">
                    <h5 class="card-title alcon-text-color fw-bold">
                      {{ "myaccountprofileheader" | translate }}
                    </h5>
                    <div class="float-start">
                      <p class="card-text lead">
                        {{
                          (user.myacc_title===undefined ? "": user.myacc_title + ". " )+
                           
                          (user.first_name===undefined ? "First Name": user.first_name) +
                            " " +
                            (user.last_name===undefined ? "Last Name": user?.last_name)
                        }}
                      </p>
                    </div>
                    <div *ngIf="IsEditProfileEnabled == true">
                      <button
                        type="button"
                        #myButton
                        *ngIf="!isProfileEditMode"
                        class="btn btn-edit py-1 end-float"
                        (click)="loadProfileComponent()"
                      >
                        <i class="bi bi-pencil"></i> {{ "btnedit" | translate }}
                      </button>
                    </div>
                  </div>
                  <div class="px-3">
                    <router-outlet
                      name="profile"
                      *ngIf="isProfileEditMode"
                    ></router-outlet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded-left rounded-right h-100 my-3">
          <div class="row card-group py-2 px-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix">
                  <div class="card-body">
                    <h5 class="card-title alcon-text-color fw-bold">
                      {{ "myaccountphonenumber" | translate }}
                    </h5>
                    <div class="">
                      <p class="card-text lead" *ngIf="!isPhoneEditMode">
                        {{ user.myacc_phone_no }}
                      </p>
                    </div>
                    <div *ngIf="IsEditProfileEnabled == true">
                      <button
                        type="button"
                        *ngIf="!isPhoneEditMode"
                        class="btn btn-edit py-1 end-float"
                        (click)="loadPhoneNumberComponent()"
                      >
                        <i class="bi bi-pencil"></i>
                        <span *ngIf="user.myacc_phone_no">
                          {{ "btnedit" | translate }}</span
                        >
                        <span *ngIf="!user.myacc_phone_no">
                          {{ "btnadd" | translate }}</span
                        >
                      </button>
                    </div>
                  </div>
                  <div>
                    <router-outlet
                      name="phone"
                      *ngIf="isPhoneEditMode"
                    ></router-outlet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded-left rounded-right h-100 my-3">
          <div class="row card-group py-2 px-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix">
                  <div class="card-body">
                    <h5 class="card-title alcon-text-color fw-bold">
                      {{ "myaccountemail" | translate }}
                    </h5>
                    <div class="float-start">
                      <p class="card-text lead">{{ user.email }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded-left rounded-right h-100 my-3">
          <div class="row card-group py-2 px-3">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix">
                  <div class="card-body">
                    <h5 class="card-title alcon-text-color mt-2 fw-bold">
                      {{ "myaccountpasswordreset" | translate }}
                    </h5>
                    <div class="">*******</div>
                    <div *ngIf="IsEditProfileEnabled == true">
                      <button
                        type="button"
                        *ngIf="!isPasswordResetMode"
                        class="btn btn-edit end-float"
                        (click)="loadResetPasswordComponent()"
                      >
                        <i class="bi bi-arrow-clockwise"></i>
                        {{ "btnreset" | translate }}
                      </button>
                    </div>
                  </div>
                  <div>
                    <router-outlet
                      class="px-3"
                      *ngIf="isPasswordResetMode"
                      name="resetpassword"
                    ></router-outlet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card rounded-left rounded-right h-100 my-3">
          <div class="row card-group py-2">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2">
              <div>
                <div class="clearfix"> -->
        <!-- <div class="card-body">
                    <h5 class="card-title alcon-text-color  px-3 mt-2 fw-bold"> {{ 'myaccountmfareset'| translate |
                      uppercase}}
                    </h5>
                  </div> -->
        <!-- <hr /> -->
        <!-- <div class="clearfix"> -->
        <!-- 
                    <div class="card-body">
                      <h6 class="card-title alcon-text-color px-3 fw-bold"> {{ 'myaccountphonenumber'| translate | uppercase}}</h6>
                      <div class="float-start">
                        <p class="card-text px-3 lead" > +0X1 22XX 2X XX1</p>
                      </div>
                      <div class="px-3" >
                        <button type="button" 
                       
                          class="btn btn-edit py-1 float-end" ><i
                            class="bi bi-pencil"></i>
                          <span> {{'btnenable'| translate}}</span>
                       <span > Add</span>
                        </button>
                        <button type="button" 
                          class="btn btn-submit py-1 float-end" ><i
                            class="bi bi-pencil"></i>
                          <span>  {{'btndisable'| translate}}</span>
                         <span > Add</span>
                        </button>
                      </div>
                    </div>  -->
        <!-- <div class="card-body">
                      <router-outlet name="phonefactor" ></router-outlet>
                    </div> -->
        <!-- </div> -->
        <!-- <hr /> -->
        <!-- <div class="clearfix" >
                    <div class="card-body">
                      <h6 class="card-title alcon-text-color px-3 fw-bold"> {{ 'myaccountsecret'| translate| uppercase}}</h6>
                      <div class="float-start">
                        <p class="card-text px-3 lead" *ngIf="!isSecretQuestionFactorEditMode"> XXX XXXXXX XXXXXXXXXXXXX
                        </p>
                      </div>
                      <div class="px-3" *ngIf="IsEditProfileEnabled == true && this.qsnFactors.length > 0">
                        <button type="button"
                          *ngIf="!isSecretQuestionFactorEditMode && this.qsnFactors[0].status != 'ACTIVE'"
                          class="btn btn-edit py-1 float-end" (click)="loadSecretFactorComponent()"><i
                            class="bi bi-pencil"></i>
                            <span> {{'btnenable'| translate}}</span>
                           <span *ngIf="!user.myacc_phone_no"> Add</span> -->
        <!-- </button> -->
        <!-- <button type="button"
                          
                          class="btn btn-submit py-1 float-end" (click)="disableSecretFactorComponent()"><i
                            class="bi bi-pencil"></i>
                            <span>  {{'btndisable'| translate}}</span>
                           <span *ngIf="!user.myacc_phone_no"> Add</span> -->
        <!-- </button> -->
        <!-- </div>
                    </div>
                    <div class="card-body">
                      
                      <router-outlet name="seceretfactors"  ></router-outlet>
                    </div>
                  </div>  -->
      </div>
      <!-- <button type="button" (click)="openPopup()"></button> -->
      <!-- </div> -->
      <!-- </div>
            </div>
          </div>
        </div> -->
    </div>
  </ng-container>
</ng-container>
