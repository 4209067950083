import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyaccountService } from '../../myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
import { ConfrimModalComponent } from '../../myalcon-account/confirmation.popup-modal.component';
import { Observable, Subscribable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StepupService } from 'src/app/core/stepup/stepup.service';
import { FactorsService } from '../services/factotoenroll.service';


@Component({
  selector: 'app-privacyandsecurity-settings',
  templateUrl: './privacyandsecurity-settings.component.html',
  styleUrls: ['./privacyandsecurity-settings.component.scss']
})
export class PrivacyandsecuritySettingsComponent implements OnInit {

  invalidData: any;
  public isPhoneFactorEditMode: boolean = false;
  public isSecretQuestionFactorEditMode: boolean = false;
  IsEditProfileEnabled: any;
  factorstoenroll: any[] = [];
  enrolledfactors: any[] = [];
  myfactors: any[] = [];
  smsFactors: any[] = [];
  qsnFactors: any[] = [];
  factorData: any;
  //isFactorsEnabled = true;
  profile: Observable<unknown> | Subscribable<unknown> | Promise<unknown>;
  userData: any;
  userid: any;
  enablePhoneFactorDisableButton = false;
  enableSecretQuestionDisablesButton = false;
  isPhoneFactorErrorMsg = '';
  isSecretQuestionFactorErrorMsg = '';
  defaultPhoneNumber: string = '+0X1 2234 XXX1';
  phoneNumberInput: string = '';
  securityQuestions: { question: string, questionText: string }[] = [];  
  selectedSecurityQuestion: string = '';
  // isPhoneFactorSubmitted: string = '';
  // isSecretQusFactorSubmitted: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private stepupService: StepupService,
    private cdr: ChangeDetectorRef,
    public factorsService: FactorsService,
    private modalService: NgbModal,
    private profileService: ProfileService,
  ) {
    
    // get all factors applicable for the user fill the behavious subject in service
    this.factorsService.getMyFactors();

    //
    //this.factorsService.getFeatureFlagsFromDB();
   
    // get the latest values and assign it to local varaibles to handle dispaly in UI
    this.communicationService.isPhoneFactorSourceTrue$.subscribe((value) => {
      this.isPhoneFactorEditMode = value;
    });
    this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe(
      (value) => {
        this.isSecretQuestionFactorEditMode = value;
      }
    );
    this.communicationService.isPhoneFactorErrorSummaryTrue$.subscribe((value) => {
      this.isPhoneFactorErrorMsg = value;
    });
    this.communicationService.isSecurityQuestionErrorSummaryTrue$.subscribe((value) => {
      this.isSecretQuestionFactorErrorMsg = value;
    });

    // to set and display UI element using local varibale value setting
    this.getMyFactors();


  }
  ngOnInit(): void {

    // based on the roaute load the childe components
    // if (this.router.url.includes('phonefactor')) {
    //   this.loadPhoneFactorComponent();
    // } else if (this.router.url.includes('seceretfactors')) {
    //   this.loadSecretFactorComponent();
    // }
  }

  /**
   * Loads the Phone Factor component.
   */
  loadPhoneFactorComponent() {
    this.isSecretQuestionFactorErrorMsg = '';
    this.isPhoneFactorErrorMsg = '';
    // Update the reset password source variable in the communication service
    this.communicationService.updatePhoneFactoreVariable(true);
    this.communicationService.updateResetSecretQuestionFactorVariable(false);

    //this.openPopup();
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      

      this.communicationService.isPhoneFactorSourceTrue$.subscribe((value) => {
        this.isPhoneFactorEditMode = value;
      });

      this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe((value) => {
        this.isSecretQuestionFactorEditMode = value;
      });

      //modalRef.close();
      this.getMyFactors();
      this.router.navigate([
        'privacyandsecuritysettings',
        {
          outlets: {
            phonefactor: ['phonefactor'],
            seceretfactors: null,
          },
        },
      ]);
      modalRef.close();
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      // this.isPhoneFactorSubmitted = 'Phone factor component loaded successfully!'; // Set success message

    // setTimeout(() => {
    //   this.isPhoneFactorSubmitted = ''; // Clear after a short delay
    // }, 3000);
      this.communicationService.updatePhoneFactoreVariable(false);
      this.communicationService.updateResetSecretQuestionFactorVariable(false);
      this.communicationService.isPhoneFactorSourceTrue$.subscribe((value) => {
        this.isPhoneFactorEditMode = value;
      });
      this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe((value) => {
        this.isSecretQuestionFactorEditMode = value;
      });

      this.getMyFactors();
      modalRef.close();
    });
    // Navigate to the reset password component in the myaccount outlet
  }

  /**
   * Loads the Secret Qsn component.
   */
  loadSecretFactorComponent() {
    this.isSecretQuestionFactorErrorMsg = '';
    this.isPhoneFactorErrorMsg = '';
    // Update the reset password source variable in the communication service
    this.communicationService.updateResetSecretQuestionFactorVariable(true);
    this.communicationService.updatePhoneFactoreVariable(false);
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe(
        (value) => {
          this.isSecretQuestionFactorEditMode = value;
        }
      );
      this.communicationService.isPhoneFactorSourceTrue$.subscribe(
        (value) => {
          this.isPhoneFactorEditMode = value;
        }
      );
      //modalRef.close();
      this.getMyFactors();
      this.router.navigate([
        'privacyandsecuritysettings',
        {
          outlets: {
            phonefactor: null,
            seceretfactors: ['seceretfactors'],
          },
        },
      ]);
      modalRef.close();
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.communicationService.updateResetSecretQuestionFactorVariable(false);
      this.communicationService.updatePhoneFactoreVariable(false);
      this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe(
        (value) => {
          this.isSecretQuestionFactorEditMode = value;
        }
      );
      this.communicationService.isPhoneFactorSourceTrue$.subscribe(
        (value) => {
          this.isPhoneFactorEditMode = value;
        }
      );
      this.getMyFactors();
      modalRef.close();
    });
    // Navigate to the reset password component in the myaccount outlet
  }

  // service call to remove / disable already enabled phone factor
  disablePhoneFactorComponent() {
    //this.openPopup();
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
      this.userData = this.profileService.UserProfile.subscribe((data: any) => {
        this.userData = data;
        this.userid = data.sub;
        this.qsnFactors = this.enrolledfactors.filter(
          (factor) => factor.factorType === 'sms'
        );
        if (this.qsnFactors.length == 0) {
          alert('There is no phone factor to disable')
        }
        this.factorsService
          .deleteFactor(this.qsnFactors[0].id, data.sub)
          .subscribe((response) => {
            // Process the response here
            this.factorsService.getMyFactors();
            //    console.log(response);
            this.resetFactorDataToDefault();
          });
      });
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    //   this.resetFactorDataToDefault();
    });

  }

  // service call to remove / disable already Secret QSN factor
  disableSecretFactorComponent() {
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
      this.userData = this.profileService.UserProfile.subscribe((data: any) => {
        this.userData = data;
        this.userid = data.sub;
        this.qsnFactors = this.enrolledfactors.filter(
          (factor) => factor.factorType === 'question'
        );
        this.factorsService
          .deleteFactor(this.qsnFactors[0].id, data.sub)
          .subscribe((response) => {
            // Process the response here
            this.factorsService.getMyFactors();
            this.resetSecurityQuestions();
            //  console.log(response);
          });
      });
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    });
    // this.openPopup();

  }

  //local mehtod to get the factors and display the factor based on the values
  getMyFactors(): void {
    this.factorsService.getenrolledFactors().subscribe((features) => {
      this.enrolledfactors = features;
    //   this.isPhoneFactorSubmitted = 'Enrolled factors loaded successfully!';
    // setTimeout(() => {
    //   this.isPhoneFactorSubmitted = ''; // Clear message after display
    // }, 3000);

    });
    this.factorsService.getfactorsToEnrolled().subscribe((factorstoenroll) => {
      this.factorstoenroll = factorstoenroll;
      if (this.factorstoenroll) {
        this.smsFactors = this.factorstoenroll!.filter(
          (factor) => factor.factorType === 'sms' && factor.status == "ACTIVE"
        );
        this.qsnFactors = this.factorstoenroll!.filter(
          (factor) => factor.factorType === 'question' && factor.status == "ACTIVE"
        );
        if (this.smsFactors.length > 0) {
          this.enablePhoneFactorDisableButton = true;   

          this.factorData = this.smsFactors[0].profile.phoneNumber;
          // this.factorData = this.smsFactors.map(factor => factor.profile.phoneNumber);
        }
        else {
          this.enablePhoneFactorDisableButton = false;
          
        }
        if (this.qsnFactors.length > 0) {
          this.enableSecretQuestionDisablesButton = true;
          

          this.extractSecurityQuestions();
        }
        else {
          this.enableSecretQuestionDisablesButton = false;
         
        }

      }
    });
   
  }

  resetSecurityQuestions(): void {
    this.securityQuestions = [];
  }

  maskQuestion(question: string): string {
    const visibleLength = 23; // Show the first 23 characters
    const maskedPart = question.slice(0, visibleLength); // Get the first 23 characters
    return maskedPart + '***'; // Add three asterisks at the end
  }
  
  extractSecurityQuestions(): void {
    this.securityQuestions = this.qsnFactors.map((factor) => {
      return {
        question: factor.profile.question,
        questionText: this.maskQuestion(factor.profile.questionText),
      };
    });
  }
  

  // pop up modal helper method
  openPopup(): void {
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();

    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    });
  }
  clearPhoneNumber(): void {
    this.phoneNumberInput = '';
  }

  validateAndSubmitPhoneNumber(): void {
    const phoneNumberPattern = /^\+?\d{1,4}\s?\d{4}\s?\d{4}$/; // Adjust pattern as needed
    if (phoneNumberPattern.test(this.phoneNumberInput)) {
      this.defaultPhoneNumber = this.phoneNumberInput;
      this.loadPhoneFactorComponent();
    } else {
      alert('Invalid phone number. Please enter a valid phone number.');
    }
  }
  getMaskedPhoneNumber(): string {
    const phoneNumber = this.factorData || this.defaultPhoneNumber;
    if (!phoneNumber) {
      return '****';;
    }
    // Mask half of the phone number
    const visibleDigits = 4; // Number of digits to keep visible from the end
  return '*'.repeat(phoneNumber.length - visibleDigits) + phoneNumber.slice(-visibleDigits);
  }

  resetFactorDataToDefault(): void {
    this.factorData = this.defaultPhoneNumber;
  }
}
