
let region = sessionStorage.getItem('region');

export const environment = {
  IsEditProfileEnabled  :  true,
  myalconaccOktaProfileUS: {
    domain: 'dev2-axon.myalcon.com',
    host: 'https://dev2-axon.myalcon.com',
    issuer: 'https://dev2-axon.myalcon.com/oauth2/aus5kk17b3KfCwjA91d7',
    clientId: '0oafyb2ilyz7Zz7PW1d7',
    universallogouttemptoken: '00OnvBisEsDIFVDKgX_dF0OMAeb-m4MKbPguR9-0S-',
    redirectUri: window.location.origin + '/region/americas/login/callback',
    postLogoutRedirectUri:  window.location.origin +  '/region/americas',
  },
  myalconaccOktaProfileEU: {
    domain: 'd-eu-id.myalcon.com',
    host: 'https://d-eu-id.myalcon.com',
    previewhost: 'https://d-eu-id.myalcon.com',
    issuer: 'https://d-eu-id.myalcon.com/oauth2/aus7rfj214CrpOTI90x7',
    clientId: '0oa8fpegxgEs8scuS0x7',
    universallogouttemptoken: '00OnvBisEsDIFVDKgX_dF0OMAeb-m4MKbPguR9-0S-',
    redirectUri: window.location.origin + '/region/emea/login/callback',
    postLogoutRedirectUri:  window.location.origin +  '/region/emea'
  },
  myalconaccOktaProfileAP: {
    domain: 'd-ap-id.myalcon.com',
    host: 'https://d-ap-id.myalcon.com',
    previewhost: 'https://d-ap-id.myalcon.com',
    issuer: 'https://d-ap-id.myalcon.com/oauth2/ausbhpnwwqTufcvEu1d7',
    clientId: '0oafycdtm3mTcgZUW1d7',
    universallogouttemptoken: '00OnvBisEsDIFVDKgX_dF0OMAeb-m4MKbPguR9-0S-',
    redirectUri: window.location.origin + '/region/apac/login/callback',
    postLogoutRedirectUri: window.location.origin +  '/region/apac'
  },
  mulesoftOktaProfile :{
    mulesoftapisbaseurl: 'https://dev.api.alcon.com/axon-cdb-exp-api/api',
    //https://dev.api.alcon.com/axon-cdb-exp-api/api/user-profile
    //'https://dev.api.alcon.com/alcon-generic-proxy-api/api/{region}/okta/users/user-id'
    mulesoftgenericapisbaseurl: 'https://dev.api.alcon.com/alcon-generic-proxy-api/api/{region}',
    clientId: 'f1f09652bed5429eb6161a6f4065d33b',
    clientSecret :  '0B9A0197e6E84E05882019b8CF8E85e0',
    multitenenetclientId: '8c6c3d2d364f4b39bd4bb02f5f2672f3',
    multitenenetclientSecret :  '5618CCFfA6584e91be5D3E2035e8f634',
    mulesoftfactorsapisbaseurls:"https://dev.api.alcon.com/alcon-generic-proxy-api/api/{region}/okta",
    mulesoftfactorsapisclientId:"8c6c3d2d364f4b39bd4bb02f5f2672f3",
    mulesoftfactorsapisclientSecret:"5618CCFfA6584e91be5D3E2035e8f634",
    jwksUrl: 'https://dev.api.alcon.com/okta-multitenant-exp/api/jwksUrl/'
  },
  apilist : {
    userapis : {
      myprofileupdate: '/user-profile',
      resetpassword: 'https://dev2-axon.myalcon.com/idp/myaccount/password',
      universallogout: '/users/sessions',
      createUserAuditLogs: '/createRecords?tableName=axon-audit-trial',
    },
    userpreferences :
    { 
      getmypreferences:'/getRecords?tableName=axon-oie-identity-prefreneces-configs',
      getusermypreferences:'/getRecords?tableName=axon-oie-identity-user-prefreneces-mapping&field=userID&id=user_id',
      createmypreferences:'/createRecords?tableName=axon-oie-identity-user-prefreneces-mapping',
      updatemypreferences:'/updateRecords?tableName=axon-oie-identity-user-prefreneces-mapping',
    },
    apps:{
      myentitledapps: "/apps",
      appsconfigs: "/apps/config"
    },
   
  },
  oktaGenric:{
    factors:{
      factorAPIendpoint:'https://dev.api.alcon.com/alcon-generic-proxy-api/api/'+region+'/okta/users/user-id'


    }
  },
  reviewRequestUrl:{
    approvalUrl:
    'https://dev.api.alcon.com/axon-cdb-exp-api/api/b2b/registration/status',
    requestorDetailsUrl:"https://dev.api.alcon.com/axon-cdb-exp-api/api/cdb-ticket-info",
    adiClientId:'5537904f87ad405a8c52f3b13f477fac',
    adiClientSecrete:'41FeF136153E453193C01cd230Ba040D'
  }
};
