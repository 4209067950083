<div class="modal-header" style="overflow: hidden;">
  <h4 class="modal-title">{{ 'btncerifyphone' | translate}}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="onClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label type="text">Enter OTP</label>
  <input [(ngModel)]="passCode" class="form-control" type="text" />
</div>
<div class="modal-footer">
  <button style="background-color: #002362;" (click)="verify()" type="submit" class="btn btn-primary w-100">
    {{'verifyandenable' | translate}}
  </button>
</div>
