import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyaccountService } from 'src/app/features/myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { EventType, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';


@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss'],
})
export class EditAccountComponent {
  invalidData = false;
  @Output() cancelAcc = new EventEmitter();
  public userData: any;
  public userProfileData:any;
  public currentRoute: any;
  profileForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
  });
  public profile = this.profileService.UserProfile;
  constructor(
    public myaccountService: MyaccountService,
    public profileService: ProfileService,
    public router: Router,
    public communcationService: CommunicationService
  ) {}

  ngOnInit(): void {
    this.getUserFormData();
    this.getUserProfile();
  }
  getUserFormData() {
    this.userData = this.profileService.UserProfile.subscribe((data: any) => {
      this.userData = data;
      console.log(this.userData);
      this.profileForm.patchValue({
        title: this.userData.myacc_title,
        firstName: this.userData.first_name,
        lastName: this.userData.last_name,
      });
    });
  }
  async getUserProfile() {
   

    try {
      const response = await this.myaccountService.getUserProfile();
      this.myaccountService.userProfileData.subscribe(
        (data: any) => {
          
          console.log('user data...',data)
          this.userProfileData = data;
        },
        (error) => {
          console.log('error....', error);
        }
      );
      //this.sendMessageToParentProfileUpdated();
    } catch (error) {
      console.error('Update failed', error);
    }
    
   
  }
  

  async onProfileSubmit() {
   
    // this.isEditMode = !this.isEditMode;
    // TODO: Use EventEmitter with form value
    if (this.profileForm.invalid) {
      this.invalidData = true;
    } else {
    let bodyParam = {
      profile: this.profileForm.value,
    };
    this.userProfileData.profile.firstName = this.profileForm.value.firstName;
    this.userProfileData.profile.lastName = this.profileForm.value.lastName;
    this.userProfileData.profile.title = this.profileForm.value.title;
    try {
      const response = await this.myaccountService.updateUserProfile(this.userProfileData);
      //this.sendMessageToParentProfileUpdated();
    } catch (error) {
      console.error('Update failed', error);
    }
    // this.myaccountService.updateUserProfile(bodyParam).then((data: any) => {
    //   this.sendMessageToParentProfileUpdated();
    // })
    // Call the function whenever the content changes its size
    // For example, after loading or after a dynamic update
   // setTimeout(() => {

   // }, 2000);
    this.cancelBtn() ;
  }
  }

  // Child App Script (SPA)
  // This code should be executed in the child app that is loaded inside the iframe

  // Send the height information to the parent window

  reset(event: any) {
    event.preventDefault();
    this.getUserFormData();
  }
  cancelBtn() {
    this.communcationService.cancelAll(false);
  }


}
