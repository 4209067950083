import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyaccountService } from '../../myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
import { ConfrimModalComponent } from '../../myalcon-account/confirmation.popup-modal.component';
import { Observable, Subscribable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StepupService } from 'src/app/core/stepup/stepup.service';
import { FactorsService } from '../services/factotoenroll.service';


@Component({
  selector: 'app-privacyandsecurity-settings',
  templateUrl: './privacyandsecurity-settings.component.html',
  styleUrls: ['./privacyandsecurity-settings.component.scss']
})
export class PrivacyandsecuritySettingsComponent implements OnInit {

  invalidData: any;
  public isPhoneFactorEditMode: boolean = false;
  public isSecretQuestionFactorEditMode: boolean = false;
  IsEditProfileEnabled: any;
  factorstoenroll: any[] = [];
  enrolledfactors: any[] = [];
  myfactors: any[] = [];
  smsFactors: any[] = [];
  qsnFactors: any[] = [];
  isFactorsEnabled = true;
  profile: Observable<unknown> | Subscribable<unknown> | Promise<unknown>;
  userData: any;
  userid: any;
  enablePhoneFactorDisableButton=false;
  enableSecretQuestionDisablesButton=false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,

    private communicationService: CommunicationService,
    private stepupService: StepupService,
    private cdr: ChangeDetectorRef,
    public factorsService: FactorsService,
    private modalService: NgbModal,
   
    private profileService: ProfileService,



  ) {
    this.factorsService.getMyFactors();
    this.factorsService.getFeatureFlagsFromDB();
    this.communicationService.isPhoneFactorSourceTrue$.subscribe((value) => {
      this.isPhoneFactorEditMode = value;
    });

    this.communicationService.isSecretQuestionFactorSourceTrue$.subscribe(
      (value) => {
        this.isSecretQuestionFactorEditMode = value;
      }
    );
    this.getMyFactors();

    
  }
  ngOnInit(): void {

  }
  
  /**
   * Loads the reset password component.
   */
  loadPhoneFactorComponent() {
    // Update the reset password source variable in the communication service
    this.communicationService.updatePhoneFactoreVariable(true);
    //this.openPopup();
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      //modalRef.close();
      this.getMyFactors();
      this.router.navigate([
        'privacyandsecuritysettings',
        {
          outlets: {
            phonefactor: ['phonefactor'],
            seceretfactors: null,
          },
        },
      ]);
      modalRef.close();
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      //this.isPhoneFactorEditMode = false;
      modalRef.close();
    });
    // Navigate to the reset password component in the myaccount outlet
  }
  
  /**
   * Loads the reset password component.
   */
  loadSecretFactorComponent() {
    // Update the reset password source variable in the communication service
    this.communicationService.updateResetSecretQuestionFactorVariable(true);
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      //modalRef.close();
      this.getMyFactors();      
      this.router.navigate([
        'privacyandsecuritysettings',
        {
          outlets: {
            phonefactor: null,
            seceretfactors: ['seceretfactors'],
          },
        },
      ]);
      modalRef.close();
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      //this.isSecretQuestionFactorEditMode =false;
      modalRef.close();
    });
    // Navigate to the reset password component in the myaccount outlet
  }

  disablePhoneFactorComponent() {
    //this.openPopup();
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();      
      this.userData = this.profileService.UserProfile.subscribe((data: any) => {
        this.userData = data;
        this.userid = data.sub;        
        this.qsnFactors = this.enrolledfactors.filter(
          (factor) => factor.factorType === 'sms'
        );
        if(this.qsnFactors.length==0){
          alert('There is no phone factor to disable')
        }
        this.factorsService
          .deleteFactor(this.qsnFactors[0].id, data.sub)
          .subscribe((response) => {
            // Process the response here
            this.factorsService.getMyFactors();
        //    console.log(response);
          });
      });
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    });

  }

  disableSecretFactorComponent() {
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
      this.userData = this.profileService.UserProfile.subscribe((data: any) => {
        this.userData = data;
        this.userid = data.sub;
        this.qsnFactors = this.enrolledfactors.filter(
          (factor) => factor.factorType === 'question'
        );
        this.factorsService
          .deleteFactor(this.qsnFactors[0].id, data.sub)
          .subscribe((response) => {
            // Process the response here
            this.factorsService.getMyFactors();
          //  console.log(response);
          });
      });
    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    });
   // this.openPopup();

  }
  getMyFactors(): void {    
    this.factorsService.getenrolledFactors().subscribe((features) => {
      this.enrolledfactors = features;
    });
    this.factorsService.getfactorsToEnrolled().subscribe((factorstoenroll) => {
      this.factorstoenroll = factorstoenroll;
      if(this.factorstoenroll){
        this.smsFactors = this.factorstoenroll!.filter(
          (factor) => factor.factorType === 'sms'
        );
        this.qsnFactors = this.factorstoenroll!.filter(
          (factor) => factor.factorType === 'question'
        );
        if(this.smsFactors.length>0)
        {
          this.enablePhoneFactorDisableButton=true;
         
          //this.isPhoneFactorEditMode = true;
          //this.communicationService.updatePhoneFactoreVariable(true)
        }
        else
        {
          this.enablePhoneFactorDisableButton=false;
         
       // this.isPhoneFactorEditMode = false;
       // this.communicationService.updatePhoneFactoreVariable(false)
        }
        if(this.qsnFactors.length>0) {

          this.enableSecretQuestionDisablesButton=true;
       //   this.isSecretQuestionFactorEditMode = true;
       //  this.communicationService.updateResetSecretQuestionFactorVariable(true)
        
        }
        else
        {
          this.enableSecretQuestionDisablesButton=false;
        //  this.communicationService.updatePhoneFactoreVariable(false)
       // this.isSecretQuestionFactorEditMode = false;
        }

      }
    });
    // this.myfactors = this.factorstoenroll!.filter((factor) =>
    //   this.enrolledfactors.includes(factor)
    // );
  }
  async onProfileSubmit() {
    // this.isEditMode = !this.isEditMode;
    // TODO: Use EventEmitter with form value
    // if (this.profileForm.invalid) {
    //   this.invalidData = true;
    // } else {
    // let bodyParam = {
    //   profile: this.profileForm.value,
    // };

    // try {
    //   const response = await this.myaccountService.updateUserProfile(bodyParam);
    //   //this.sendMessageToParentProfileUpdated();
    // } catch (error) {
    //   console.error('Update failed', error);
    // }
    // this.myaccountService.updateUserProfile(bodyParam).then((data: any) => {
    //   this.sendMessageToParentProfileUpdated();
    // })
    // Call the function whenever the content changes its size
    // For example, after loading or after a dynamic update
    // setTimeout(() => {

    // }, 2000);
    //    this.cancelBtn() ;
    // }
  }
  openPopup(): void {
    const modalRef = this.modalService.open(ConfrimModalComponent);
    modalRef.componentInstance.conformed.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();

    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      this.getMyFactors();
      modalRef.close();
    });
  }

}
