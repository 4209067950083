// popup-modal.component.ts
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-popup-modal',
  templateUrl: './signout.popup-modal.component.html',
})
export class SignOutModalComponent {
  @Output() confirmsignput: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  // Use DomSanitizer to mark the URL as safe
  safeUrl: SafeResourceUrl;

  confirmsingout() {
    this.confirmsignput.emit();
    }

  constructor(public bsModalRef: BsModalRef, private sanitizer: DomSanitizer,public activeModal: NgbActiveModal,private translate: TranslateService, ) {}

  ngOnInit() {
    // Mark the URL as safe when it changes
    //this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  onClose(): void {
    this.close.emit();
  }
}
