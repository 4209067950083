import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyalconPreferencesRoutingModule } from './myalcon-preferences-routing.module';
import { MyalconPreferencesComponent } from './myalcon-preferences.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { UniqueArrayPipe } from 'src/app/core/pipes/filterUnique';
import { EditPhoneComponent } from './editforms/edit-phone/edit-phone.component';


@NgModule({
  declarations: [
    MyalconPreferencesComponent,UniqueArrayPipe,EditPhoneComponent
  ],
  imports: [
    CommonModule,FormsModule,SharedModule,
    MyalconPreferencesRoutingModule, TranslateModule.forChild(),NgxIntlTelInputModule,ReactiveFormsModule
  ]
})
export class MyalconPreferencesModule { }
