
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyalconAccountRoutingModule } from '../myalcon-account/myalcon-account-routing.module'; 

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedModule } from 'src/app/shared.module';
import { MyalconAccountComponent } from '../myalcon-account/myalcon-account.component'; 
import { EditAccountComponent } from '../myalcon-account/editforms/edit-account/edit-account.component'; 
import { EditPhoneComponent } from '../myalcon-account/editforms/edit-phone/edit-phone.component'; 
import { ResetPasswordComponent } from '../myalcon-account/editforms/reset-password/reset-password.component'; 
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ConfrimModalComponent } from '../myalcon-account/confirmation.popup-modal.component';
import { SignOutModalComponent } from 'src/app/core/commoncomponent/card/signout.popup-modal.component';
import { PrivacyandsecuritySettingsComponent } from './privacyandsecurity-settings/privacyandsecurity-settings.component';
import { PrivacyandsecuritySettingsRoutingModule } from './privacyandsecurity-settings/privacyandsecurity-settings-routing.module';
import { EditSecretQuestionFactorComponent } from './editforms/edit-secret-question-factor/edit-secret-question-factor.component';
import { PhoneFactorPopupModalComponent } from './editforms/edit-phone-factor/edit-phone-factor.popup-modal.component';
import { SecretQuestionPopupModalComponent } from './editforms/edit-secret-question-factor/edit-secret-question-factor.popup-modal.component';
import { EditPhoneFactorComponent } from './editforms/edit-phone-factor/edit-phone-factor.component';
@NgModule({
  declarations: [
    //  EditPhoneFactorComponent,
    PrivacyandsecuritySettingsComponent,
    SecretQuestionPopupModalComponent,
    EditSecretQuestionFactorComponent, PhoneFactorPopupModalComponent, EditPhoneFactorComponent
  ],
  imports: [  TranslateModule.forChild(),
    CommonModule,SharedModule,ReactiveFormsModule,NgxIntlTelInputModule,FormsModule,
    PrivacyandsecuritySettingsRoutingModule,ModalModule.forRoot(),NgbModule
  ]
})

export class PrivacyandSecuritySettingsModule { }
