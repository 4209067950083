
// This card is build and used for My alcon home and the Cusomer Support Section
// Re uasble responsive component

import { Component, Input } from '@angular/core';
import { Card, MyHomSalesRepCard } from '../card/card';

@Component({
  selector: 'app-customer-support-card',
  templateUrl: './customer-support-card.component.html',
  styleUrls: ['./customer-support-card.component.scss']
})
export class CustomerSupportCardComponent {
  @Input() card!: MyHomSalesRepCard;
  @Input() cardheight!: Number;
  constructor() {}
  ngOnInit(): void {}
}

