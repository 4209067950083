import { Component } from '@angular/core';
import { NavigatorService } from '../navigator.service';
import { ProfileService } from '../core/services/common/profile.service';
import { array_message_translations } from './translations';


@Component({
  selector: 'app-maintain-page',
  templateUrl: './maintain-page.component.html',
  styleUrls: ['./maintain-page.component.scss']
})
export class MaintainPageComponent {
  translations = array_message_translations;
  selectedLanguagearray: string[] = [];
  userLanguage = '' 
  constructor( private navigatorService: NavigatorService,private profileService:ProfileService) {
  }
  ngOnInit(): void {
    var lang:string = (navigator.language).substring(0, 2);
    //alert(lang);
    console.log(this.profileService.maintainPagestatus.getValue());
    const message = array_message_translations[lang as keyof typeof array_message_translations];
    this.selectedLanguagearray = this.translations[lang.toString() as keyof typeof this.translations];
    if (!this.selectedLanguagearray) {
      this.selectedLanguagearray = this.translations["en"];; // Fallback to English message
    }
  }
}
