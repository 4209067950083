<ng-container *ngIf="profile | async as user">
  <ng-container>
    <div class="langDropdown" *ngIf="showLangSelection">
      <label class="langselect-label px-3">Select your preferred language</label>
      <div class="container lang-dropdown">
        <div class="lang-cardComp item " *ngFor="let lang of languageList" (click)="onLangSelect(lang)">
          {{lang.language}}
        </div>
      </div>
    </div>
    <div class="sidenav-body-container py-5">
      <div
        class="sidenav-body text-center"
        [class.slide-in-from-left]="sidenavService.isSlidingInFromLeft"
        [class.slide-in-from-right]="sidenavService.isSlidingInFromRight"
      >
      <!-- <div>
        <img src="/assets/img/closeBtn.png" class="tab-view tab-closebtn" (click)="closeMenu()" />

      </div> -->
        <div class="profile" *ngIf="sidenavService.isExpanded">
          <div class="text-center big-circle" *ngIf="user.first_name">
            <div class="text-center fs-1 userinitial pt-4">
              <span style="position:relative;">{{ user.first_name | slice : 0 : 1}}</span>
              <span>{{ user.last_name | slice : 0 : 1 }}</span>
            </div>
          </div>
          <br />
          <div class="text-center email">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div class="text-center sub-email">{{ user.email }}</div>
          <div class="text-center fs-6 language-dropdown d-lg-none d-xl-none" *ngIf="mobileView || isIpadMiniPortrait || isIpadMiniLandscape" (click)="onLangDropdown()">
            Language: {{selectedLang}}            
          </div>
          <br />
          <div class="text-center">
            <div class="ring" *ngIf="loading">
              Loading.
              <span class="span"></span>
            </div>           
          </div>
        </div>
        <!-- <ng-template sidenavContentArea></ng-template> -->
        <div *ngFor="let menu of menuData">
          <ng-container *ngIf="menu.show">
            <ng-container *ngIf="menu.useHref; else useRouterLink">           
              <a  [attr.href]="menu.useHref ? menu.route : null"
               routerLinkActive="active"
              [ngClass]="{ 'active': selectedMenuItem === menu.label }"
              (click)="onMenu($event)" 
              style="color:white;text-decoration: none;">  
              <div class="menucontainer">
                  <div class="input-group menu-style">
                    <div>
                      <img alt="{{menu.label}}" src="{{ menu.faImage }}" />                     
                    </div>
                    <span class="link-text"  >{{ menu.label | translate }} </span>
                  </div>
                </div>
              </a>            
            </ng-container>
            <ng-template #useRouterLink>
              <a [routerLink]="menu.route" 
              routerLinkActive="active" 
              [ngClass]="{ 'active': selectedMenuItem === menu.label }" 
              (click)="onMenu($event)"
               style="color:white;text-decoration: none;">
                <div class="menucontainer">
                  <div class="input-group menu-style">
                    <div>
                      <img alt="{{menu.label}}" src="{{ menu.faImage }}" />                    
                    </div>
                    <span class="link-text" >{{ menu.label | translate }} </span>
                  </div>
                </div>
              </a>
            </ng-template>
          </ng-container>
        </div>
        <app-oval-button
          label="{{ 'logout' | translate }}"
          [css]="ovalLogOutButton"
          [icon]="myIcon"
          (click)="logout()"
        >
        </app-oval-button>
      
      </div>
    </div>

    <!-- Resize Handle -->
   
    
  </ng-container>
</ng-container>
