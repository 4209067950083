import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpResponseProvider } from '../../../core/services/common/http-response-provider.service';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
interface Claim {
  claim: string;
  value: any;
}
@Injectable({
  providedIn: 'root',
})
export class AppsService {
  private allAppConfigsSubject = new BehaviorSubject<any[]>([]);
  private allEntitledAppConfigsSubject = new BehaviorSubject<any[]>([]);
  private isDataFetchCompleteSubject = new BehaviorSubject<boolean>(false);

  allAppConfigs$ = this.allAppConfigsSubject.asObservable();
  allEntitledApps$ = this.allEntitledAppConfigsSubject.asObservable();
  isDataFetchComplete$ = this.isDataFetchCompleteSubject.asObservable();

  claims: Claim[] = [];
  private queryStrings = '?isactive=true';
  constructor(
    private http: HttpClient,
    private httpResponseProvider: HttpResponseProvider,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {}

  getAllAppConfigs() {
    const apiUrl =
      environment.mulesoftOktaProfile.mulesoftapisbaseurl +
      environment.apilist.apps.appsconfigs;
    const   headers: HttpHeaders = this.httpResponseProvider.addMuleSoftHeader();
    this.oktaAuth.getUser().then((res) => {
      this.claims = Object.entries(res).map((entry) => ({
        claim: entry[0],
        value: entry[1],
      }));
      const groupsClaim: Claim | undefined = this.claims.find(item => item.claim === "Groups");
      if(groupsClaim && groupsClaim.value) {
        groupsClaim.value.forEach((element: any) => {
          if (element == 'Professional') {
            this.queryStrings = this.queryStrings + `&isprofessional=true`;
          } else if (element == 'Consumers') {
            this.queryStrings = this.queryStrings + `&isconsumer=true`;
          } else if (element == 'Vc') {
            this.queryStrings = this.queryStrings + `&isvisioncare=true`;
          } else if (element == 'Sx') {
            this.queryStrings = this.queryStrings + `&issurgical=true`;
          }  else {
          }
        });
      }
    });

    this.http
    .get<any[]>(apiUrl + this.queryStrings, { headers:headers })
    .subscribe(
      (apps) => {
        this.allAppConfigsSubject.next(apps);
      },
      null,
      () => this.complete()
    );

  }

  getAssignedApps() {
    const   headers: HttpHeaders = this.httpResponseProvider.addMuleSoftHeader();
    const apiUrl =
      environment.mulesoftOktaProfile.mulesoftapisbaseurl +
      environment.apilist.apps.myentitledapps;
    this.http.get<any[]>(apiUrl, { headers: headers }).subscribe(
      (assignedApps) => {
        this.allEntitledAppConfigsSubject.next(assignedApps);
      },
      null,
      () => this.complete()
    );
  }

  private complete() {
    if (this.isDataFetchCompleteSubject.value) return;
    this.isDataFetchCompleteSubject.next(true);
    this.isDataFetchCompleteSubject.complete();
  }
}
