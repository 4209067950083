import { Injectable } from '@angular/core';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { BehaviorSubject } from 'rxjs';
import appconfig from 'src/config/appconfig';
@Injectable({
  providedIn: 'root'
})
export class OktaAuthService {
  private oktaAuthSubject = new BehaviorSubject<OktaAuth | null>(null);
  public oktaAuth$ = this.oktaAuthSubject.asObservable();
  private oktaAuthInstance: OktaAuth;

  constructor() {
    // Initialize with default configuration
    this.oktaAuthInstance = new OktaAuth(this.getDefaultAuthConfig());
    this.oktaAuthSubject.next(this.oktaAuthInstance);
  }

  private getDefaultAuthConfig(): OktaAuthOptions {
    // Define your default configuration here
    return appconfig.oidcus;
  }

  public updateAuthConfig(newConfig: OktaAuthOptions): void {
    // Re-initialize OktaAuth with the new configuration
    this.oktaAuthInstance = new OktaAuth(newConfig);
    this.oktaAuthSubject.next(this.oktaAuthInstance);
  }

  public getOktaAuth(): OktaAuth {
    return this.oktaAuthInstance;
  }
}
