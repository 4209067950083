import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal  } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  bsModalRef!: BsModalRef;
  constructor(private http: HttpClient) { }

  // passing the lastest variable values for the Edit profile in my account User setting screen
  private isEditProfileSource = new BehaviorSubject<boolean>(false);
  isEditProfileTrue$ = this.isEditProfileSource.asObservable();

  // passing the lastest variable values for the Edit profile mobile in my account User setting screen
  private isEditPhoneSource = new BehaviorSubject<boolean>(false);
  isEditPhoneTrue$ = this.isEditPhoneSource.asObservable();

  // passing the lastest variable values for the Edit profile password reset in my account User setting screen
  private isResetPasswordSource = new BehaviorSubject<boolean>(false);
  isResetPasswordSourceTrue$ = this.isResetPasswordSource.asObservable();

  // passing the lastest variable values for the Edit phone factor in my privacy&setting screen
  private isPhoneFactorSource = new BehaviorSubject<boolean>(false);
  isPhoneFactorSourceTrue$ = this.isPhoneFactorSource.asObservable();

  // passing the lastest variable values for the Edit secret qsn factor in my privacy&setting screen
    private isSecretQuestionFactorSource = new BehaviorSubject<boolean>(false);
  isSecretQuestionFactorSourceTrue$ = this.isSecretQuestionFactorSource.asObservable();

  // passing the lastest variable values for the Edit phone factor error  in my privacy&setting screen
  private isPhoneFactorErrorSummary = new BehaviorSubject<string>('');
  isPhoneFactorErrorSummaryTrue$ = this.isPhoneFactorErrorSummary.asObservable();

  // passing the lastest variable values for the Edit secret qsn factor error in my privacy&setting screen
    private isSecurityQuestionErrorSummary = new BehaviorSubject<string>('');
  isSecurityQuestionErrorSummaryTrue$ = this.isSecurityQuestionErrorSummary.asObservable();

 

  //methode to toggle the route outlet in my account screen - expand only profile only
  updateEditProfileSourceVariable(value: boolean): void {
    this.isEditProfileSource.next(value);
    this.isEditPhoneSource.next(!value);
    this.isResetPasswordSource.next(!value);
  }

   //methode to toggle the route outlet in my account screen - expand only mobile number only
  updateEditPhoneSourceVariable(value: boolean): void {
    this.isEditPhoneSource.next(value);
    this.isEditProfileSource.next(!value);
    this.isResetPasswordSource.next(!value);
  }

   //methode to toggle the route outlet in my account screen -- expand reset passward only
  updateResetPasswordSourceVariable(value: boolean): void {
    this.isResetPasswordSource.next(value);
    this.isEditProfileSource.next(!value);
    this.isEditPhoneSource.next(!value);
  }

  //methode to toggle the route outlet in my privacy setting screen -- expand phone factor only
  updateResetSecretQuestionFactorVariable(value: boolean,errormsg?:string): void {
    this.isSecretQuestionFactorSource.next(value);    
    const errorMessage = errormsg || "";
    this.isSecurityQuestionErrorSummary.next(errorMessage);
  }

  //methode to toggle the route outlet in my privacy screen -- expand reset secret qsn section only
  updatePhoneFactoreVariable(value: boolean,errormsg?:string): void {    
    this.isPhoneFactorSource.next(value);
    const errorMessage = errormsg || "";
    this.isPhoneFactorErrorSummary.next(errorMessage);
  }

  //Collapse all the section sin the privacy and setting screen
  cancelFactorResetAll(value: boolean): void {
    this.isSecretQuestionFactorSource.next(value);
    this.isPhoneFactorSource.next(value);
  }

  //Collapse all the section sin the my account user setting screen
  cancelAll(value: boolean): void {
    this.isResetPasswordSource.next(value);
    this.isEditProfileSource.next(value);
    this.isEditPhoneSource.next(value);
  }

  getIpAddress(): Observable<any> {
    return this.http.get('https://api64.ipify.org?format=json');
  }

  getIpLocation(ip: string): Observable<any> {
    return this.http.get(`https://www.iplocate.io/api/lookup/${ip}`);
  }

  getIpAndLocation(): Observable<any> {
    return this.getIpAddress().pipe(
      switchMap((response: any) => this.getIpLocation(response.ip))
    );
  }
}
