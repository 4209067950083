<form #f="ngForm"  [formGroup]="phoneForm">
  <div *ngIf="isError" class="error-text alert alert-danger">                      
    {{isError}}     </div>
  <div class="wrapper">
    <ngx-intl-tel-input
    type="text" [style]="'padding-left:0px;'"
      [cssClass]="'input-tel1 form-group form-control input-tel col-md-4 col-xl-4'"
      [preferredCountries]="preferredCountries"
      (countryChange)="onChangeCode($event)"
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="false"
      [searchCountryFlag]="true"
      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
      [selectFirstCountry]="false" 
      [selectedCountryISO]="SearchCountryISO"     
      [maxLength]="15"
      [phoneValidation]="true"
      [separateDialCode]="separateDialCode"
      [numberFormat]="PhoneNumberFormat.National"
      name="phone"
      formControlName="phone"
      aria-describedby="button-addon2" required
    >
    </ngx-intl-tel-input>
  </div>
  <div>
    <div *ngIf="invalidNo" class="error-text">
      <span class="bi bi-exclamation-circle-fill" aria-label="Error"></span>
      {{'entervalidphone'  | translate}}
    </div>
  </div>
  <div class="text-end my-2">
    <button class="btn text-end btn-reset" (click)="f.reset()" type="reset">
      {{ 'btnreset'  | translate}}
    </button>
    <button class="btn btn-cancel mx-2 my-1" (click)="cancelBtn()">  {{ 'btncancel'  | translate}}</button>

    <button
      class="btn btn-submit mx-2 my-1"
      type="submit" [disabled]="!phoneForm.valid"
      (click)="onPhoneNumberSubmit()"
    >
    {{ 'btnsubmit'  | translate}}
    </button>
  </div>
</form>
