<ng-container *ngIf="profile | async as user">
  <ng-container>
    <div class="sidenav-body-container py-5">
      <div
        class="sidenav-body text-center"
        [class.slide-in-from-left]="sidenavService.isSlidingInFromLeft"
        [class.slide-in-from-right]="sidenavService.isSlidingInFromRight"
      >
        <div class="profile" *ngIf="sidenavService.isExpanded">
          <div class="text-center big-circle" *ngIf="user.first_name">
            <div class="text-center fs-1 pt-3">
              {{ user.first_name | slice : 0 : 1
              }}{{ user.last_name | slice : 0 : 1 }}
            </div>
          </div>
          <br />
          <div class="text-center">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div class="text-center sub-email">{{ user.email }}</div>
          <br />
          <div class="text-center">
            <div class="ring" *ngIf="loading">
              Loading.
              <span class="span"></span>
            </div>

            <!-- <div class="py-1 text-center w-75" *ngIf="isLanguageEnabled">
              <select
                (change)="changeLanguageValue($event)"
                class="form-select"
              >
                <option selected>{{ "changeyourlang" | translate }}</option>
                <option
                  [ngValue]="lang"
                  *ngFor="let i of languageOptions"
                  [value]="i.value"
                >
                  {{ i.label | translate }}
                </option>
              </select>
            </div> -->
          </div>
        </div>
        <!-- <ng-template sidenavContentArea></ng-template> -->
        <div *ngFor="let menu of menuData">

          <ng-container *ngIf="menu.show">
            <ng-container *ngIf="menu.useHref; else useRouterLink">
             <app-sidenav-link *ngIf="menu.show" [attr.href]="menu.useHref ? menu.route : null" [routerLink]="menu.useHref ? null : menu.route">
              <a [href]="menu.route" style="color: white;text-decoration: none;">  
              <div class="menucontainer">
                  <div class="input-group menu-style">
                    <div>
                      <img src="{{ menu.faImage }}" />
                      <!-- <fa-icon [icon]="menu.faImage" class="fa-1x"></fa-icon> -->
                    </div>
                    <span class="link-text">{{ menu.label | translate }} </span>
                  </div>
                </div>
              </a>
              </app-sidenav-link>
            </ng-container>
            <ng-template #useRouterLink>
              <app-sidenav-link *ngIf="menu.show" [attr.href]="menu.useHref ? menu.route : null" [routerLink]="menu.useHref ? null : menu.route">
                <div class="menucontainer">
                  <div class="input-group menu-style">
                    <div>
                      <img src="{{ menu.faImage }}" />
                      <!-- <fa-icon [icon]="menu.faImage" class="fa-1x"></fa-icon> -->
                    </div>
                    <span class="link-text">{{ menu.label | translate }} </span>
                  </div>
                </div>
              </app-sidenav-link>
            </ng-template>
          </ng-container>
        </div>
        <app-oval-button
          label="{{ 'logout' | translate }}"
          [css]="ovalLogOutButton"
          [icon]="myIcon"
          (click)="logout()"
        >
        </app-oval-button>
        <!-- <app-sidenav-link class="nav-link page-scroll"routerLink="/" fragment="targetdiv">
        <div class="menucontainer">
          <div class="input-group">
            <div>
              <fa-icon [icon]="myfaPhone" class="fa-1x"></fa-icon>
            </div>
            <span class="link-text"> Customer Support </span>
          </div>
        </div>
      </app-sidenav-link> -->
      </div>
    </div>

    <!-- Resize Handle -->
    <div
      class="resize-handle"
      [class.resizing]="resizingEvent.isResizing"
      (mousedown)="startResizing($event)"
    ></div>
    <button
    
      (click)="this.sidenavService.toggleSidenav()"
      [class.is-flipped]="sidenavService.isExpanded"
    >
      <mat-icon icon>chevron_right</mat-icon>
    </button>
  </ng-container>
</ng-container>
