<header class="alcon-color d-none d-lg-inline d-xl-inline">
    <div class="alcon-color">
      <div class="d-flex flex-wrap align-items-center justify-content-sm-between justify-content-sm-between justify-content-justify-content-lg-start">
        <a class="navbar-brand" href="#" class="px-3"><img src="assets/img/alcon-logo.png" class="logo" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div class="dropdown text-end px-2 d-none d-lg-inline d-xl-inline" >
          <ul class="nav col-12 col-lg-auto me-lg-auto justify-content-end mb-md-0">
            <li>
              <div class="nav-link px-2 justify-content-end">
              
              </div>
            </li>
            <!-- <li class="d-none d-lg-block d-xl-block d-xxl-block">
              <a href="#"><img src="assets/alcon-us.png" height="48" alt="Black and White Portrait of a Man" loading="lazy" id="dropdownBasic1" /></a>
            </li> -->
            <!-- <div class="coh-container ssa-instance_a_header_supernav d-none d-lg-block d-xl-block d-xxl-block">
  
              <div style="width: 8rem;padding-right: 4px ;;    padding-top: 1px;
              padding-bottom: 1px;     background-color: rgba(249, 246, 241, 1);">   <a style="color: rgb(0, 53, 149);" href="#" class="coh-link switcher-link coh-style-a-header-right-button ssa-instance-e914fd54d481fa73fe5f1d9909805057 coh-ce-cpt_a_header_supernav_l1_prof-ec8d116f" title="MyAlcon for Consumers" target="_self"> MyAlcon for Consumers </a> <i class="arrow right"></i></div>
            </div> -->
          </ul>
        </div>
      </div>
    </div>
  </header>
  
  <div class="pb-2 afixed-top d-sx-block d-sm-block d-sm-block d-md-none">
  <nav class="navbar navbar-expand-md lcon-color"  >
    <!-- <a class="navbar-brand" href="#"><img src="assets/alcon-us.png" height="48" alt="Black and White Portrait of a Man"
      loading="lazy" id="dropdownBasic1" /></a> -->
    <div class="w-65 mx-auto alcon-color">
      <img src="assets/img/alcon-logo.png" class="logo"/>
    </div>
   
    <div  class="collapse navbar-collapse " id="navbarsDefault" style="float: right;">
      <!-- <ul class="navbar-nav mr-auto list-group px-3">
        <li><a  routerLink="/home" class="nav-link px-2 pt-2 p-0 fw-bold">My Alcon Home <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
        </svg></a> </li>
        <li><a href="#" class="nav-link px-2  p-0 fw-bold">Cataract <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
        </svg></a> </li>
        <li><a href="#" class="nav-link px-2  p-0 fw-bold">Refractive<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
        </svg></a></li>
        <li><a href="#" class="nav-link px-2  p-0 fw-bold">Vitreoretinal<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
        </svg></a></li>
        <li><a href="#" class="nav-link px-2  p-0 fw-bold">OTC<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
        </svg></a></li>
        <li><a href="#" class="nav-link px-2  p-0 fw-bold">Prescription Drugs<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
        </svg></a></li>
        <li><a href="#" class="nav-link px-2  p-0 fw-bold">Contact Lenses<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
        </svg></a></li>
        <li><a href="#" class="nav-link px-2  p-0 fw-bold">Resources<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
        </svg></a></li>
      </ul> -->
      <div class="text-center py-3">
        <!-- <app-oval-button label="Log Out" [css]="ovalLogOutButton" [icon]="myIcon" (click)="logout()"></app-oval-button> -->
        <!-- <button class="btn btn-primary"><mat-icon icon class="v-align-middle">account_circle</mat-icon><span class="top-4">Log Out</span></button> -->
      </div>
      <div><a href="#"><img src="assets/alcon-customer.png" height="48" width="100%"
        loading="lazy" id="dropdownBasic1" /></a></div>
    </div>
  
    </nav>
  </div>
  
<div class="outerdiv">
    <div class="innerdiv">
        <div class="card rounded-left rounded-right text-center">
            <div class="card-header text-center" style="background-color: #003493;">
                <img src="assets/img/alcon.jpg" width="200px"
        loading="lazy" id="dropdownBasic1" />
              </div>
          <div class="card-body text-center">
            <h5 class="card-title alcon-text-color mx-2 py-3 fw-bold"> {{ selectedLanguagearray[0]}}</h5>
             </div>
       
        </div>
      </div>
</div>
  <footer class="footer-section">
    <div class="copyright-area">
      <div class="container">
        <div class="row">
          <div class="col-xl-11 col-lg-11 d-none d-lg-block text-left">
            <div class="footer-menu">
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://preferences.myalcon.com/privacy-notice/?hidebanner=true" target="_blank"
                    rel="noopener noreferrer">Privacy Notices</a>
                </li>
                <li class="menu-item">
                  <a href="https://preferences.myalcon.com/cookie-notice/?hidebanner=true" target="_blank"
                    rel="noopener noreferrer">Cookie Notice</a>
                </li>
                <li class="menu-item">
                  <a href="https://preferences.myalcon.com/exercise-my-rights/?hidebanner=true" target="_blank"
                    rel="noopener noreferrer">Exercise your Rights</a>
                </li>
                <li class="menu-item">
                  <a href="https://www.alcon.com/terms-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-1 col-lg-1 text-center text-lg-right">
            <div class="copyright-text">
              <div
                class="coh-column coh-ce-cpt_a_supernav_footer_profession-c29098f7 coh-visible-sm coh-col-sm-12 coh-col-xl-auto">
                <picture>
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 1600px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 1170px) and (max-width: 1599px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 1024px) and (max-width: 1169px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 768px) and (max-width: 1023px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 565px) and (max-width: 767px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 0px) and (max-width: 564px)" type="image/png"> <img
                    class="coh-image coh-ce-cpt_a_supernav_footer_profession-201608de coh-image-responsive-xl"
                    src="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    alt="Alcon See Brilliantly Logo">
                </picture>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </footer>