import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyaccountService } from 'src/app/features/myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { EventType, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
import { HttpHelperService } from 'src/app/core/services/common/http-helper.service';
import { AuditUserLogsService } from 'src/app/core/services/common/audit-user-logs.service';


@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss'],
})
export class EditAccountComponent {
  [x: string]: any;
  invalidData = false;
  @Output() cancelAcc = new EventEmitter();
  public userData: any;
  public userProfileData:any;
  public currentRoute: any;
  public emailId = '';
  public isError = '';
  public changesMade: boolean = false; 
  profileForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    title: new FormControl('', Validators.required),
  });
  public profile = this.profileService.UserProfile;
  constructor(
    public myaccountService: MyaccountService,
    public profileService: ProfileService,
    public router: Router,public audit: AuditUserLogsService ,   public communcationService: CommunicationService,public httpHelperService:HttpHelperService
  ) {}

  ngOnInit(): void {
    this.getUserFormData();
    this.getUserProfile();
    this.trackFormChanges();
  }
  getUserFormData() {
    this.userData = this.profileService.UserProfile.subscribe((data: any) => {
      this.userData = data;
      console.log(this.userData);
      this.emailId =  this.userData.email;
      this.profileForm.patchValue({
        title: this.userData.myacc_title,
        firstName: this.userData.first_name,
        lastName: this.userData.last_name,
      });
    });
  }

  trackFormChanges() {
    this.profileForm.controls['firstName'].valueChanges.subscribe(() => {
      this.changesMade = true;
    });

    this.profileForm.controls['lastName'].valueChanges.subscribe(() => {
      this.changesMade = true;
    });
    this.profileForm.controls['title'].valueChanges.subscribe(() => {
      this.changesMade = true;
    });
  }
  async getUserProfile() {
   

    try {
      const response = await this.myaccountService.getUserProfile();
      this.myaccountService.userProfileData.subscribe(
        (data: any) => {
          
          console.log('user data...',data)
          this.userProfileData = data;
        },
        (error) => {
          console.log('error....', error);
        }
      );
      //this.sendMessageToParentProfileUpdated();
    } catch (error) {
      console.error('Update failed', error);
    }
    
   
  }
  

  async onProfileSubmit() {
   
    // this.isEditMode = !this.isEditMode;
    // TODO: Use EventEmitter with form value
    if (this.profileForm.invalid) {
      this.invalidData = true;
    } else {
    let bodyParam = {
      profile: this.profileForm.value,
    };
    // this.userProfileData.profile.firstName = this.profileForm.value.firstName;
    // this.userProfileData.profile.lastName = this.profileForm.value.lastName;
    // this.userProfileData.profile.title1 = this.profileForm.value.title;

    var  data = JSON.stringify(bodyParam)
    debugger;
    const response =
    await this.httpHelperService.userProfilePatch(      
      data
    );

  // Subscribe to the response observable
  response.subscribe({
    // When the response is received
    next: (res: any) => {
      // Load the updated user profile
    //  this.profileService.loadUserProfile();

      debugger;
            
    },
    // When an error occurs
    error: (err) => {
      console.error('An error occurred:', err),
      this.isError = err.message;
      this.audit.postAuditLogs("FAILURE",this.emailId,"User Profile Pages",err);
    },
    // When the request is completed
    complete: () => {
      debugger;
      this.cancelBtn() ;
      // Load the updated user profile
      this.profileService.loadUserProfile();
      this.audit.postAuditLogs("SUCCESS",this.emailId,"User Profile Pages",response);
        return true;
    },
    
  });

   
    
  }
  }

  // Child App Script (SPA)
  // This code should be executed in the child app that is loaded inside the iframe

  // Send the height information to the parent window

  reset(event: any) {
    event.preventDefault();
    this.getUserFormData();
    this.changesMade = false;
  }
  cancelBtn() {
    this.communcationService.cancelAll(false);
  }


}
