import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyalconPreferencesComponent } from './myalcon-preferences.component';
import { EditPhoneComponent } from './editforms/edit-phone/edit-phone.component';

const routes: Routes = [
  { path: '', component: MyalconPreferencesComponent,
  children: [
    // { path: 'profile', component: EditAccountComponent, outlet: 'profile'},
    { path: 'phone', component: EditPhoneComponent, outlet: 'phone' },
  //  { path: 'phonefactor', component: EditPhoneFactorComponent, outlet: 'phonefactor'},
    // { path: 'profile', component: EditAccountComponent, outlet: 'profile',canActivate:[stepupGuard()] },
    // { path: 'phone', component: EditPhoneComponent, outlet: 'phone', canActivate:[stepupGuard()] },
    //{ path: 'resetpassword', component: ResetPasswordComponent, outlet: 'resetpassword' , canActivate:[stepupGuard()]},
  //  { path: 'seceretfactors', component:EditSecretQuestionFactorComponent , outlet: 'seceretfactors'},
    // { path: 'resetfactors', component: ResetFactorsComponent, outlet: 'resetfactors'},

  ],
 }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyalconPreferencesRoutingModule { }
