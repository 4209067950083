import { Component, OnInit } from '@angular/core';
import { array_message_translations } from './translations';
import { NavigatorService } from '../navigator.service';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})


export class InfoComponent implements OnInit  {
  translations = array_message_translations;
  selectedLanguagearray: string[] = [];
  userLanguage = '' 
  constructor( private navigatorService: NavigatorService) {
  }
  ngOnInit(): void {
    var lang:string = (navigator.language).substring(0, 2);
    //alert(lang);
    const message = array_message_translations[lang as keyof typeof array_message_translations];
    this.selectedLanguagearray = this.translations[lang.toString() as keyof typeof this.translations];
    if (!this.selectedLanguagearray) {
      this.selectedLanguagearray = this.translations["en"];; // Fallback to English message
    }
  }
}
