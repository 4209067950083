<div class="alcon-ace-color card rounded-left rounded-right" [style.height.px]="cardheight">
  <!-- <div class="p-1 col-2 text-center rounded-2 fw-bold bg-primary mt-3 mx-5 text-white">{{ card.title }}</div> -->
  <!-- <p class="p-2 mx-5 fs-4 card-text alcon-play-card-text-color">{{ card.text }}</p> -->
  <br/>
    <div class="position-absolute bottom-0 start-0">
      <div class="p-1 col-12 row mx-5 rounded-2 fw-bold  alcon-play-card-text-color ">
        <!-- <div class="col-1"> <fa-icon [icon]="myfaArrow"  class="fa-2x pt-4"></fa-icon></div> -->
        <!-- <div class="col-10 px-3 py-3"><a class=" alcon-play-card-text-color"><p><abbr title="HyperText Markup Language" class="attribute"><a>Read More</a></abbr></p></a> -->
        <!-- </div> -->
        </div>
  </div>

  <img *ngIf="card.imageUrl" class="card-img-middle p-2"  height="100px" src="{{ card.imageUrl }}" alt="Card image cap">
</div>

