// popup-modal.component.ts
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-popup-modal',
  templateUrl: './confirmation.popup-modal.component.html',
})
export class ConfrimModalComponent {
  @Output() conformed: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  // Use DomSanitizer to mark the URL as safe
  safeUrl: SafeResourceUrl;

  confirmme() {
    this.conformed.emit();
    }

  constructor(public bsModalRef: BsModalRef, private sanitizer: DomSanitizer,public activeModal: NgbActiveModal,private translate: TranslateService, ) {}

  ngOnInit() {
    // Mark the URL as safe when it changes
    //this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    window.addEventListener('beforeunload', this.handlePageRefresh);
  }

  onClose(): void {
    this.close.emit();
  }
  handlePageRefresh = (): void => {
    // Close modal or emit close event on page refresh
    this.onClose();
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    if (this.activeModal) {
      this.activeModal.close();
    }
  };

  ngOnDestroy() {
    // Remove page refresh listener to prevent memory leaks
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  }
}
