import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  ViewChild,
  inject,
  isDevMode,
} from '@angular/core';
import { SidenavService } from './services/sidenav.service';
import { SidenavContentAreaDirective } from '../myalcon-sidenav/sidenav-content-area.directive';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import {
  faBraille,
  faGear,
  faHome,
  faUser,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { environment } from '../../../../../environments/environment';
import { forkJoin, take } from 'rxjs';
import { ILanguageOption } from 'src/app/interfaces/language-option.interface';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FactorsService } from 'src/app/features/privacyand-security-settings/services/factotoenroll.service';
import { OktaService } from 'src/app/core/services/common/okta.service';

@Component({
  selector: 'app-myalcon-sidenav',
  templateUrl: './myalcon-sidenav.component.html',
  styleUrls: ['./myalcon-sidenav.component.scss'],
})
export class MyalconSidenavComponent {
  resizingEvent = {
    // whether the user is currently resizing the sidenav
    isResizing: false,

    // the x coordinate of the mouse when the user started resizing
    startingCursorX: 0,

    // the width of the sidenav when the user started resizing
    startingWidth: 0,
  };
  public profile = this.profileService.UserProfile; // this.profileService.UserProfile;
  public myIcon: any = null;
  public ovalLogOutButton = 'oval-logout-button';
  public myfaHome: any = faHome;
  public myfaUser: any = faUser;
  public myfaGear: any = faGear;
  public myfaDashboard: any = faBraille;
  public myfaPhone: IconDefinition = faPhone;
  public menuData: any;
  public lang: any = 'en_US';
  public loading: boolean = false;
  url:any;
  constructor(
    public sidenavService: SidenavService,
    private route: ActivatedRoute,
    private scroller: ViewportScroller,
    private router: Router,
    public profileService: ProfileService,
    public factorsService: FactorsService,
    public oktaService :OktaService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {
    this.profileService.UserProfile.subscribe((data: any) => {
      this.translateService.addLangs(this.availableLanguages);
      if (navigator.language.replace('-', '_'))
      {
        this.translateService.setDefaultLang(navigator.language.replace('-', '_'));
        this.lang =navigator.language.replace('-', '_');
      }
      else if (data.gen_locale) {
        this.translateService.setDefaultLang(data.gen_locale);
        this.lang =data.gen_locale;
      }
      else
      {
        this.lang ='en_US';
        this.translateService.setDefaultLang('en_US');
      }
     
      this.buildLanguageOptions();
    });
  }

  changeLanguageValue(event: any) {
    if (event.target) {
      const selectedValue = event.target.value;
      this.lang = selectedValue;
      this.changeLanguage(selectedValue);
    }
  }
  private readonly translateService = inject(TranslateService);
  private readonly translatePipe = inject(TranslatePipe);
  textTranslatedByPipe = '';
  languageOptions: ILanguageOption[] = [];
  private readonly availableLanguages = ['en_US', 'en_AU'];

  changeLanguage(language: string) {
    //    alert(language);
    this.translateService.use(language);
    this.textTranslatedByPipe = this.translatePipe.transform(
      'TRANSLATED_BY_COMPONENT'
    );
  }
  private buildLanguageOptions() {
    const EN = this.translateService.get('EN');
    const AU = this.translateService.get('AU');

    forkJoin([EN, AU]).subscribe((_response) => {
      this.languageOptions = [
        {
          value: this.availableLanguages[0],
          label: _response[0].toUpperCase(),
        },
        {
          value: this.availableLanguages[1],
          label: _response[1].toUpperCase(),
        },
      ];
      this.textTranslatedByPipe = this.translatePipe.transform(
        'TRANSLATED_BY_COMPONENT'
      );
    });
    console.log('current language,', this.translateService.currentLang)
  }

  @ViewChild(SidenavContentAreaDirective, { static: true })
  sidenavContentArea?: SidenavContentAreaDirective;
  ngOnChanges() {}

  ngAfterViewInit(): void {
    // this.customerSupportElementRef.nativeElement.focus();
  }

  isLanguageEnabled = true;
  ismyHomeEnabled = true;
  ismyAppsEnabled = true;
  ismyPrefrencesEnabled = true;
  ismyAccountEnabled = true;
  ismyConsentEnabled = true;
  ismyApprovalEnabled = true;
  ismyIdHelpEnabled = true;
  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    // this.translateService.langChanges$.pipe(take(1)).subscribe(() => {
    //   // Your action or function to be executed after 50 seconds
    //  // console.log('After 50 seconds with RxJS timer');
    // });
    this.factorsService.getmyFeatureFlags();
    this.translateService.setDefaultLang('en_US');
    this.buildLanguageOptions();
    // localStorage.setItem('loginUrl',"https://d-identity.myalcon.com/reviewRequest?ticketNumber=c85f5c3e-fa66-4c19-b903-65197559bcb6&countryCode=US?utm_source=sfmc&utm_medium=email&utm_campaign=PROD_EMAIL_ADI_HOME_DYNAMIC&utm_term=%%Review_Request_link%%&utm_id=46700299&sfmc_id=204090571")

   this.getredirecturl();
    // Set the icon for the user.
    this.myIcon = faUser;

    // Set the CSS class for the logout button.
    this.ovalLogOutButton = 'oval-logout-button';
    
    // Set the menu data.
  }
  getredirecturl(){
  
    const redirectUrl:any = localStorage.getItem('loginUrl')
      ? localStorage.getItem('loginUrl')
      : this.router.url;
    
    
      if (localStorage.getItem('loginUrl')) {
        this.url = new URL(redirectUrl);
        if (this.url.searchParams.get('ticketNumber')) {
          this.menuData = [
            {
              route: 'myapprovals',
              label: 'mnuapproval',
              faImage: '../assets/img/personal-icon.png',
              useHref:false,
              scrollapplicable: false,
              show: this.ismyApprovalEnabled,
            }
          ]
          this.router.navigate(['/myapprovals'])
        }
      }else{
        this.menuOptions();
      }
  }
menuOptions(){
  this.factorsService.getFeatureFlags().subscribe((features) => {
    if (features.length > 0) {
     
      this.ismyConsentEnabled = features.filter(
        (item: { feature: string; flag: boolean }) =>
          item.feature === 'myconsent'
      )[0].flag;
      this.isLanguageEnabled = features.filter(
        (item: { feature: string; flag: boolean }) =>
          item.feature === 'language-selection'
      )[0].flag;
      this.ismyAccountEnabled = features.filter(
        (item: { feature: string; flag: boolean }) =>
          item.feature === 'myaccount'
      )[0].flag;
      this.ismyHomeEnabled = features.filter(
        (item: { feature: string; flag: boolean }) => item.feature === 'home'
      )[0].flag;
      this.ismyPrefrencesEnabled = features.filter(
        (item: { feature: string; flag: boolean }) =>
          item.feature === 'mypreference'
      )[0].flag;
      this.ismyAppsEnabled = features.filter(
        (item: { feature: string; flag: boolean }) =>
          item.feature === 'myalconapps'
      )[0].flag;
      // this.ismyApprovalEnabled = features.filter(
      //   (item: { feature: string; flag: boolean }) =>
      //     item.feature === 'myapprovals'
      // )[0].flag;
      this.menuData = [
        {
          route: 'home',
          label: 'mnumyalconhome',
          faImage: '../assets/home.png',
          scrollapplicable: false,
          useHref:false,
          show: this.ismyHomeEnabled,
        },
        {
          useHref: true,
          route: 'https://www.myalcon.com',
          label: 'mnumyapplications',
          faImage: '../assets/apps.png',
          scrollapplicable: false,
          show: this.ismyAccountEnabled,
        },
        {
          route: 'myaccount',
          label: 'mnumpersonnelinformation',
          faImage: '../assets/img/personal-icon.png',
          useHref:false,
          scrollapplicable: false,
          show: this.ismyConsentEnabled,
        },
        {
          route: 'mypreference',
          label: 'mnumanagepreferences',
          faImage: '../assets/img/bell.png',
          useHref:false,
          scrollapplicable: false,
          show: this.ismyPrefrencesEnabled,
        },
        {
          route: 'privacyandsecuritysettings',
          label: 'mnuprivacysecurity',
          faImage: '../assets/img/alert.png',
          useHref:false,
          scrollapplicable: false,
          show: this.ismyAppsEnabled,
        },
        {
          route: 'https://www.myalcon.com/international/professional/myalconid/faqs/',
          label: 'mnumyidhelp',
          faImage: '../assets/img/help-icon.png',
          useHref:true,
          scrollapplicable: false,
          show: this.ismyIdHelpEnabled,
        }
       
      ];
    }
  });
}
  private clearOktaCookies() {
    // Replace 'oktaDomain' with your Okta domain (e.g., 'dev-123456.okta.com')
    const oktaDomain = environment.myalconaccOktaProfileUS.domain;

    // Clear Okta cookies
    document.cookie.split(';').forEach((c) => {
      const name = c.replace(/^\s+/g, '').split('=')[0];
      if (name.indexOf(oktaDomain) !== -1) {
        document.cookie =
          name +
          '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' +
          oktaDomain;
      }
    });
  }
  /**
   * Logout function.
   *
   * This function logs out the user by performing the following steps:
   *  - If the parent window is not equal to the current window, it sends a logout message to the parent window.
   *  - If the parent window is equal to the current window, it performs the following actions:
   *    - Shows an alert indicating that the user is outside of an iframe.
   *    - Calls the signOut method of the oktaAuth object to sign out the user.
   *    - Logs a success message if the signOut operation is successful.
   *    - Logs an error message if the signOut operation fails.
   *    - Calls the clearOktaCookies method to clear any Okta-related cookies.
   *    - Shows an alert indicating that the user is outside of an iframe.
   *  - If an error occurs during the logout process, it logs an error message.
   */
  async logout() {
    //  window.location.href = 'https://dev2-axon.myalcon.com/oauth2/aus5kk17b3KfCwjA91d7/v1/logout?id_token_hint=' + this.oktaAuth.getIdToken();
    this.oktaService.logoutSessions();
   
  }

  @HostBinding('class.resizing')
  get isResizing(): boolean {
    return this.resizingEvent.isResizing;
  }

  @HostBinding('class.is-expanded')
  get isExpanded() {
    return this.sidenavService.isExpanded;
  }

  startResizing(event: MouseEvent): void {
    this.resizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.sidenavService.sidenavWidth,
    };
  }

  @HostListener('window:mousemove', ['$event'])
  updateSidenavWidth(event: MouseEvent) {
    if (!this.resizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = event.clientX - this.resizingEvent.startingCursorX;

    const newWidth = this.resizingEvent.startingWidth + cursorDeltaX;

    this.sidenavService.setSidenavWidth(newWidth);
  }

  @HostListener('window:mouseup')
  stopResizing() {
    this.resizingEvent.isResizing = false;
  }
}
