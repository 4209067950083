<header class="alcon-color d-none d-lg-inline d-xl-inline">
    <div class="alcon-color">
      <div class="d-flex flex-wrap align-items-center justify-content-sm-between justify-content-sm-between justify-content-justify-content-lg-start">
        <a class="navbar-brand" href="#" class="px-3 pt-2 pb-2"><img src="assets/img/alcon-logo.png" class="logo" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div class="dropdown text-end px-2 d-none d-lg-inline d-xl-inline" >
          <ul class="nav col-12 col-lg-auto me-lg-auto justify-content-end mb-md-0">
            <li>
              <div class="nav-link px-2 justify-content-end">
              
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  
  <div class="pb-2 afixed-top d-sx-block d-sm-block d-sm-block d-md-none">
  <nav class="navbar navbar-expand-md lcon-color"  >
    <div class="w-65 mx-auto alcon-color">
      <img src="assets/img/alcon-logo.png" class="logo"/>
    </div>
   
    <div  class="collapse navbar-collapse " id="navbarsDefault" style="float: right;">
      <div class="text-center py-3">
      </div>
      <div><a href="#"><img src="assets/alcon-customer.png" height="48" width="100%"
        loading="lazy" id="dropdownBasic1" /></a></div>
    </div>
  
    </nav>
  </div>
  
<div class="outerdiv">
    <div class="innerdiv">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="card rounded-left rounded-right text-center">
            <div class="card-header text-center" style="background-color: #003493;">
                <img src="assets/img/alcon.jpg" width="200px"
        loading="lazy" id="dropdownBasic1" />
              </div>
          <div class="card-body text-center">
            <input type="text" placeholder="Enter User ID"  class="form-control mx-1 mt-5" required formControlName="Email" id="Email"/>
            <br/>
            <br/>
          </div>
          <div class="card-footer">
            <input type="submit" value="Submit" class="btn btn-primary">
          </div>
        </div>
      </form>
      </div>
      
</div>
  <footer class="footer-section">
    <div class="copyright-area">
      <div class="container">
        <div class="row">
          <div class="col-xl-11 col-lg-11 d-none d-lg-block text-left">
            <div class="footer-menu">
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://preferences.myalcon.com/privacy-notice/?hidebanner=true" target="_blank"
                    rel="noopener noreferrer">Privacy Notices</a>
                </li>
                <li class="menu-item">
                  <a href="https://preferences.myalcon.com/cookie-notice/?hidebanner=true" target="_blank"
                    rel="noopener noreferrer">Cookie Notice</a>
                </li>
                <li class="menu-item">
                  <a href="https://preferences.myalcon.com/exercise-my-rights/?hidebanner=true" target="_blank"
                    rel="noopener noreferrer">Exercise your Rights</a>
                </li>
                <li class="menu-item">
                  <a href="https://www.alcon.com/terms-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-1 col-lg-1 text-center text-lg-right">
            <div class="copyright-text">
              <div
                class="coh-column coh-ce-cpt_a_supernav_footer_profession-c29098f7 coh-visible-sm coh-col-sm-12 coh-col-xl-auto">
                <picture>
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 1600px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 1170px) and (max-width: 1599px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 1024px) and (max-width: 1169px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 768px) and (max-width: 1023px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 565px) and (max-width: 767px)" type="image/png">
                  <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    media="(min-width: 0px) and (max-width: 564px)" type="image/png"> <img
                    class="coh-image coh-ce-cpt_a_supernav_footer_profession-201608de coh-image-responsive-xl"
                    src="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                    alt="Alcon See Brilliantly Logo">
                </picture>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </footer>