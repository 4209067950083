<div class="px-3 py-5 preference-card">
  <!-- <div class="back-link">&lt; Back to [origin/close window]</div> -->
  <div class="fw-light fs-1 pt-4 text-center head-title">
    Manage Preferences
  </div>
  <div class="py-5 sub-header">Contact Preferences</div>
  <div class="alert alert-success" *ngIf="formSubmitted">
    <strong>Success - </strong> Prefreneces updated successfully!
  </div>
  <div class="row card-group py-2">
    <div class="col- col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card">
        <div class="card">
          <!-- <div class="card-body text-left">
            <h5 class="card-title alcon-text-color fw-bold">Email Frequency</h5>
            <div
              class="btn-toolbar"
              role="toolbar"
              aria-label="Toolbar with button groups"
            >
              <div
                class="btn-toolbar pt-4"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div class="btn-group" role="group" aria-label="First group">
                  <div class="px-2">
                    <app-oval-button
                      [name]="WeeklyFrequency"
                      label="Weekly"
                      class="btn-data"
                      [css]="
                        selectedEmailFrequency === 'Weekly'
                          ? selectedButton
                          : unSelectedButton
                      "
                      (click)="setEmailFrequence($event)"
                    ></app-oval-button>
                  </div>
                  <div class="ml-2">
                    <app-oval-button
                      [name]="MonthlyFrequency"
                      label="Monthly"
                      class="btn-data"
                      [css]="
                        selectedEmailFrequency === 'Monthly'
                          ? selectedButton
                          : unSelectedButton
                      "
                      (click)="setEmailFrequence($event)"
                    ></app-oval-button>
                  </div>
                </div>
              </div>
              <div
                class="btn-toolbar pt-4"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div class="btn-group" role="group" aria-label="First group">
                  <div class="px-2">
                    <app-oval-button
                      [name]="SnoozFrequency"
                      label="Snooze for 6 months"
                      class="btn-data"
                      [css]="
                        selectedEmailFrequency === 'Snooz'
                          ? selectedButton
                          : unSelectedButton
                      "
                      (click)="setEmailFrequence($event)"
                    ></app-oval-button>
                  </div>
                  <div class="ml-2">
                    <app-oval-button
                      [name]="UnsubscribeFrequency"
                      label="Unsubscribe from all"
                      class="btn-data"
                      [css]="
                        selectedEmailFrequency === 'Unsubscribe'
                          ? selectedButton
                          : unSelectedButton
                      "
                      (click)="setEmailFrequence($event)"
                    ></app-oval-button>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div> -->
          <form (ngSubmit)="onPreferenceSubmit()" novalidate>
          <div class="ms-5">
            
            <!-- <h5 class="card-title alcon-text-color pt-4 fw-bold">
              My Preferences
            </h5> -->
            <div class="row">
              <div class="col-md-6">
                <h5 class="sub-card-title alcon-text-color pt-4 fw-bold">
                  Type of content
                </h5>
                
                  <div
                    class="left checkbox-style"
                    *ngFor="let data of preferences; let i = index"
                  >
                    <input
                      class="form-check-input checkbox-lg mt-2"
                      type="checkbox"
                      [value]="data.prefrenceID"
                       
                      [innerText]="data.prefrenceID"
                      value="checkboxinfo.Value"
                      [checked]="data.visible ? 'checked' : ''"
                      (change)="onCheckboxChange($event)"
                    />
                    <label class="form-check-label fs-4 p-1">
                      {{ data.description }}
                    </label>
                  </div>

                 
              </div>

              <div class="col-md-6">
                <h5 class="sub-card-title alcon-text-color pt-4 fw-bold">
                  Communication channel
                </h5>
              
                  <div
                    class="right checkbox-style"
                    *ngFor="let data of comchannel; let i = index"
                  >
                    <input
                      class="form-check-input checkbox-lg mt-2"
                      type="checkbox"
                      [value]="data.prefrenceID"
                      [innerText]="data.prefrenceID"
                       
                      value="checkboxinfo.Value"
                      [checked]="data.visible ? 'checked' : ''"
                      (change)="onCheckboxChange($event)"
                    />
                    <label class="form-check-label fs-4 p-1">
                      {{ data.description }}
                    </label>
                  </div>
                  <div class="float-start" [formGroup]="phoneForm">
                    <p class="card-text lead phone">Phone Number</p>
                    <ngx-intl-tel-input
                    
                    type="text" [style]="'padding-left:0px;'"
                      [cssClass]="'input-tel1 form-group form-control input-tel col-md-4 col-xl-4'"
                      [preferredCountries]="preferredCountries"
                      (countryChange)="onChangeCode($event)"
                      [enableAutoCountrySelect]="false"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="CountryISO.UnitedStates"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [separateDialCode]="separateDialCode"
                      [numberFormat]="PhoneNumberFormat.National"
                      name="phone"
                      formControlName="phone"
                      (change)="onBlur()"
                      (focus)="onFocus()"
                      (blur)="onBlur()"
                      (input)="onInput($event)"
                      aria-describedby="button-addon2" required
                    >
                    </ngx-intl-tel-input>

                    <div *ngIf="invalidNo" class="error-text">
                      <span class="bi bi-exclamation-circle-fill" aria-label="Error"></span>
                      {{'entervalidphone'  | translate}}
                    </div>
                    <!-- <router-outlet name="phone" (showButton)="onChangePhone($event)" ></router-outlet> -->
                  </div>
                  <div>
                    
                  </div>
                  </div>
                  <!-- <input type="submit" value="Submit"  *ngIf="showButton"  class="btn btn-primary my-3" /> -->
                
                <!-- <div class="alert alert-success" *ngIf="formSubmitted">
              <strong>Success - </strong> Prefreneces updated successfully!
            </div> -->
              </div>
            </div>
          
          <div class="ms-5">
            <h5 class="sub-card-title alcon-text-color pt-4 fw-bold">
              Areas Of Interest
            </h5>

            <div class="row">
            
                <ul class="columnList ">
                  <li
                    *ngFor="let data of areaofIntrest; let i = index"
                    class="checkbox-style col-sm-10 col-md-6"
                  >
                    <input
                      class="form-check-input checkbox-lg columnList mt-2"
                      type="checkbox"
                      [value]="data.prefrenceID"
                      [innerText]="data.prefrenceID"
                      value="checkboxinfo.Value"
                       
                      [checked]="data.visible ? 'checked' : ''"
                      (change)="onCheckboxChange($event)"
                    />
                    <label class="form-check-label fs-4 p-1">
                      {{ data.description }}
                    </label>
                  </li>
                </ul>
                <!-- <input type="submit" value="Submit"  *ngIf="showButton"  class="btn btn-primary my-3" /> -->
              
             
            </div>
          </div>
          <div class="text-end">
          <input
            class="btn"
            type="submit"
            value="Submit"
            [disabled]="!showButton"
            class="btn btn-primary my-3"
          />
        </div>
      </form>
        </div>
      </div>
    </div>
  </div>
</div>
