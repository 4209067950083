// This card is build and used for My alcon home and the Account overview Section
//Re uasble responsive component
import { Component, EventEmitter, Inject, Input, NgModule, OnInit, Output } from '@angular/core';
import { MyHomeCard } from './card';
import OktaAuth, { IDToken } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { HttpHelperService } from '../../services/common/http-helper.service';
import { environment } from 'src/environments/environment';
import { SignOutModalComponent } from './signout.popup-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() card!: MyHomeCard;
  @Input() cardheight!: Number;
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, private httpHelperService: HttpHelperService,private modalService: NgbModal) { }
  ngOnInit(): void { }


  openPopup(functionName:string): void {
    const modalRef = this.modalService.open(SignOutModalComponent);
    modalRef.componentInstance.confirmsignput.subscribe((receivedEntry: any) => {

     // alert(functionName);
      this.callme(functionName)
      modalRef.close();

    });
    modalRef.componentInstance.close.subscribe((receivedEntry: any) => {
      modalRef.close();
    });
  }

  async callme(functionName: string) {

    const fn = (this as any)[functionName] as Function;
    if (fn && typeof fn === 'function') {
      fn(this.oktaAuth, this.httpHelperService);
    }
    window.location.href = '/';
    console.log('Function executed in parent component');
  }


  /**
   * Ends the user's sessions with the application by redirecting them to the
   * logout callback URL.
   *
   * The `id_token_hint` parameter is sent to the logout endpoint to allow the
   * Okta Authorization Server to revoke the user's session. Without this
   * parameter, the user's session may not be revoked.
   *
   */
  async endsessions(oktaAuth: any, httpHelperService: any) {
    // const idToken = oktaAuth.getIdToken();
    const idToken = (await oktaAuth.tokenManager.get(
      'idToken'
    )) as IDToken;
    (
      await httpHelperService.univesalLogoutSessions(
        idToken.claims.sub
      )
    ).subscribe({
      next: (res: any) => {
      },
      error: (error: any) => {
        localStorage.removeItem('okta-cache-storage');
        localStorage.removeItem('okta-token-storage');
        oktaAuth.tokenManager.clear();
       
      },
    });
  }
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}



