import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { SidenavService } from './core/layout/components/myalcon-sidenav/services/sidenav.service';
import { ProfileService } from './core/services/common/profile.service';
import { forkJoin, Observable } from 'rxjs';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { OKTA_AUTH, OktaAuthModule, OktaAuthStateService, OktaConfig } from '@okta/okta-angular';
import { ViewportScroller } from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ILanguageOption } from './interfaces/language-option.interface';
import { AppsService } from './features/myalcon-apps/services/apps.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { OktaAuthService } from './core/services/common/okta.authservice';
import appconfig from 'src/config/appconfig';
import { Token } from '@angular/compiler';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  [x: string]: any;
  browserLang: any;
  lang: any;
  public isAuthenticated$!: Observable<boolean>;
  private readonly availableLanguages = ['en_US', 'en_AU'];
  constructor(
    public sidenavService: SidenavService, //Side Nav service used to buuld the side nav
    private cdr: ChangeDetectorRef,
    public profileService: ProfileService, // Created and used for getiing the profile related actions
    private scroller: ViewportScroller,
    private appsService: AppsService,
    private router: Router,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private authStateService: OktaAuthStateService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private oktaAuthService: OktaAuthService
  ) {
  }

  ngOnInit() {
    setTheme('bs5');
    //alert(4)
    this.oktaAuth.authStateManager.subscribe(async (authState) => {
      // Logic based on authState is done here.
      try {
        if (authState.isAuthenticated) {
          const userProfile = await this.oktaAuth.getUser();
          // const accessToken = this.oktaAuth.getAccessToken();
          // Extract email or other claims
          var dr_region: string = 'americas';
          dr_region = ((await this.oktaAuth.getUser())['dr_region'] == '' || (await this.oktaAuth.getUser())['dr_region'] == undefined ) ? "americas" : (await this.oktaAuth.getUser())['dr_region']?.toString(); // Assuming `userProfile` has an `email` property
          let region = window.sessionStorage.getItem('region');
          //const tokens = await this.oktaAuth.token.getWithoutPrompt();
          // if(dr_region!='americas')
          // {
          // window.sessionStorage.setItem('americas-tokens',JSON.stringify(tokens))
          // }
          if (dr_region != '') {
            if (
              window.sessionStorage.getItem('region') == 'americas' &&
              window.sessionStorage.getItem('region') != dr_region
            ) {
              window.sessionStorage.setItem('region', dr_region);
              let authConfig = appconfig.oidcus;
              if (dr_region == 'apac') {
                authConfig = appconfig.oidcap;
                console.log(
                  'latest region configs' + JSON.stringify(appconfig.oidcap)
                );
              } else if (dr_region == 'emea') {
                authConfig = appconfig.oidceu;
                console.log(
                  'latest region configs' + JSON.stringify(appconfig.oidceu)
                );
              } else if (dr_region == 'americas') {
                authConfig = appconfig.oidcus;
                console.log(
                  'latest region configs' + JSON.stringify(appconfig.oidcus)
                );
              }
              await this.oktaAuth.signOut();
              await this.oktaAuth.clearStorage();
              await this.oktaAuth.session.close();
              await this.oktaAuth.closeSession();
              await this.oktaAuth.tokenManager.clear();
              const oktaAuth = new OktaAuth(authConfig);
              const moduleConfig: OktaConfig = { oktaAuth };
              OktaAuthModule.forRoot(moduleConfig),
              window.location.href = `${window.location.origin}/region/${dr_region}`;
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    });
  }
  /*On init inject the side nav component.*/
  ngAfterViewInit(): void {}

  /*On Destroy clear promises on the side nav component.*/
  ngOnDestroy(): void {
    this.sidenavService.pop();
  }
  async login() {
    await this.oktaAuth.signInWithRedirect();
  }
}
