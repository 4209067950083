import { Component } from '@angular/core';
import { FactorsService } from 'src/app/features/privacyand-security-settings/services/factotoenroll.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
closeNav:any = false;
openLang:any;
  constructor(
    public factorsService: FactorsService,
  ) {
  }
  ismyHeaderFooterEnabled = false;
  ngOnInit(): void {
    this.factorsService.getFeatureFlags().subscribe((features) => {
      if (features.length > 0) {
        this.ismyHeaderFooterEnabled = features.filter((item: { feature: string; flag: boolean; }) => item.feature === 'enable_header_n_footer')[0].flag;
      }
    });
  }
  onMenuClose(event:any){
 
    this.closeNav = true;
    
  }
  onHamburgerMenu(e:any){
    this.closeNav = false;
  }
  onLangSelect(e:any){
 
    this.openLang = e;
  }
}


