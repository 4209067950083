<div class="pt-5">
  <nav class="navbar fixed-bottom alcon-color">
    <div class="menucontainer text-center">
      <div class="d-flex">
        <div class="text-white">
          <button
            role="button"
            class="btn alcon-color text-white"
            routerLink="/home"
          >
            <fa-icon [icon]="myfaHome" class="fa-1x"></fa-icon>
            <br />
            <span class="font-smaller">MyAlcon Home</span>
          </button>
        </div>
        <div class="text-white">
          <button
            role="button"
            class="btn alcon-color text-white"
            routerLink="/myaccount"
          >
            <fa-icon [icon]="myfaUser" class="fa-1x"></fa-icon>
            <br />
            <span class="font-smaller"> My Account </span>
          </button>
        </div>
        <div class="text-white">
          <button
            role="button"
            class="btn alcon-color text-white"
            routerLink="/mypreference"
          >
            <fa-icon [icon]="myfaGear" class="fa-1x"></fa-icon>
            <br />
            <span class="font-smaller"> My Preferences </span>
          </button>
        </div>
        <div class="text-white">
          <button
            role="button"
            class="btn alcon-color text-white"
            routerLink="/myalconapps"
          >
            <fa-icon [icon]="myfaDashboard" class="fa-1x"></fa-icon>
            <br />
            <span class="font-smaller"> MyAlcon Apps </span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</div>
