import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import appconfig from 'src/config/appconfig';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  constructor(
    private http: HttpClient,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {}
  public queryParams = new Map<string, string>();
  /// This mehtod build the header
  async addMuleSoftHeader() {
    const accessToken = this.oktaAuth.getAccessToken();
    const url =
      environment.mulesoftOktaProfile.jwksUrl +
      accessToken +
      '?clientId=' +
      environment.mulesoftOktaProfile.multitenenetclientId +
      '&clientSecret=' +
      environment.mulesoftOktaProfile.multitenenetclientSecret;
    const token = btoa(url);
    const headers = new HttpHeaders({
      'Content-Type': `application/json`,
      token: token,
      Authorization: `Bearer ${accessToken}`,
      client_id: `${environment.mulesoftOktaProfile.multitenenetclientId}`,
      client_secret: `${environment.mulesoftOktaProfile.multitenenetclientSecret}`,
    });
    return headers;
  }

  replaceKeyValuePairsInURL(
    url: string,
    replacements: Map<string, string>
  ): string {
    // Split the URL into its components
    const [path, query] = url.split('?');

    // Parse the query string into a map of key-value pairs

    if (query) {
      const queryPairs = query.split('&');
      for (const queryPair of queryPairs) {
        const [queryKey, queryValue] = queryPair.split('=');
        this.queryParams.set(queryKey, queryValue);
      }
    }
    let updatedURL = url;
    // Apply the replacements to the query string
    for (const [key, value] of replacements) {
      //this.queryParams.set(key, value);
      updatedURL = updatedURL.replace(key, value);
    }
    // console.log(updatedURL);
    return updatedURL;
  }
  /// This mehtod build the header
  async addHeader() {
    let authConfig;
    if (window.sessionStorage.getItem("region") == "apac") {
      authConfig = appconfig.oidcap;
    }
    else if (window.sessionStorage.getItem("region") == "emea") {
      authConfig = appconfig.oidceu;
    }  
    else {
      authConfig = appconfig.oidcus;
    }
    const oktaAuth = new OktaAuth(authConfig);
    const accessToken = oktaAuth.getAccessToken();
    const getheaders = new HttpHeaders({
      'Content-Type': `application/json`,
      'Access-Control-Allow-Origin': `*`, 
      Authorization: `Bearer ${accessToken}`,
      Accept: `application/json; okta-version=1.0.0`,
    });
    return getheaders;
  }

  async adddeleteHeader() {
    let authConfig;
    if (window.sessionStorage.getItem("region") == "apac") {
      authConfig = appconfig.oidcap;
    }
    else if (window.sessionStorage.getItem("region") == "emea") {
      authConfig = appconfig.oidceu;
    }  
    else {
      authConfig = appconfig.oidcus;
    }
    const oktaAuth = new OktaAuth(authConfig);
    const accessToken = oktaAuth.getAccessToken();
    const getheaders = new HttpHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
    return getheaders;
  }

  async addSSWSHeader() {
    const universallogouttemptoken =  environment.myalconaccOktaProfileUS.universallogouttemptoken;
    const getheaders = new HttpHeaders({
      'Content-Type': `application/json`,
      'Access-Control-Allow-Origin': `*`,
      Authorization: `SSWS ${universallogouttemptoken}`,
      Accept: `application/json; okta-version=1.0.0`,
    });
    return getheaders;
  }

  // method used to get call with the mule soft profile in header
  async getProtectedResource(url: string) {
    const headers = await this.addMuleSoftHeader();
    const response = this.http.get(
      environment.mulesoftOktaProfile.mulesoftapisbaseurl + url,
      { headers }
    );
    return response;
  }

  // method used to get call with the mule soft profile in header and paramerter
  async getProtectedResourceWithBodyParams(url: string, bodyParams: any) {
    const accessToken = await this.oktaAuth.getAccessToken();
    const headers = await this.addMuleSoftHeader();
    const response = await this.http.get(
      environment.mulesoftOktaProfile.mulesoftapisbaseurl + url,
      {
        headers,
        params: bodyParams,
      }
    );
    return response;
  }

  // method used to post call with the mule soft profile in header and paramerter
  async postProtectedResourceWithBodyParams(url: string, bodyParams: any) {
    debugger;
    const accessToken = await this.oktaAuth.getAccessToken();
    const headers = await this.addMuleSoftHeader();
    const response = await this.http.post(
      environment.mulesoftOktaProfile.mulesoftapisbaseurl + url,
      bodyParams,
      {
        headers,
      }
    );
    return response;

  }
  async putProtectedResourceWithBodyParams(url: string, bodyParams: any) {
    const accessToken = await this.oktaAuth.getAccessToken();
    const headers = await this.addMuleSoftHeader();
    const response = await this.http.put(
      environment.mulesoftOktaProfile.mulesoftapisbaseurl + url,
      bodyParams,
      {
        headers,
      }
    );
    return response;

  }
  // method used to post call with the mule soft profile in header and paramerter
  async patchProtectedResourceWithBodyParams(url: string, bodyParams: any) {
    const accessToken = await this.oktaAuth.getAccessToken();
    const headers = await this.addMuleSoftHeader();
    const response = await this.http.patch(
      environment.mulesoftOktaProfile.mulesoftapisbaseurl + url,
      bodyParams,
      {
        headers,
      }
    );
    return response;
  }

  // method used to post call with the mule soft profile in header and paramerter
  async putOktaCallWithBodyParams(url: string, bodyParams: any) {
    const headers = await this.addHeader();

    const response = await this.http.put(url, bodyParams, {
      headers,
      observe: 'response',
      responseType: 'text',
    });

    return response;
  }

  async deleteOktaCallWithBodyParams(url: string) {
    const headers = await this.adddeleteHeader();
    const response = await this.http.delete(url, {
      headers,
    });
    return response;
  }

  async univesalLogoutSessions(userId: string) {
  //  debugger;
    const url = `${environment.myalconaccOktaProfileUS.domain}/api/v1/users/${userId.trim()}/sessions?oauthTokens=true`;
    const headers = await this.addSSWSHeader();
    const response = await this.http.delete(url, { headers });
    return response;
  }

  async univesalMuleSoftLogoutSessions(userId: string) {
     const region =  window.sessionStorage.getItem("region") ? "americas" : window.sessionStorage.getItem("region");
     var mulesofturl = environment.mulesoftOktaProfile.mulesoftgenericapisbaseurl.replace('{region}' ,"");
      const url = `${mulesofturl}/okta/users/${userId.trim()}/sessions`;
    
      const headers = await this.adddeleteHeader();
      const response = await this.http.delete(url, { headers });  
      return response;
  }
  async getUserProfileData( ) {
    const accessToken = await this.oktaAuth.getAccessToken();
    const headers = await this.addMuleSoftHeader();
    // var userprofileoktaurl = environment.oktaGenric.factors.factorAPIendpoint;
   
    const oktaurl = environment.oktaGenric.factors.factorAPIendpoint;
    const url = `${oktaurl}`;
    const response = await this.http.get(
       url,    
      {
        headers,
      }
    );
    return response;
  }
  async userProfilePatch( bodyParams: any) {
    const accessToken = await this.oktaAuth.getAccessToken();
    const headers = await this.addMuleSoftHeader();
    // var userprofileoktaurl = environment.oktaGenric.factors.factorAPIendpoint;
   
    const oktaurl = environment.oktaGenric.factors.factorAPIendpoint;
    const url = `${oktaurl}`;
    const response = await this.http.post(
      url,
      bodyParams,
      {
        headers,
      }
    );
    return response;
  }
}


