import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpHelperService } from '../../../core/services/common/http-helper.service';
import { environment } from 'src/environments/environment';
import OktaAuth, { IDToken, isObject } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { jwtDecode } from 'jwt-decode';
import { DynamoDBValue } from 'src/app/models/dynamodb.types';
@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  public MyPrefreeneces = new BehaviorSubject<any>({});
  public UserPrefreeneces = new BehaviorSubject<any>({});
  public appid: any = '';
  public idToken: any = '';
  public clientId: any = '';
  public url: any = '';
  public urlwithparams: string = '';
  public bodyParams: any;
  constructor(
    private httpHelperService: HttpHelperService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {}
  public async getMyPrefrerencesApps() {
    this.idToken = this.oktaAuth.getIdToken();
    const decodedIdToken = jwtDecode(this.idToken);
    this.clientId = decodedIdToken.aud;

   
    this.url =environment.apilist.userpreferences.getmypreferences;
    (
      await this.httpHelperService.getProtectedResource(this.url)
    ).subscribe({
      next: (res: any) => {
    
        this.MyPrefreeneces.next(res);

      },
      error: (err) => console.error('An error occurred :', err),
      complete: () => {},
    });
  }
  public async getUserPrefrerences() {
    this.idToken = this.oktaAuth.getIdToken();
    const decodedIdToken = jwtDecode(this.idToken);
    this.clientId = decodedIdToken.sub;

    const replacements = new Map<string, string>([
      ['user_id', this.clientId],
    ]);
    this.url = this.httpHelperService.replaceKeyValuePairsInURL(
      environment.apilist.userpreferences.getusermypreferences,
      replacements
    );
    (
      await this.httpHelperService.getProtectedResource(this.url)
    ).subscribe({
      next: (res: any) => {
    
    
        this.UserPrefreeneces.next(res);
      },
      error: (err) => console.error('An error occurred :', err),
      complete: () => {},
    });
  }
  public  isObject(value: any): value is Record<string, any> {
    return typeof value === 'object' && value !== null;
  }
   
  // Define the function to convert a DynamoDB object to a JSON object
public convertDynamoDBToJson(dynamoDBObj: any): any {
  const jsonObject: any = {};

  // Iterate through each key in the DynamoDB object
  for (const key in dynamoDBObj) {
    if (dynamoDBObj.hasOwnProperty(key)) {
        const attribute = dynamoDBObj[key];

        // Extract the value based on the DynamoDB type (e.g., S, N, M)
        if ('S' in attribute) {
            jsonObject[key] = attribute.S;
        } else if ('N' in attribute) {
            jsonObject[key] = Number(attribute.N);
        } else if ('B' in attribute) {
            jsonObject[key] = attribute.B;
        } else if ('BOOL' in attribute) {
            jsonObject[key] = attribute.BOOL;
        } else if ('L' in attribute) {
            jsonObject[key] = attribute.L.map(this.convertDynamoDBToJson); // Recursively handle list elements
        } else if ('M' in attribute) {
            jsonObject[key] = this.convertDynamoDBToJson(attribute.M); // Recursively handle map elements
        } else if ('SS' in attribute) {
            jsonObject[key] = attribute.SS;
        } else if ('NS' in attribute) {
            jsonObject[key] = attribute.NS.map(Number);
        } else if ('BS' in attribute) {
            jsonObject[key] = attribute.BS;
        }
    }
}

  return jsonObject;
}



  //  public ddbToJson(data: DynamoDBValue | object): any {
  //   if (_isObject: any(data)) return convertDDB({ M: data });
  //   return data;
  // }
  // public convertDDB(ddbData: DynamoDBValue): any {
  //   if (isObject(ddbData)) {
  //     if ('S' in ddbData) return ddbData.S;
  //     if ('N' in ddbData) return parseFloat(ddbData.N);
  //     if ('BOOL' in ddbData) return ddbData.BOOL;
  //     if ('NULL' in ddbData) return null;
  //     if ('M' in ddbData) {
  //       const result: Record<string, any> = {};
  //       for (const key in ddbData.M) {
  //         result[key] = convertDDB(ddbData.M[key]);
  //       }
  //       return result;
  //     }
  //     if ('L' in ddbData) return ddbData.L.map(convertDDB);
  //     if ('SS' in ddbData) return ddbData.SS;
  //     if ('NN' in ddbData) return ddbData.NN;
  //     if ('BB' in ddbData) return ddbData.BB;
  //     if ('NS' in ddbData) return ddbData.NS;
  //     if ('BS' in ddbData) return ddbData.BS;
  //   }
  //   return data;
  // }
  public jsonToDynamoDB(jsonObj: any): any {
    const dynamoDBItem: any = {};
    
    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        dynamoDBItem[key] = this.mapToDynamoDBType(jsonObj[key]);
      }
    }
    return dynamoDBItem;
  }

  // Helper method to map JSON values to DynamoDB types
  public mapToDynamoDBType(value: any): any {
    if (typeof value === 'string') {
      return { S: value };
    } else if (typeof value === 'number') {
      return { N: value.toString() };
    } else if (typeof value === 'boolean') {
      return { BOOL: value };
    } else if (Array.isArray(value)) {
      if (typeof value[0] === 'string') {
        return { SS: value };
      } else if (typeof value[0] === 'number') {
        return { NS: value.map(num => num.toString()) };
      }
    } else if (typeof value === 'object') {
      if (value !== null) {
        const mapValue: any = {};
        for (const key in value) {
          if (value.hasOwnProperty(key)) {
            mapValue[key] = this.mapToDynamoDBType(value[key]);
          }
        }
        return { M: mapValue };
      }
    }

    return { NULL: true };  // If the value doesn't match any known type
  }
  public dynamoDBToJson(dynamoDBItem: any): any {
    const jsonObj: any = {};
    if(dynamoDBItem!== undefined){
      for (const key in dynamoDBItem) {
        if (dynamoDBItem.hasOwnProperty(key)) {
          jsonObj[key] = this.mapFromDynamoDBType(dynamoDBItem[key]);
        }
      }
    }
    
    return jsonObj;
  }
  
  // //Help To convert in json to array
  // public jsontoArray(dynamoDBItem: any):any{
  //   const keys = Object.keys(dynamoDBItem);
  //   console.log(keys,typeof(keys));
  // }


  // // Helper method to map DynamoDB types to JSON values
  public mapFromDynamoDBType(value: any): any {
    if (value.S !== undefined||value.s !== undefined) {
      return value.S !== undefined ? value.S : value.s;
    } else if (value.N !== undefined || value.n !== undefined) {
      return parseFloat(value.N !== undefined ? value.N : value.n);
    } else if (value.BOOL !== undefined || value.bool !== undefined) {
      return value.BOOL !== undefined ? value.BOOL:value.bool;
    } else if (value.SS !== undefined || value.ss !== undefined) {
      return value.SS !== undefined ? value.SS:value.ss;
    } else if (value.NS !== undefined || value.ns !== undefined) {
      return value.NS !== undefined ? value.NS.map((num: string) => parseFloat(num)):value.ns.map((num: string) => parseFloat(num));
    } else if (value.L !== undefined || value.l !== undefined) {
      return  value.M !== undefined ? value.L.map((item: any) => this.mapFromDynamoDBType(item)):value.l.map((item: any) => this.mapFromDynamoDBType(item));
    } else if (value.M !== undefined || value.m !== undefined) {
      const keys = value.M !== undefined ? value.M : value.m;
      const mapValue: any = {};
      for (const key in keys ) {
        if (value.M.hasOwnProperty(key)) {
          mapValue[key] = this.mapFromDynamoDBType(keys[key]);
        }
      }
      return mapValue;
    } else if (value.NULL !== undefined || value.null !== undefined) {
      return null;
    }

    return null; // Default case if type is not recognized
  }
  public async updateMyPrefrerencesApps(bodyParams: string) {
    // const replacements = new Map<string, string>([
    //   ['user_id', this.clientId],
    // ]);
    // this.url = this.httpHelperService.replaceKeyValuePairsInURL(
    //   environment.apilist.userpreferences.updatemypreferences,
    //   replacements
    // );
    this.url=environment.apilist.userpreferences.updatemypreferences;
    (
      await this.httpHelperService.putProtectedResourceWithBodyParams(
        this.url,
        bodyParams
      )
    ).subscribe({
      next: (_res: any) => {
        //this.getMyPrefrerencesApps();
      },
    });
  }
  public async createMyPrefrerencesApps(bodyParams: string) {
    // const replacements = new Map<string, string>([
    //   ['user_id', this.clientId],
    // ]);
    // this.url = this.httpHelperService.replaceKeyValuePairsInURL(
    //   environment.apilist.userpreferences.updatemypreferences,
    //   replacements
    // );
    this.url=environment.apilist.userpreferences.createmypreferences;
    (
      await this.httpHelperService.postProtectedResourceWithBodyParams(
        this.url,
        bodyParams
      )
    ).subscribe({
      next: (_res: any) => {
       // this.getMyPrefrerencesApps();
      },
    });
  }
  
}


