
<div class="container-fluid"  >
  <div class="row fixed-top" >   
 
    <app-topnav  [openLang]="openLang" ></app-topnav>
    
  
  </div>
    <div class="row">  
      <!-- <app-mobile-nav  *ngIf="isMobile" style="z-index: 99;"  (langChange)="onLangSelect($event)" (valueChange)='onMenuClose($event)'
    ></app-mobile-nav>   -->
     
    <app-myalcon-sidenav *ngIf="!isMobile && !isIpadMiniPortrait" style="z-index: 99;" (langChange)="onLangSelect($event)">
    </app-myalcon-sidenav>
     
      <div class="col-sm" *ngIf="showContent" >
       
        <div class="content"  [ngClass]="{'tabContentClass': isIpadMiniPortrait}">
        
          <router-outlet></router-outlet>
        </div>
      
        <div>

        </div>
      </div>

    </div>
    <!-- <footer class="footer-section" *ngIf="ismyHeaderFooterEnabled">
      <div class="copyright-area">
        <div class="container">
          <div class="row">
            <div class="col-xl-11 col-lg-11 d-none d-lg-block text-left">
              <div class="footer-menu">
                <ul class="menu">
                  <li class="menu-item">
                    <a href="https://preferences.myalcon.com/privacy-notice/?hidebanner=true" target="_blank"
                      rel="noopener noreferrer">Privacy Notices</a>
                  </li>
                  <li class="menu-item">
                    <a href="https://preferences.myalcon.com/cookie-notice/?hidebanner=true" target="_blank"
                      rel="noopener noreferrer">Cookie Notice</a>
                  </li>
                  <li class="menu-item">
                    <a href="https://preferences.myalcon.com/exercise-my-rights/?hidebanner=true" target="_blank"
                      rel="noopener noreferrer">Exercise your Rights</a>
                  </li>
                  <li class="menu-item">
                    <a href="https://www.alcon.com/terms-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-1 col-lg-1 text-center text-lg-right">
              <div class="copyright-text">
                <div
                  class="coh-column coh-ce-cpt_a_supernav_footer_profession-c29098f7 coh-visible-sm coh-col-sm-12 coh-col-xl-auto">
                  <picture>
                    <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                      media="(min-width: 1600px)" type="image/png">
                    <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                      media="(min-width: 1170px) and (max-width: 1599px)" type="image/png">
                    <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                      media="(min-width: 1024px) and (max-width: 1169px)" type="image/png">
                    <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                      media="(min-width: 768px) and (max-width: 1023px)" type="image/png">
                    <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                      media="(min-width: 565px) and (max-width: 767px)" type="image/png">
                    <source srcset="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                      media="(min-width: 0px) and (max-width: 564px)" type="image/png"> <img
                      class="coh-image coh-ce-cpt_a_supernav_footer_profession-201608de coh-image-responsive-xl"
                      src="https://www.myalcon.com/sites/g/files/rbvwei2521/files/alcon-logo-see2.png"
                      alt="Alcon See Brilliantly Logo">
                  </picture>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </footer> -->
  </div>