<div class="ring" *ngIf="loading">
  Loading.
  <span class="span"></span>
</div>

<ng-container>
  <div class="px-3 py-5">
    <div class="fw-light fs-1 pt-4 col-xl-12">
      <div class="back-link">&lt;<a href="https://www.myalcon.com/">Back to [origin/close window]</a></div>
      <p class="head-title">{{ "home_title" | translate }}</p>
    </div>
 
    <div class="row card-group py-2">
      <div
        class="col- col-sm-12 col-md-6 col-lg-6 col-xl-6 px-2 py-2"
        *ngFor="let card of cardData"
      >
        <app-card [card]="card" *ngIf="card.isShow"></app-card>
      </div>
    </div>
    <div class="row py-2 id-help text-center mx-3">
    
      <div class="footer-card-titile">MyAlcon ID Help</div>
        <div class="footer-subtext">Having trouble with account, login or password? </div>
        <div class="py-2">
          <button type="button" class="btn btn-lg btn-sm fw-bold oval-logout-button rounded-5">
            Read FAQs
          </button>
      </div>
    </div>
    <div class="row py-2 text-center manage-footer mx-3 ">
      <img class="footer-img p-2" src="../../../assets/img/myalconhome-img.png">
      <div class="footer-card-titile">MyAlcon Home</div>
      <div>Where all your dream come true</div>
      <div class="py-3">
        <button
          type="button"
          class="btn btn-lg btn-sm fw-bold oval-logout-button rounded-5"
        >
          Visit MyAlcon Home
        </button>
      </div>
    </div>
  
  </div>
</ng-container>
