import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyalconAccountComponent } from '../../myalcon-account/myalcon-account.component'; 
import { EditAccountComponent } from '../../myalcon-account/editforms/edit-account/edit-account.component'; 
import { EditPhoneComponent } from '../../myalcon-account/editforms/edit-phone/edit-phone.component'; 
import { ResetPasswordComponent } from '../../myalcon-account/editforms/reset-password/reset-password.component';
import { stepupGuard } from 'src/app/core/stepup/stepup.guard';
import { PrivacyandsecuritySettingsComponent } from './privacyandsecurity-settings.component';
import { EditPhoneFactorComponent } from '../editforms/edit-phone-factor/edit-phone-factor.component';
import { EditSecretQuestionFactorComponent } from '../editforms/edit-secret-question-factor/edit-secret-question-factor.component';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: PrivacyandsecuritySettingsComponent,
    children: [
      // { path: "privacyandsecuritysettings", component: EditSecretQuestionFactorComponent},
      // { path: 'phone', component: EditPhoneComponent, outlet: 'phone' },
      { path: 'phonefactor', component: EditPhoneFactorComponent, outlet: 'phonefactor'},
      // // { path: 'profile', component: EditAccountComponent, outlet: 'profile',canActivate:[stepupGuard()] },
      // { path: 'phone', component: EditPhoneComponent, outlet: 'phone', canActivate:[stepupGuard()] },
      // { path: 'resetpassword', component: ResetPasswordComponent, outlet: 'resetpassword' , canActivate:[stepupGuard()]},
      { path: 'seceretfactors', component:EditSecretQuestionFactorComponent , outlet: 'seceretfactors'},
      // { path: 'resetfactors', component: ResetFactorsComponent, outlet: 'resetfactors'},
      // { path: 'profile', component: EditAccountComponent, outlet: 'profile'}
      ],

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivacyandsecuritySettingsRoutingModule { }
