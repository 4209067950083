import { Inject, Injectable } from '@angular/core';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
// import { ApiService } from './../api.service';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject } from 'rxjs';
import { HttpHelperService } from './http-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditUserLogsService {
  public UserProfile = new BehaviorSubject<any>({});
  public resetPasswordResponse = new BehaviorSubject<any>({});
  private email$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // claims: Claim[] = [];
  public records: any = null;
  url: string;
  constructor( @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,private httpHelperService: HttpHelperService,) { }
  async postAuditLogs(status:string,action:string,actiondoneby:string,paramdata:any){
    let  uuid = uuidv4();
    let data = {
         "audit_id" : uuid,
         "action" : actiondoneby,
         "user_id" : "IDENTITY PORTAL_"+action,
         "log_details" : paramdata,
         "status": status,
         "time_stamp" : new Date().toISOString()
     }
    this.url =environment.apilist.userapis.createUserAuditLogs;
    (
      await this.httpHelperService.postProtectedResourceWithBodyParams(
       this.url,
        data
      )
    ).subscribe({
      next: (_res: any) => {
       // this.getMyPrefrerencesApps();
       console.log(_res);
      },
    });

  }
  
  
}
