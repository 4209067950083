import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from './http-helper.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root',
})
export class OktaService {
  baseUrl = environment.mulesoftOktaProfile.mulesoftapisbaseurl;
  logouturl = this.baseUrl + environment.apilist.userapis.universallogout;
  constructor(
    private http: HttpClient,
    private httpHelper: HttpHelperService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {}
  public univesalLogoutSessions() {
    let url;
    if (window.sessionStorage.getItem('region') == 'apac') {
      url = environment.myalconaccOktaProfileAP.postLogoutRedirectUri;
    } else if (window.sessionStorage.getItem('region') == 'emea')
      url = environment.myalconaccOktaProfileEU.postLogoutRedirectUri;
    else {
      url = environment.myalconaccOktaProfileUS.postLogoutRedirectUri;
    }
    var postLogoutRedirectUrimyalconaccOktaProfileAP =
      environment.myalconaccOktaProfileAP.postLogoutRedirectUri;
    var postLogoutRedirectUrimyalconaccOktaProfileEU =
      environment.myalconaccOktaProfileEU.postLogoutRedirectUri;
    var postLogoutRedirectUrimyalconaccOktaProfileUS =
      environment.myalconaccOktaProfileUS.postLogoutRedirectUri;
    
    this.oktaAuth.signOut({ postLogoutRedirectUri: url });
    this.oktaAuth.signOut({
      postLogoutRedirectUri: postLogoutRedirectUrimyalconaccOktaProfileUS,
    });
    this.httpHelper.univesalMuleSoftLogoutSessions(this.logouturl);
  }

  async logoutSessions() {
  //   const tokens = await this.oktaAuth.token.getWithoutPrompt();
  //   await this.oktaAuth.tokenManager.getTokens()
  // .then(tokens => {
  //   // Handle the resolved tokens here
  //   console.log('Tokens:', tokens);
  // })
  // .catch(error => {
  //   // Handle any errors that occurred during token retrieval
  //   console.error('Error retrieving tokens:', error);
  // });
  //   // closes session, alternative to signOut()
  //   await this.oktaAuth.session.close();
  
    // clears okta-token-storage from localStorage. Although documentation for
    // closeSession() says tokens are cleared in TokenManager, the actual browser
    // localstorage maintains the idToken. This clear() removes it
    
    this.signOutWithFallback()
  }
  async attemptSignOut(url: string) {
    try {
      await this.oktaAuth.signOut({
        postLogoutRedirectUri: url
      });
      console.log(`Attempted sign-out with URL: ${url} - Success`);
    } catch (error) {
      console.error(`Attempted sign-out with URL: ${url} - Failed. Error: ${error}`);
    }
  }
  
  async  signOutWithFallback() {
   const region  =window.sessionStorage.getItem("region");
   var logoutURl = environment.myalconaccOktaProfileUS.postLogoutRedirectUri;
    if (region == "apac") {
      logoutURl =environment.myalconaccOktaProfileAP.postLogoutRedirectUri;
    }
    else if (region == "emea") {
      logoutURl =environment.myalconaccOktaProfileEU.postLogoutRedirectUri;
    }  
    else if (region == "americas") {
      logoutURl =environment.myalconaccOktaProfileUS.postLogoutRedirectUri;
    }
    window.sessionStorage.removeItem('region');
    await this.attemptSignOut(logoutURl);
  }
  
}
