import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { StepupCallbackComponent } from './stepup-callback.component';

const routes: Routes = [
  {
    path: 'stepup/callback',
    component: StepupCallbackComponent,
    canActivate: [OktaAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class StepupRoutingModule {}
