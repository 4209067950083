import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StepupService } from './stepup.service';

@Component({
  selector: 'app-stepup-callback',
  template: '',
})
export class StepupCallbackComponent {

  constructor(private router: Router, private stepupService: StepupService) {
    try {
      const stepupInfo = JSON.parse(
        localStorage.getItem('stepup-request') || ''
      );

      if (!stepupInfo) {
        this.router.navigate(['/']);
        return;
      }

      const cookieName = `stepup`;
      this.stepupService.setCookie(
        'stepup' , //stepupInfo.path,'stepup',//
        '1',
        stepupInfo.durationInSeconds
      );

      localStorage.removeItem('stepup-request');
      this.router.navigateByUrl(stepupInfo.path)
    } catch (error) {
      this.router.navigate(['/']);
    }
  }
}
