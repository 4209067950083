import { Component, HostListener, Input } from '@angular/core';
import { FactorsService } from 'src/app/features/privacyand-security-settings/services/factotoenroll.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DeviceDetectionService } from 'src/app/device-detection.service';
import { Subscription } from 'rxjs';
import { faL } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {  
  @Input() isMobile: boolean = false;
  @Input() closeNav: any = false;
  manuallyClosed : Boolean = false;
  screenWidth: number; screenHeight: number;
  resizeSubscription: Subscription;
  openLang: any;
  isIpadMiniLandscape: any;
   
  constructor(
    public factorsService: FactorsService,
    private deviceDetectionService: DeviceDetectionService,
    
  ) {
    
    this.checkScreenSize();
  }
  ismyHeaderFooterEnabled = false;
 



  ngOnInit(): void {    
    this.closeNav = false;
    this.factorsService.getFeatureFlags().subscribe((features) => {
      if (features) {
        this.ismyHeaderFooterEnabled = features['enable_header_n_footer'];
      }
    });
    this.getResolution();

  }
  ngOnDestroy(): void { if (this.resizeSubscription) { this.resizeSubscription.unsubscribe(); } }
  mobileView = false;
  tabView = false;
  desktopView = false;
  showContent: any;
  isIpadMiniPortrait: boolean;
  getResolution() {
    this.checkOrientation()
    this.closeNav = false;
    this.showContent = true;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.deviceDetectionService.isHandset().subscribe(isHandset => {
      this.mobileView = isHandset;

    });
    this.deviceDetectionService.isTablet().subscribe(isTablet => {
      this.tabView = isTablet;

    });
    this.deviceDetectionService.isWeb().subscribe(isWeb => {
      this.desktopView = isWeb;
    });
    this.resizeSubscription = this.deviceDetectionService.onResize().subscribe(size => {
      this.checkOrientation()
      this.closeNav = false;
      this.screenWidth = size.width;
      this.screenHeight = size.height;
      this.deviceDetectionService.isHandset().subscribe(isHandset => {
        this.mobileView = isHandset;
      
      });
      this.deviceDetectionService.isTablet().subscribe(isTablet => {
        this.tabView = isTablet;


      });
      this.deviceDetectionService.isWeb().subscribe(isWeb => {
        this.desktopView = isWeb;
      
      });

    });
    if (this.mobileView) {
      this.closeNav = true;
      this.showContent = true;
    } else if (this.tabView) {
      this.closeNav = true;
      this.showContent = true;
    } else if (this.desktopView) {
      this.closeNav = false;
      this.showContent = true;
    }

  }

  @HostListener('window:resize', ['$event']) 
   onResize(event: any) {
     this.checkScreenSize();
     }

     checkScreenSize() {
       this.isMobile = window.innerWidth < 769; // Adjust the width according to your needs 
       }  


  onLangSelect(e: any) {
    this.openLang = e;
  }
  checkOrientation(): void {
    this.isIpadMiniPortrait = this.deviceDetectionService.isIpadMiniPortrait();
    this.isIpadMiniLandscape = this.deviceDetectionService.isIpadMiniLandscape();

      } 

}
