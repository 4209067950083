<a
  [routerLink]="routerLink"
  routerLinkActive="active"
  [routerLinkActiveOptions]="routerLinkActiveOptions"
>
  <!-- Wrapping icon and text with spans to make text truncation work -->
  <span>
    <ng-content select="[icon]" class="activem"> </ng-content>
  </span>

  <span class="text-container">
    <ng-content></ng-content>
  </span>
</a>
