
<div class="card rounded-left rounded-right h-100"  >
  <div class="text-center pt-4">
    <!-- <fa-icon [icon]="card.faImage" class="fa-2x alcon-text-color"></fa-icon> -->
 </div>
  <div class="card-body text-center  pb-4">
    <h5 class="card-title alcon-text-color fw-bold">{{ card.title |  translate | uppercase }}</h5>
    <br/>
    <span class="card-text p-0">{{ card.text | translate}}</span>
    <br/>
    <span class="card-text p-0">{{ card.subtext | translate}}</span>
    <br/>
    <a class="fs-6" href="{{card.type}}:{{ card.linkone | translate}}">{{ card.linkone | translate }}</a>
    <br/>
    <br/>
    <span class="card-text">{{ card.secondtext | translate}}</span>
    <br/>
    <br/>
    <a class="fs-6" href="{{card.type}}:{{ card.linktwo  | translate }}">{{ card.linktwo  | translate}}</a>
  </div>
</div>
