import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
  name: 'uniqueArray'
})
export class UniqueArrayPipe implements PipeTransform {
 
  transform(value: string[]): string[] {
    if (!Array.isArray(value)) return value;
 
    // Use Set to filter unique values
    return Array.from(new Set(value));
  }
}