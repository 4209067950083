<header class="alcon-color d-none d-lg-inline d-xl-inline">
  <div class="alcon-color">
    <div
      class="d-flex flex-wrap align-items-center justify-content-sm-between justify-content-sm-between justify-content-justify-content-lg-start">
      <a class="navbar-brand" href="#"><img src="assets/img/alcon-logos.png" class="myalcon-logo" /></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>



      <div class="dropdown text-end px-2 d-none d-lg-inline d-xl-inline" *ngIf="profile | async as user">
        <ul class="nav col-12 col-lg-auto me-lg-auto justify-content-end mb-md-0">
          <li>
            <div ngbDropdown class="d-inline-block" placement="bottom-right">

              <div class="text-left remove-caret" id="dropdownBasic1" ngbDropdownToggle>

                <div class="text-center fs-6 language-dropdown" (click)="onLangDropdown()">
                  Language: {{selectedLang}} <i class="bi bi-chevron-down px-3" *ngIf="showDropdown"></i> <i
                    class="bi bi-chevron-up px-3" *ngIf="!showDropdown"></i>
                </div>

              </div>

              <div ngbDropdownMenu style="position: absolute;top: 20px;right: -51px;" aria-labelledby="dropdownBasic1"
                class="dropdown-container">
                <div class="dropdown-highlight" >

                </div>
                <div class="dropdown-content">
                  <label class="langselect-label px-3">Select your preferred language</label>
                  <div class="container lang-dropdown">
                    <div class="lang-cardComp item " *ngFor="let lang of languageList" (click)="onLangSelect(lang)">
                      {{lang.language}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </li>
          <li>
            <div class="nav-link px-2 justify-content-end">
              <div ngbDropdown class="d-inline-block" placement="bottom-right">

                <div class="text-left small-circle-alt remove-caret" id="dropdownBasic1" ngbDropdownToggle>

                  <div class="text-center fs-6" *ngIf="user.email">
                    {{ user.first_name | firstChar
                    }}{{ user.last_name | firstChar }}
                  </div>

                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button class="text-left alcon-text-color" ngbDropdownItem>
                    ACCOUNT
                  </button>
                  <div class="profile px-3">
                    <div class="btn-group" role="group" aria-label="First group">
                      <div class="text-left small-circle">
                        <div class="text-center fs-3" *ngIf="user.email">
                          {{ user.first_name | firstChar
                          }}{{ user.last_name | firstChar }}
                        </div>
                      </div>
                      <div class="text-left px-2">
                        <span class="user-name">{{ user.first_name }} {{ user.last_name }}</span><br />
                        <span class="text-left sub-email" >{{ user.email }}</span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <button ngbDropdownItem routerLink="/home" class="manage">
                    Manage MyAlcon ID
                  </button>
                
                  <button ngbDropdownItem (click)="logout()" class="btn-logout">LOG OUT</button>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</header>

<div class="pb-2 afixed-top d-sx-block d-sm-block d-sm-block d-md-none">
  <nav class="navbar navbar-expand-md lcon-color" *ngIf="profile | async as user">
    <a class="navbar-brand" href="#"><img src="assets/alcon-us.png" height="48" alt="Black and White Portrait of a Man"
        loading="lazy" id="dropdownBasic1" /></a>
    <div class="w-65 mx-auto alcon-color">
      <img src="/assets/myalcon-logo.png" />
    </div>
    <a class="hidden-sm-up alcon-color px-3" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
      data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
      <div class="text-center small-circle-alt remove-caret">
        <div class="fs-6 fw-bold text-white text-decoration-none" *ngIf="user.email">
          {{ user.first_name | firstChar }}{{ user.last_name | firstChar }}
        </div>
      </div>
    </a>
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault" style="float: right">
      <br />
      <div class="text-center text-white" *ngIf="user.email">
        {{ user.first_name }} {{ user.last_name }}
      </div>
      <div class="text-center text-white" *ngIf="user.email">
        {{ user.email }}
      </div>
      <br />
      <!-- <ul class="navbar-nav mr-auto list-group px-3">
      <li><a  routerLink="/home" class="nav-link px-2 pt-2 p-0 fw-bold">My Alcon Home <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a> </li>
      <li><a href="#" class="nav-link px-2  p-0 fw-bold">Cataract <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a> </li>
      <li><a href="#" class="nav-link px-2  p-0 fw-bold">Refractive<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a></li>
      <li><a href="#" class="nav-link px-2  p-0 fw-bold">Vitreoretinal<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a></li>
      <li><a href="#" class="nav-link px-2  p-0 fw-bold">OTC<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a></li>
      <li><a href="#" class="nav-link px-2  p-0 fw-bold">Prescription Drugs<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a></li>
      <li><a href="#" class="nav-link px-2  p-0 fw-bold">Contact Lenses<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a></li>
      <li><a href="#" class="nav-link px-2  p-0 fw-bold">Resources<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right float-end" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
      </svg></a></li>
    </ul> -->
      <div class="text-center py-3">
        <app-oval-button label="Log Out" [css]="ovalLogOutButton" [icon]="myIcon" (click)="logout()"></app-oval-button>
        <!-- <button class="btn btn-primary"><mat-icon icon class="v-align-middle">account_circle</mat-icon><span class="top-4">Log Out</span></button> -->
      </div>
      <div>
        <a href="#"><img src="assets/alcon-customer.png" height="48" width="100%"
            alt="Black and White Portrait of a Man" loading="lazy" id="dropdownBasic1" /></a>
      </div>
    </div>
  </nav>
</div>