import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { MyaccountService } from 'src/app/features/myalcon-account/services/myaccount.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service';
import { HttpHelperService } from 'src/app/core/services/common/http-helper.service';
import { AuditUserLogsService } from 'src/app/core/services/common/audit-user-logs.service';
@Component({
  selector: 'app-edit-phone',
  templateUrl: './edit-phone.component.html',
  styleUrls: ['./edit-phone.component.scss'],
})
export class EditPhoneComponent {
  @Output() cancelAcc = new EventEmitter();
  public userData: any;
  public emailId = '';
  public isError: string | null = null;
  invalidNo = false;
  selectedCountryISO: CountryISO;
  inphone: any;
  isValidPhoneNumber: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneForm: any = new FormGroup({
    phone: new FormControl(undefined, [Validators.required, Validators.minLength(5)]),
  });
  locationData: any;
  ipAddress: any;
  SearchCountryISO: any

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  constructor(
    public myaccountService: MyaccountService,
    public profileService: ProfileService,
    private route: Router,
    public communcationService: CommunicationService,public httpHelperService:HttpHelperService,public audit: AuditUserLogsService
  ) { }
  ngOnInit(): void {
    this.getUserFormData();
    this.communcationService.getIpAndLocation().subscribe((data: any) => {
      this.locationData = data;
      this.ipAddress = data.ip;
      console.log(this.locationData);
      this.SearchCountryISO = this.locationData.country_code.toString();//CountryISO.India;
      console.log(this.CountryISO.India);
    });  
  }

  async getUserFormData() {
    this.userData = this.profileService.UserProfile.subscribe((data: any) => {
      this.userData = data;
      this.phoneForm.patchValue({
        phone: data.myacc_phone_no.substring(4).trim(),
      });
    });
  }
  async onChangeCode(event: any) {
  }

  async onPhoneNumberSubmit() {
    if (this.phoneForm.invalid) {
      this.invalidNo = true;
    } else {
      this.invalidNo = false;
      let phone: any = this.phoneForm.value;
      let bodyParam = {
        profile: {
          primaryPhone: phone?.phone?.internationalNumber,
        },
      };
      const response =
        await this.httpHelperService.userProfilePatch(
          JSON.stringify(bodyParam)
        );

      // Subscribe to the response observable
      response.subscribe({
        // When the response is received
        next: (res: any) => {
          if (res?.error) { 
            // If there's an error in the response
            this.isError = res.error.message || 'An unknown error occurred';
            this.audit.postAuditLogs("FAILURE", this.emailId, "User Profile Pages mobile update", res.error);
          } else {
            // If the response is successful
            this.isError = null; // Clear any previous error messages
            this.profileService.loadUserProfile(); // Load the updated user profile
            this.audit.postAuditLogs("SUCCESS", this.emailId, "User Profile Pages mobile update", res);
          }
        
        },
        // When an error occurs
        error: (err) => {
          console.error('An error occurred:', err),
          this.isError = err.message;
          this.audit.postAuditLogs("FAILURE", this.emailId, "User Profile  Pages mobile update", err);
        },
        // When the request is completed
        complete: () => {
          this.cancelBtn();
          // Load the updated user profile
          this.profileService.loadUserProfile();
          this.audit.postAuditLogs("SUCCESS", this.emailId, "User Profile  Pages mobile update", response);
          return true;
        },

      });
    }
    
  }
  reset(event: any) {
    event.preventDefault();
    this.getUserFormData();
  }
  cancelBtn() {
    this.communcationService.cancelAll(false);
  }
}
