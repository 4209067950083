// shared.module.ts
import { NgModule } from '@angular/core';
import { OvalButtonComponent } from '../app/core/commoncomponent/oval-button/oval-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FirstCharPipe } from './core/pipes/fistChar';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [OvalButtonComponent,FirstCharPipe],
  exports: [OvalButtonComponent,FirstCharPipe],
  imports:[FontAwesomeModule,ReactiveFormsModule,NgxIntlTelInputModule],
  providers: [FirstCharPipe]
})
export class SharedModule {}
