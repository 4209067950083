import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigatorService {

  constructor() { }

  getLanguage(): string {
    return navigator.language;//|| navigator.userLanguage;
  }
}
