import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import { PreferencesService } from 'src/app/features/myalcon-preferences/services/preferences.service';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { OktaAuth, IDToken } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { jwtDecode } from 'jwt-decode';
import { CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
import { DataTranferService } from './services/data-tranfer.service';
import { AuditUserLogsService } from 'src/app/core/services/common/audit-user-logs.service';
import { CommunicationService } from 'src/app/core/services/common/communication-service.service'; 

export class Preference {
  name!: string;
  value!: boolean;
}

export class genericObject {
  prefrenceID!: string;
  preferencetype!: string;
  description!: string;
  status!: string;
  visible!: boolean;
  sequenceId: any;
}

@Component({
  selector: 'app-myalcon-preferences',
  templateUrl: './myalcon-preferences.component.html',
  styleUrls: ['./myalcon-preferences.component.scss'],
})
export class MyalconPreferencesComponent {
  @Output() childEvent = new EventEmitter<string>();
  invalidNo = false;
  inphone: any;
  isValidPhoneNumber: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  public emailId: string;
  public inputPhone: string | any;
  public myIcon: any = null;
  public selectedButton = 'oval-button-selected';
  public unSelectedButton = 'oval-button-unselected';
  public label = 'Edit Prefrences';
  public preferences: genericObject[] = [];
  public comchannel: genericObject[] = [];
  public scheduleType: string = '';
  //public preferencesUpdated: Preference[] = [];
  public areaofIntrest: genericObject[] = [];
  //public areaofIntestUpdated: Preference[] =[];
  public userPreferences: any[] | string[] = [];
  public showButton = true;
  public selectedEmailFrequency: string;
  public WeeklyFrequency: string = 'Weekly';
  public MonthlyFrequency: string = 'Monthly';
  public UnsubscribeFrequency: string = 'Unsubscribe';
  public SnoozFrequency: string = 'Snooz';
  public Message: string = '';
  public currentTimeInGMT: any;
  public isDisable: boolean = false;
  public appid: any = '';
  public previousValue: string = '';
  public idToken: any = '';
  public clientId: any = '';
  public formSubmitted: boolean = false;
  public formnotSubmitted: boolean = false;
  phoneForm: any = new FormGroup({
    // checkArray: this.fb.array([]),
    phone: new FormControl(undefined, [Validators.required, Validators.minLength(5)])
  });
  userId?: string;
  userUpdatedPreferences: any;
  @ViewChild('phoneInput', { static: false }) phoneInputComponent: NgxIntlTelInputComponent;  
  locationData: any;
  ipAddress: any;
  SearchCountryISO: any;

  constructor(
    private fb: FormBuilder,
    public preferencesService: PreferencesService,
    public profileService: ProfileService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private router: Router,
    private audit: AuditUserLogsService,
    private phoneField: DataTranferService,
    public communcationService : CommunicationService
  ) {

    // this.form = new FormGroup({
    //   phone: new FormControl(undefined, [Validators.required, Validators.minLength(5)]),
    // });
    this.preferencesService.getUserPrefrerences();
    this.preferencesService.getMyPrefrerencesApps();
  }
  loadPhoneNumberComponent() {
    // Update the edit phone source variable to true


    // Navigate to the phone number component
    // this.router.navigate([
    //   'mypreference',
    //   {
    //     outlets: {

    //       phone: ['phone'],

    //     },
    //   },
    // ]);
  }

  onChangePhone(event: any) {

    this.showButton = true;
  }
  //Gettting all the preperence on load
  ngOnInit(): void {
    //(window as any).sendHeightToParent();
    this.myIcon = faUser;
    this.showButton = false;


    // this.loadPhoneNumberComponent();
    this.preferencesService.MyPrefreeneces.subscribe((mypreferences: any) => {

      this.preferences = [];
      this.areaofIntrest = [];
      this.comchannel = [];
      this.userPreferences = [];
      if (mypreferences && mypreferences.items) {
        // console.log(mypreferences['items'],mypreferences)
        // for(let item of mypreferences['items']){
        mypreferences['items'].forEach((element: any) => {
          const jsonObj = this.preferencesService.dynamoDBToJson(element);

          if (jsonObj['status'] === 'Active') {
            switch (jsonObj['preferencetype']) {
              case 'prefrences':
                this.preferences.push(jsonObj);

                break;
              case 'areaofIntrest':
                this.areaofIntrest.push(jsonObj);
                break;
              case 'comminicationChannel':
                this.comchannel.push(jsonObj);
                break;
            }
          }
          this.preferences.sort((a, b) => a.sequenceId < b.sequenceId ? -1 : a.sequenceId > b.sequenceId ? 1 : 0)

          this.areaofIntrest.sort((a, b) => a.sequenceId < b.sequenceId ? -1 : a.sequenceId > b.sequenceId ? 1 : 0)
        });

        this.userInformation();
      }

      this.communcationService.getIpAndLocation().subscribe((data: any) => {
        this.locationData = data;
        this.ipAddress = data.ip;
        console.log(this.locationData);
        this.SearchCountryISO = this.locationData.country_code.toString();//CountryISO.India;
        console.log(this.CountryISO.India);
      }); 
    });
  }
  onDataChange(data: any) {
    this.inputPhone = data;
  }
  public toUpdatePreferenceCheckBox(
    _arr: genericObject[],
    _key: any,
    _value: any
  ) {
    for (let i = 0; i < _arr.length; i++) {
      switch (_key) {
        case 'description':
          _arr[i]['description'] = _value;
          break;
        case 'preferencetype':
          _arr[i]['preferencetype'] = _value;
          break;
        case 'preferenceID':
          _arr[i]['prefrenceID'] = _value;
          break;
        case 'status':
          _arr[i]['status'] = _value;
          break;
        case 'visible':
          _arr[i]['visible'] = _value;
          break;

      }
    }
  }
  async onChangeCode(event: any) {
    // const temp = this.phoneForm.controls.value;
    // // const phoneUser =temp.internationalNumber.toString().substring(4).trim();
    // console.log(this.phoneForm.controls.phone.pendingValue,this.phoneForm.controls.phone)
    if (this.phoneForm.value.phone !== null || this.phoneForm.value.phone === '') {
      if (this.phoneForm.invalid) {
        this.invalidNo = true;
        this.showButton = false;
      } else {
        this.invalidNo = false;
        this.showButton = true;


      }
    }

  }
  public userInformation() {
    this.preferencesService.UserPrefreeneces.subscribe(
      (userpreferences: any) => {

        // const jsonObj = this.preferencesService.convertDynamoDBToJson(userpreferences[0]);
        let result: any = {};
        if (Object.keys(userpreferences).length > 0) {
          if (userpreferences[0].userID) result['userID'] = userpreferences[0].userID.s;

          if (userpreferences[0].phoneNumber) {
            result['phoneNumber'] = userpreferences[0].phoneNumber.s;
            this.inputPhone = userpreferences[0].phoneNumber.s;
            //this.phoneField.changePhoneNumber();
            this.phoneForm.patchValue({
              phone: result['phoneNumber']?.substring(4).trim(),
            });

          }
          if (userpreferences[0].schedule) {
            result['schedule'] = userpreferences[0].schedule.s;
            if (result['schedule'] === "Unsubscribe") {
              this.isDisable = true;
            }
            this.selectedEmailFrequency = userpreferences[0].schedule.s;
            this.scheduleType = userpreferences[0].schedule.s;
          }
          if (userpreferences[0].schedule) result['timestamp'] = userpreferences[0].timestamp.s;
          result['myprefrenceslist'] = {};
          Object.keys(userpreferences[0].myprefrenceslist.m).forEach(
            (ele: any) => {
              result['myprefrenceslist'][ele] =
                userpreferences[0].myprefrenceslist.m[ele].s;
            }
          );

          this.userPreferences = Object.values(result['myprefrenceslist']);
          console.log(this.userPreferences);
          if (this.userPreferences.includes("MPC")) {
            this.phoneForm.get('phone')?.enable();
          } else {
            this.phoneForm.get('phone')?.disable();
          }

          //   this.userPreferences= result['myprefrenceslist'];
          for (let item of this.userPreferences) {
            if (this.findPrefernceCategory(this.preferences, item)) {
              continue;
            } else if (this.findPrefernceCategory(this.areaofIntrest, item)) {
              continue;
            } else if (this.findPrefernceCategory(this.comchannel, item)) {
              continue;
            }
            console.log(item);
          }

        }
      }
    );
  }


  public findPrefernceCategory(_arr: genericObject[], _str: string): any {
    for (let i = 0; i < _arr.length; i++) {
      if (_arr[i]['prefrenceID'] === _str) {
        _arr[i]['visible'] = true;

        return true;
      } else {
        if (!_arr[i]['visible']) _arr[i]['visible'] = false;

      }

    }
  }

  //Submitting the  all the preperence on on selection. Here the api response and pay load has to changed
  onPreferenceSubmit() {
    try {
      this.formSubmitted = false;
      this.formnotSubmitted = false;
      // Logic based on authState is done here.
      try {

        // const userProfile = await this.oktaAuth.getUser();
        // Extract email or other claims

        this.profileService.UserProfile.subscribe((data: any) => {
          this.emailId = data.email_id;
          this.userId = data?.sub;
        }); //(await this.oktaAuth.getUser())['sub'].toString(); // Assuming `userProfile` has an `email` property
        if (this.selectedEmailFrequency != null) {
          var preference = new Preference();
          preference.name = this.selectedEmailFrequency;
          preference.value = true;
          // this.preferencesUpdated.push(preference);
        }
        this.phoneField.currentPhone.subscribe((message) => {

          // console.log("onPreferenceSubmit function: "+message);
          // this.inputPhone = message;


        });
        var isformvalid = this.phoneForm.isformvalid;
        let phone: any = this.phoneForm.value.phone;

        //alert( phone?.internationalNumber);

        // console.log('Complete phone number:', this.phoneInputComponent.phoneNumber);
        this.currentTimeInGMT = new Date().toUTCString();
        this.userUpdatedPreferences = this.uploadPrefernces();

        //this.scheduleType = this.scheduleType === this.selectedEmailFrequency ? this.scheduleType : this.selectedEmailFrequency;
        let new_data: { [key: string]: any };
        //  console.log(this.inputPhone, 'userid : ' + this.userId);
        if (this.userId) {
          if (this.userPreferences.includes('MPC')) {
            if (this.phoneForm.invalid) {
              this.invalidNo = true;
              this.showButton = false;

              return;
            } else {
              this.invalidNo = false;

            }
            new_data = {
              userID: this.userId,
              timestamp: this.currentTimeInGMT,
              myprefrenceslist: this.userUpdatedPreferences,
              schedule: this.selectedEmailFrequency,
              phoneNumber: phone?.internationalNumber
            };
          } else {

            new_data = {
              userID: this.userId,
              timestamp: this.currentTimeInGMT,
              myprefrenceslist: this.userUpdatedPreferences,
              schedule: this.selectedEmailFrequency,
              phoneNumber: '',
            };
          }
          // this.preferencesUpdated.forEach(function (value) {
          //   new_data[value.name] = Boolean(value.value);
          // });
          // *******************************************************//
          // This has to be changed the prefrences payload and the resonse fom the  is not proper
          // We will have to fix this during the fuutue rlease  2024
          //********************************************************//
          const bodyParamsPayload = JSON.stringify(new_data);
          this.preferencesService.UserPrefreeneces.subscribe((data: any) => {
            if (data.length > 0) {

              // Sample payload :  "{ \"appid\": \"0oa9rr2p7140l6aAA1d7\",\"data\":{\"test1\":true,\"test2\": true,\"test3\": true}}";
              this.preferencesService.updateMyPrefrerencesApps(
                bodyParamsPayload
              );

              setTimeout(() => {
                // Assume the form submission is successful
                this.formSubmitted = true;
                this.showButton = false;
                this.audit.postAuditLogs("SUCCESS", this.emailId, "Preference Pages", bodyParamsPayload);

              }, 2000); // Simulating a delay of 2 seconds

            }
            else {
              const bodyParamsPayload = JSON.stringify(new_data);
              // Sample payload :  "{ \"appid\": \"0oa9rr2p7140l6aAA1d7\",\"data\":{\"test1\":true,\"test2\": true,\"test3\": true}}";
              this.preferencesService.createMyPrefrerencesApps(
                bodyParamsPayload
              );

              setTimeout(() => {
                // Assume the form submission is successful
                this.formSubmitted = true;
                this.showButton = false;
                this.audit.postAuditLogs("SUCCESS", this.emailId, "Preference Pages", bodyParamsPayload);
              }, 2000); // Simulating a delay of 2 seconds
            }
          });

        }

      } catch (error) {
        console.error('Error fetching user profile:', error);
        this.audit.postAuditLogs("FAILURE", this.emailId, "Preference Pages", error);

      }

    } catch (easy) {
      setTimeout(() => {
        // Assume the form submission is successful
        this.formnotSubmitted = true;
      }, 2000);
    }
  }

  afterViewInit() {
    //(window as any).sendHeightToParent();
  }
  // on slection of theck box prepare a pay load raw data payload on submitt
  onCheckboxChange(e: any) {

    this.showButton = true;
    // const checkArray: FormArray = this.form.get('checkArray') as FormArray;
    if (e.target.checked) {
      this.userPreferences.push(e.target.textContent);
      this.findPrefernceCategory(this.preferences, e.target.textContent);
      if (e.target.textContent === "MPC") {
        this.phoneForm.get('phone')?.enable();
   

      }

      this.findPrefernceCategory(this.areaofIntrest, e.target.textContent);
      this.findPrefernceCategory(this.comchannel, e.target.textContent);

    } else {
      this.userPreferences = this.userPreferences.filter(
        (item) => item !== e.target.textContent
      );
      if (e.target.textContent === "MPC") {
        this.phoneForm.get('phone')?.disable();
        this.invalidNo = false;
        this.previousValue = this.phoneForm.value.phone?.internationalNumber;
        console.log(this.phoneForm.value.phone?.internationalNumber)
        this.phoneForm.get('phone')?.setValue("")
      }
    }
  }
  onFocus() {
    this.showButton = true;
    if (this.phoneForm.invalid) {
      this.invalidNo = true;
      this.showButton = false;
    } else {
      this.invalidNo = false;
      this.showButton = true;


    }
    // Perform actions when the input field is focused
  }

  onBlur() {
    this.showButton = true;
    if (this.phoneForm.invalid) {
      this.invalidNo = true;
      this.showButton = false;
    } else {
      this.invalidNo = false;
      this.showButton = true;


    }
    // Perform actions when the input field loses focus
  }

  onInput(event: any) {
    // console.log('User entered: ', event.target.value);
    
    this.showButton = true;
    // Perform actions when the user enters data
  }
  onChangeInput(e: any) {
    this.showButton = true;

  }


  //On selection of button for email frequence prepare the raw data for payload on submitt
  setEmailFrequence(event: Event) {
    var element = event.target as HTMLElement;
    this.selectedEmailFrequency = element.attributes[2].value; //button.childNodes[1].nodeValue;
    this.currentTimeInGMT = new Date().toUTCString();
    switch (element.attributes[2].value) {
      case 'Unsubscribe':
        this.selectedEmailFrequency = 'Unsubscribe';
        this.userPreferences = [];
        this.showButton = true;
        this.isDisable = true;
        this.toUpdatePreferenceCheckBox(this.preferences, 'visible', false);
        this.toUpdatePreferenceCheckBox(this.areaofIntrest, 'visible', false);
        this.toUpdatePreferenceCheckBox(this.comchannel, 'visible', false);
        this.onPreferenceSubmit();
        break;
      case 'Snooz':
        this.selectedEmailFrequency = 'Snooz';
        this.showButton = true;
        this.isDisable = false;
        break;
      case 'Weekly':
        this.selectedEmailFrequency = 'Weekly';
        this.showButton = true;
        this.isDisable = false;
        break;
      case 'Monthly':
        this.selectedEmailFrequency = 'Monthly';
        this.showButton = true;
        this.isDisable = false;
        break;
    }
  }

  // Yet to implement
  // application wise prefrences
  getPreferencesForAnApplication() {
    // future development 2024
  }

  uploadPrefernces() {
    let _tempObj: { [key: number]: any } = {};
    if (this.userPreferences.length > 0) {
      this.userPreferences.forEach((item, index) => {
        _tempObj[index] = item;
      });
    }
    return _tempObj;
  }
  convertToArray() { }
}
