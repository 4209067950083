
<header class="alcon-color" (window:resize)="onResize($event)">
  <div class="alcon-color">
    <div
      class="header-fixed d-flex flex-wrap align-items-center justify-content-sm-between justify-content-sm-between justify-content-justify-content-lg-start">
      <a *ngIf="desktopView || tabView || isIpadMiniLandscape" class="navbar-brand" href="#"><img  alt="alcon-logo"src="assets/img/alcon-logoID.png"
          class="myalcon-logo" /></a>
      <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->

      <div class="w-65 mx-auto alcon-color " *ngIf="mobileView">
        <img alt="my-alcon" src="assets/img/alcon-logoID.png" class="myalcon-logo" />
      </div>

     
      <img  alt="burger-icon" src="/assets/img/burger-icon.png"  class="hamburgerIcon" (click)="onHamburgerMenu()"
        *ngIf="mobileView || isIpadMiniLandscape || isIpadMiniPortrait" />  
        
        <div *ngIf="menuOpen"  class="phone-menu">
          <button class="toggle-button" *ngIf="menuOpen">
            <img  alt="closeBtn "src="/assets/img/closeBtn.png"  class="tab-view tab-closebtn" (click)="closebtn()"/>
            </button>
          <app-myalcon-sidenav (onMenuClicked) ="getDataFromChild($event)"></app-myalcon-sidenav></div>
              
        
        <div *ngIf="desktopView || tabView ||  isIpadMiniLandscape">
        <div class="dropdown text-end px-2 " *ngIf="profile | async as user">
          <ul class="nav col-12 col-lg-auto me-lg-auto justify-content-end mb-md-0 ">
            <li>
              <div ngbDropdown #dropdown="ngbDropdown" class="d-inline-block language-list-arrow" placement="bottom-right" (openChange)="onDropdownToggle($event)"  >

                <div class="text-left remove-caret" id="dropdownBasic1" ngbDropdownToggle>

                  <div class="text-center fs-6 language-dropdown" (click)="onLangDropdown()">
                    Language: {{selectedLang}} 
                    <i class="bi" [ngClass]="{'bi-chevron-up': showDropdown , 'bi-chevron-down': !showDropdown }" class="px-3"></i>

                  </div>

                </div>

                <div ngbDropdownMenu style="position: absolute;top: 20px;right: -51px;" aria-labelledby="dropdownBasic1"
                  class="dropdown-container">
                  <div class="dropdown-highlight">

                  </div>
                  <div class="dropdown-content">
                    <label class="langselect-label px-3">Select your preferred language</label>
                    <div class="container lang-dropdown">
                      <div class="lang-cardComp item " *ngFor="let lang of languageList" (click)="onLangSelect(lang)">
                        {{lang.language}}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </li>
            <li>
              <div class="nav-link px-2 justify-content-end user-informatation">
                <div ngbDropdown class="d-inline-block" placement="bottom-right">

                  <div class="text-left small-circle-alt remove-caret" id="dropdownBasic1" ngbDropdownToggle>

                    <div class="text-center fs-6 userInitial" *ngIf="user.email">
                      {{ user.first_name | firstChar
                      }}{{ user.last_name | firstChar }}
                    </div>

                  </div>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
                    <div class="account-container">
                      <div class="dropdown-highlight">

                      </div>
                      <button class="text-left alcon-text-color" ngbDropdownItem>
                        ACCOUNT
                      </button>
                      <div class="profile px-3">
                        <div class="btn-group" role="group" aria-label="First group">
                          <div class="text-left small-circle">
                            <div class="text-center fs-3 userInitial" *ngIf="user.email">
                              {{ user.first_name | firstChar
                              }}{{ user.last_name | firstChar }}
                            </div>
                          </div>
                          <div class="text-left px-2">
                            <span class="user-name">{{ user.first_name }} {{ user.last_name }}</span><br />
                            <span class="text-left sub-email">{{ user.email }}</span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <button ngbDropdownItem class="manage" (click)="redirectToProfessional()">
                        MyAlcon Home
                      </button>
                      <button ngbDropdownItem routerLink="/home" class="manage"  alt="home">
                        Manage MyAlcon ID
                      </button>
                      <button ngbDropdownItem class="manage" (click)="redirectToHelp()">
                        MyAlcon ID
                         Help
                     </button>  
                      <button ngbDropdownItem (click)="logout()" class="btn-logout">LOG OUT</button>
                    </div>

                  </div>
                </div>
              </div>
            </li>

          </ul>
          <div>

          </div>
        </div>
      </div>

    </div>
  </div>
</header>