<div class="alcon-color card rounded-left rounded-right h-100">
  <div class="p-1  text-center fw-bold bg-primary mt-3 mx-5 text-white w-25">{{ card.title }}</div>
  <p class="p-2 mx-1 fs-4 card-text alcon-play-card-text-color">{{ card.text }}</p>
  <br />
  <br />
  <br />
  <div class="position-absolute bottom-0 start-0">
    <div class="row mx-5 rounded-2 fw-bold  alcon-play-card-text-color ">
      <div class="col-2 float-left">
      <fa-icon [icon]="myfaArrow" class="fa-2x  pt-4"></fa-icon></div>
      <div class="col-10  text-center pt-3 px-2"><a class="alcon-play-card-text-color">
          <p><abbr title="HyperText Markup Language" class="attribute"><a>Read More</a></abbr></p>
        </a>
      </div>
    </div>
  </div>
  <img *ngIf="card.imageUrl" class="card-img-top " src="{{ card.imageUrl }}" alt="Card image cap">
</div>
