import { ElementRef, Inject, Injectable, ViewChild } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { IDToken } from '@okta/okta-auth-js';
import { StepupModuleConfig } from './stepup.module';

export interface SetpupConfig {
  durationInSeconds: number;
  acrValues: string;
  additionalScopes?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class StepupService {
  private readonly moduleConfig!: StepupModuleConfig;
  //@ViewChild('myButton')  myButton!: ElementRef;
  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    @Inject('CONFIG') private config: StepupModuleConfig
  ) {
    this.moduleConfig = this.config;
  }

  get configs() {
    return this.moduleConfig.config;
  }

  async isStepUpNeeded() {
    const idToken = (await this.oktaAuth.tokenManager.get(
      'idToken'
    )) as IDToken;

    return idToken.claims.acr !== this.moduleConfig.config.acrValues;
  }

  setpUp(path: string,btn:string) {
    const info = {
      path: path,
      durationInSeconds: this.moduleConfig.config.durationInSeconds,
      btn: btn,
    };

    localStorage.setItem('stepup-request', JSON.stringify(info));

    this.oktaAuth.signInWithRedirect({
      originalUri: '/stepup/callback',
      acrValues: this.moduleConfig.config.acrValues,
      maxAge: this.moduleConfig.config.durationInSeconds,
      prompt: 'login',
      scopes: [
        'openid',
        'profile',
        'email',
        ...(this.moduleConfig.config.additionalScopes || []),
      ],
    });
  }

  setCookie(name: string, value: string, seconds: number) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + seconds * 1000);

    const cookieValue = `${name}=${value};SameSite=None; Secure; expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = cookieValue;
  }

  getCookie(name: string): string | null {
    const cookies = document.cookie.split(';');

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim());
      if (cookieName === name) {
        // this.myButton.nativeElement.click();
        return cookieValue;
      }
    }

    return null;
  }
}
