
// This is button component build and used accoss the application with the customizable look and feel
// Re uasble responsive component
import { Component, Input } from '@angular/core';
import { IconDefinition ,Icon} from '@fortawesome/fontawesome-svg-core';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-oval-button',
  templateUrl: './oval-button.component.html',
  styleUrls: ['./oval-button.component.scss']
})
export class OvalButtonComponent {
  @Input() label!: string;
  @Input() icon: IconDefinition = faAdd;
  @Input() css!: string;
  @Input() name!: string;

  constructor() {
  }
}
