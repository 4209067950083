import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LayoutComponent } from './core/layout/layout/layout.component';
import { SidenavGuard } from './core/layout/components/myalcon-sidenav/services/sidenav.gaurd';
import { InfoComponent } from './info/info.component';
import { LoginComponent } from './login/login.component';
import { MaintainPageComponent } from './maintain-page/maintain-page.component';
export const routes: Routes = [
  {
    path: '',
   
    canActivate: [OktaAuthGuard, SidenavGuard],
    component: LayoutComponent,
   
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/myalcon-home/myalcon-home.module').then(
            (m) => m.MyAlconHomeModule
          ),
      }, 
       
      {
        path: 'home',
        loadChildren: () =>
          import('./features/myalcon-home/myalcon-home.module').then(
            (m) => m.MyAlconHomeModule
          ),
      },    
      { path: 'myaccount', loadChildren: () => import('./features/myalcon-account/myalcon-account.module').then(m => m.MyalconAccountModule) },
      {
        path: 'mypreference',
        loadChildren: () => import('./features/myalcon-preferences/myalcon-preferences.module').then(m => m.MyalconPreferencesModule)
      },
      {
        path: 'myconsent',
        loadChildren: () => import('./features/myalcon-consent/myalcon-consent.module').then(m => m.MyalconConsentModule)
      },
      {
        path: 'privacyandsecuritysettings',
        loadChildren: () => import('./features/privacyand-security-settings/privacyand-security-settings.module').then(m => m.PrivacyandSecuritySettingsModule)
      },
      {
        path: '',
        redirectTo: 'region/americas',
        pathMatch: 'full',
      },
      {
        path: 'region/americas',
        redirectTo: 'home',
        pathMatch: 'full',
      },

      {
        path: 'region/emea',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'region/apac',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'homr/region/americas',
        redirectTo: 'home',
        pathMatch: 'full',
      },

      {
        path: 'home/region/emea',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home/region/apac',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'myapprovals',
        loadChildren: () =>
          import('./features/myalcon-approvals/myalcon-approvals.module').then(
            (m) => m.MyalconApprovalsModule
          ),
      },
    ],
  },
  {
    path: 'region/americas/login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'region/apac/login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'region/emea/login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'info',
    component: InfoComponent,
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'maintaince_page',
    component: MaintainPageComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    //  onSameUrlNavigation: 'reload',
      scrollOffset: [0, 50],
     // enableTracing :true,
      preloadingStrategy: PreloadAllModules,
      // relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
