import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal  } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  bsModalRef!: BsModalRef;
  constructor() { }

  private isEditProfileSource = new BehaviorSubject<boolean>(false);
  isEditProfileTrue$ = this.isEditProfileSource.asObservable();

  private isEditPhoneSource = new BehaviorSubject<boolean>(false);
  isEditPhoneTrue$ = this.isEditPhoneSource.asObservable();

  private isResetPasswordSource = new BehaviorSubject<boolean>(false);
  isResetPasswordSourceTrue$ = this.isResetPasswordSource.asObservable();

  private isPhoneFactorSource = new BehaviorSubject<boolean>(false);
  isPhoneFactorSourceTrue$ = this.isPhoneFactorSource.asObservable();

  private isSecretQuestionFactorSource = new BehaviorSubject<boolean>(false);
  isSecretQuestionFactorSourceTrue$ = this.isSecretQuestionFactorSource.asObservable();




  updateEditProfileSourceVariable(value: boolean): void {
    this.isEditProfileSource.next(value);
    this.isEditPhoneSource.next(!value);
    this.isResetPasswordSource.next(!value);
   // this.isSecretQuestionFactorSource.next(!value);
    //this.isPhoneFactorSource.next(!value);
  }

  updateEditPhoneSourceVariable(value: boolean): void {
    this.isEditPhoneSource.next(value);
    this.isEditProfileSource.next(!value);
    this.isResetPasswordSource.next(!value);
    //this.isSecretQuestionFactorSource.next(!value);
    //this.isPhoneFactorSource.next(!value);
  }

  updateResetPasswordSourceVariable(value: boolean): void {
    this.isResetPasswordSource.next(value);
    this.isEditProfileSource.next(!value);
    this.isEditPhoneSource.next(!value);
    //this.isSecretQuestionFactorSource.next(!value);
    //this.isPhoneFactorSource.next(!value);
  }



  updateResetSecretQuestionFactorVariable(value: boolean): void {
    this.isSecretQuestionFactorSource.next(value);
    this.isPhoneFactorSource.next(!value);
  }

  updatePhoneFactoreVariable(value: boolean): void {
    this.isSecretQuestionFactorSource.next(!value);
    this.isPhoneFactorSource.next(value);
  }
  cancelFactorResetAll(value: boolean): void {
    this.isSecretQuestionFactorSource.next(value);
    this.isPhoneFactorSource.next(value);
  }

  cancelAll(value: boolean): void {
    this.isResetPasswordSource.next(value);
    this.isEditProfileSource.next(value);
    this.isEditPhoneSource.next(value);
    this.isSecretQuestionFactorSource.next(value);
    this.isPhoneFactorSource.next(value);
  }
}
