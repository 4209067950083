import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyalconHomeRoutingModule } from './myalcon-home-routing.module';
import { MyalconHomeComponent } from './myalcon-home.component';
import { CardComponent } from 'src/app/core/commoncomponent/card/card.component';
import { CustomerSupportCardComponent } from 'src/app/core/commoncomponent/customer-support-card/customer-support-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [MyalconHomeComponent,
    CardComponent,
    CustomerSupportCardComponent],
  imports: [ TranslateModule.forChild(),
    CommonModule,MyalconHomeRoutingModule,
  ],
  exports: [
    MyalconHomeComponent,
  ],
  bootstrap: [MyalconHomeComponent],
})
export class MyAlconHomeModule { }
