// popup-modal.component.ts
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FactorsService } from '../../services/factotoenroll.service';
@Component({
  selector: 'app-popup-modal',
  templateUrl: './edit-phone-factor.popup-modal.component.html',
})
export class PhoneFactorPopupModalComponent implements OnInit {

  @Input() factorid: string = '';
  @Input() userid: string = '';
  @Output() verifyOTP: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  // Use DomSanitizer to mark the URL as safe
  safeUrl: SafeResourceUrl;
  passCode = '';
  verify() {


    const body = {
      "passCode": this.passCode
    }

    this.factorsService
      .verifyFactor(this.factorid, this.userid, body)
      .subscribe((response) => {
        // Process the response here
       // console.log(response);

        this.verifyOTP.emit(response);
        // this.openPopup();
      });
  }

  constructor(
    public factorsService: FactorsService,
    public bsModalRef: BsModalRef,
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    // Mark the URL as safe when it changes
    //this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  onClose() {
    // alert('child close');
    this.close.emit();
  }
}
